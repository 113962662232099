import { useLocation } from 'react-router-dom';

// import { BackButton } from '../../components/questionnaire';
import { Box, Button, Flex, LoadingOverlay, Text, Title } from '@mantine/core';
import { useQueryClient } from 'react-query';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import { useHeaderBackAndCtaStyles } from '../../components/header-back-and-cta/hooks/use-styles';
import { useGetUserProfile } from '../../service';

export const SubscriptionPage = () => {
  const { classes: headerBackAndCtaClasses } = useHeaderBackAndCtaStyles();
  const queryClient = useQueryClient();
  const { signOut } = useAuthContext();
  // const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkParam = searchParams.has('check');

  const { data, refetch } = useGetUserProfile();
  const shouldRefetch = checkParam && !data?.subscribed;
  if (shouldRefetch) {
    queryClient.invalidateQueries([REACT_QUERY_CACHE_KEYS.userProfile]);
    refetch();
  }
  // const goBack = () => {
  //   navigate(-1);
  // };

  return (
    <Box>
      {/* {doesAnyHistoryEntryExist && <BackButton prevStep={goBack} />} */}
      <LoadingOverlay visible={shouldRefetch} zIndex={1000} overlayBlur={2} />
      <Flex justify="right" h={80} align="center">
        <button className={headerBackAndCtaClasses.headerCta} onClick={signOut}>
          Logout
        </button>
      </Flex>
      <Box mt="20px">
        <Title order={4} mb="xl" ta="left">
          Choose your subscription
          {/* and unlock a 90 day free trial */}
        </Title>
        {/* <Text c="#8F8FAF">
          After 90 days you’ll be billed accordingly. You are able to cancel your subscription
          anytime.
        </Text> */}
      </Box>
      <Box bg="#1C1C27" mt="10px" p="25px" style={{ borderRadius: '10px' }}>
        <Title order={6} mb="sm" ta="left">
          Monthly Subscription
        </Title>
        <Text c="#FC9244" fw={600}>
          $9.99 / month
        </Text>
        <Text c="#8F8FAF">Billed as $9.99 each month</Text>
        <Button
          size="md"
          mt="10px"
          component="a"
          href={`https://buy.stripe.com/8wM4ig3PZdVReYw5kJ?client_reference_id=${data?.id}`}
          fullWidth
        >
          Choose
        </Button>
      </Box>
      <Box bg="#1C1C27" mt="10px" p="25px" style={{ borderRadius: '10px' }}>
        <Title order={6} mb="sm" ta="left">
          Yearly Subscription
        </Title>
        <Text c="#FC9244" fw={600}>
          $8.33 / month
        </Text>
        <Text c="#8F8FAF">Billed as $99.99 each year</Text>
        <Button
          size="md"
          mt="10px"
          component="a"
          href={`https://buy.stripe.com/5kA2a82LV1959Ec4gE?client_reference_id=${data?.id}`}
          fullWidth
        >
          Choose
        </Button>
      </Box>
    </Box>
  );
};
