export const RewardsIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1495 5.97111L10.6253 5.57851L8.85862 1.41886C8.5408 0.66171 7.45649 0.66171 7.13867 1.41886L5.37199 5.58786L0.857129 5.97111C0.0345462 6.03654 -0.301965 7.06477 0.32432 7.60693L3.75486 10.5794L2.72664 14.9915C2.53969 15.7954 3.40901 16.431 4.11942 16.001L7.99864 13.6641L11.8779 16.0104C12.5883 16.4403 13.4576 15.8047 13.2707 15.0008L12.2424 10.5794L15.673 7.60693C16.2993 7.06477 15.9721 6.03654 15.1495 5.97111ZM7.99864 11.9161L4.48397 14.038L5.41873 10.0373L2.31534 7.3452L6.40956 6.98999L7.99864 3.22293L9.59707 6.99934L13.6913 7.35455L10.5879 10.0466L11.5227 14.0474L7.99864 11.9161Z"
        fill="#72729B"
      />
    </svg>
  );
};
