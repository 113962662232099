import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Loader,
  Progress,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { format } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CustomerRoutes } from '../../routes';
import { fetchUserObjectives } from '../../service/objectives';
import { useUserProfileContext } from '../../service/user/context';

interface Objective {
  bankAccountId: string;
  bonusPoints: number;
  categoryId: string;
  createdAt: number;
  customerId: string;
  id: string;
  isActive: boolean;
  isCompleted: boolean;
  targetAmount: number;
  taskBonusPoints: number;
  taskXpPoints: number;
  title: string;
  xpPoints: number;
}

export const CustomerDashboardPage = () => {
  const theme = useMantineTheme();
  const [isObjectivesEmpty, setIsObjectivesEmpty] = useState(true);
  const { userProfile } = useUserProfileContext();
  const { data, isLoading } = useQuery('userObjectives', fetchUserObjectives);

  useEffect(() => {
    console.log('user objectives: ', data);
    setIsObjectivesEmpty(Boolean(data?.length));
  }, [data]);

  const renderObjectives = (objectives: Objective[] | undefined) => {
    if (isLoading) {
      return (
        <Center maw={400} h={100} mx="auto">
          <Loader />
        </Center>
      );
    }
    if (!objectives) {
      return null;
    }
    return objectives.map((objective: Objective) => {
      const { createdAt, targetAmount, title, xpPoints } = objective;
      const date = new Date(createdAt);
      const formattedDate = format(date, 'MMM dd yyyy');

      return (
        <Link
          to={`${CustomerRoutes.OBJECTIVE_DETAIL}/${objective.id}`}
          style={{ textDecoration: 'none' }}
          key={objective.id}
        >
          <Box
            p={25}
            mb="md"
            bg={theme.colors.dark[8]}
            sx={{ borderRadius: theme.radius.md }}
            key={objective.id}
          >
            <Flex justify="space-between" align="center">
              <Text size="xl" c={theme.white} fw={700} sx={{ lineHeight: '1.625rem' }}>
                {title}
              </Text>
              <Badge
                h="30px"
                lh="1"
                sx={{ flexShrink: 0, padding: '8px' }}
                bg={theme.colors.dark[7]}
                c={theme.colors.dark[1]}
              >
                {formattedDate}
              </Badge>
            </Flex>
            <Text c={theme.colors.dark[2]} fw={600} mb="sm">
              {`$ ${targetAmount} / month`}
            </Text>
            <Text size="lg" fw={600} h="20px">
              <span style={{ color: theme.colors.blue[4] }}>$3.850 /</span> $4.100
            </Text>
            <Text size="sm" c={theme.colors.dark[3]}>
              $150 remaining
            </Text>
            <Flex justify="space-between" align="center">
              <Progress value={92} w="100%" />
              <Text sx={{ flexShrink: 0, margin: '0 9px' }}>92%</Text>
              <Badge
                variant="outline"
                size="lg"
                radius="md"
                color="orange"
                sx={{ flexShrink: 0, padding: '5px 8px', fontSize: '12px' }}
              >
                {xpPoints} XP
              </Badge>
            </Flex>
          </Box>
        </Link>
      );
    });
  };
  const option = {
    xAxis: {
      type: 'category',
      data: ['Feb 2022', 'Feb 2023'],
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      axisLabel: {
        align: 'center',
      },
    },
    grid: {
      left: 0,
      right: 30,
      top: 10,
      bottom: 10,
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 20000,
      axisLabel: {
        fontSize: 8,
        color: 'rgba(203, 213, 225, 1)',
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(48, 53, 80, 1)',
          type: 'dashed',
        },
      },
    },

    series: [
      {
        data: [15000, 18000],
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'rgba(31, 204, 183, 1)',
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(23, 153, 137, 1)', // Start color
              },
              {
                offset: 1,
                color: 'rgba(12, 77, 69, 0)', // End color
              },
            ],
          },
        },
      },
    ],
  };
  return (
    <Box>
      <Box pt="30px" pb="36px">
        <Text fz="xl" fw={700} lh="22px" mb="4px" c={theme.colors.dark[2]}>
          Liquid Net Worth
        </Text>
        <Title order={4}>${userProfile?.netWorth}</Title>
      </Box>
      <Box mb={36}>
        <ReactECharts
          option={option}
          notMerge={true}
          lazyUpdate={true}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
          style={{ height: '185px' }}
        />
      </Box>

      <Box
        p={42}
        bg="rgba(31, 204, 183, 0.04)"
        sx={{ borderRadius: 8, border: '1px solid #1E293B' }}
        ta="center"
      >
        <Text fz="md" mb="md" fw={600} c={theme.colors.dark[0]}>
          No data to show
        </Text>
        <Link to={CustomerRoutes.ACCOUNTS_CREATE} style={{ textDecoration: 'none' }}>
          <Button>Start Adding Accounts</Button>
        </Link>
      </Box>

      <Box pt="60px" pb="36px">
        <Flex justify="space-between" align="center" mb="md">
          <Text fz="xl" fw={700} lh="22px" c={theme.colors.dark[2]}>
            My Objectives
          </Text>
          {isObjectivesEmpty ? (
            <Link to={CustomerRoutes.OBJECTIVE_CREATE}>
              <Button>New Objective</Button>
            </Link>
          ) : null}
        </Flex>
        {renderObjectives(data)}
        <Box ta="center" p="0 24px 12px">
          {!isObjectivesEmpty ? (
            <Link to={CustomerRoutes.OBJECTIVE_CREATE}>
              <Button mt="xs">Create Your Objective</Button>
            </Link>
          ) : (
            <Button
              c={theme.colors.dark[1]}
              size="md"
              p="12px 16px"
              variant="outline"
              sx={{ borderColor: theme.colors.dark[6] }}
            >
              Show all objectives
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
