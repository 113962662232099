import { Navigate, Outlet } from 'react-router-dom';
import { CustomerRoutes } from '../../customer-app/routes';
import { useAuthContext } from '../../providers';

export const AuthGuard = () => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) return <Outlet />;

  return <Navigate to={CustomerRoutes.LOGIN} />;
};
