import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';

export type UserProfileData = {
  hasSubmittedQuestionnaire: boolean;
  id: string;
  netWorth: number;
  persona: keyof typeof UserProfilePersona;
  points: {
    bonus: number;
    level: number;
    nextLevelXp: number;
    xp: number;
  };
  subscribed: boolean;
  salary: UserProfileSalary;
  profilePhotoUrl: string;
  questionnaire: { [key: string]: UserProfileQuestionnaire };
};

export enum UserProfilePersona {
  BIG_SPENDER = 'Big Spender',
  SPENDER = 'Spender',
  SAVER = 'Saver',
  BIG_SAVER = 'Big Saver',
  NO_PERSONA = 'Questionnaire was not completed',
}

type UserProfileSalary = {
  annualAmount: number;
  frequency: Frequency;
  incomeTitle: string;
  relationshipStatus: RelationshipStatus;
  dependents: string;
  paid: boolean;
};

export type RelationshipStatus = 'single' | 'married' | 'divorced';

type UserProfileQuestionnaireAnswer = {
  letter: string;
  text: string;
};

type UserProfileQuestionnaire = {
  answer: UserProfileQuestionnaireAnswer;
  questionId: string;
  title: string;
};

type UserProfileResponse = {
  data: UserProfileData;
};

type UserProfilePictureUploadUrlData = {
  presignedUrl: string;
};

type UserProfilePictureUploadUrlResponse = {
  data: UserProfilePictureUploadUrlData;
};

export enum Frequency {
  monthly = 'MONTHLY',
  weekly = 'WEEKLY',
  biweekly = 'BIWEEKLY',
}

export type FinancialDataPayload = {
  incomeTitle: string;
  annualAmount: number;
  frequency: `${Frequency}`;
  relationshipStatus: RelationshipStatus;
  dependents: number;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchUserProfile() {
  const { data } = await httpService.get<UserProfileResponse>('/profile');
  return data.data;
}

export function useGetUserProfile(
  options?: UseQueryOptions<
    UserProfileData,
    Error,
    UserProfileData,
    [REACT_QUERY_CACHE_KEYS_PROPS['userProfile']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    UserProfileData,
    Error,
    UserProfileData,
    [REACT_QUERY_CACHE_KEYS_PROPS['userProfile']]
  >([REACT_QUERY_CACHE_KEYS.userProfile], fetchUserProfile, {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function fetchProfilePhotoUploadUrl() {
  const { data } = await httpService.get<UserProfilePictureUploadUrlResponse>(
    '/profile/photo/upload-url',
  );
  return data.data;
}

export function useGetProfilePhotoUploadUrl(
  options?: UseQueryOptions<
    UserProfilePictureUploadUrlData,
    Error,
    UserProfilePictureUploadUrlData,
    [REACT_QUERY_CACHE_KEYS_PROPS['userProfilePhotoUploadUrl']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    UserProfilePictureUploadUrlData,
    Error,
    UserProfilePictureUploadUrlData,
    [REACT_QUERY_CACHE_KEYS_PROPS['userProfilePhotoUploadUrl']]
  >([REACT_QUERY_CACHE_KEYS.userProfilePhotoUploadUrl], fetchProfilePhotoUploadUrl, {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function mutateFinancialData(financialDataPayload: FinancialDataPayload) {
  const { data } = await httpService.post<FinancialDataPayload>(
    '/profile/financial-information',
    financialDataPayload,
  );
  return data;
}
