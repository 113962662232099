export const StarIcon = ({ color = '#72729B' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49999 13.1603L12.3799 15.5069C13.0904 15.937 13.9599 15.3013 13.7729 14.4972L12.7445 10.0844L16.1756 7.11144C16.802 6.56919 16.4654 5.54079 15.6427 5.47534L11.1271 5.09203L9.36011 0.922328C9.04224 0.165051 7.95774 0.165051 7.63987 0.922328L5.87289 5.08268L1.35727 5.46599C0.534552 5.53144 0.197984 6.55984 0.824374 7.10209L4.2555 10.0751L3.22709 14.4879C3.04011 15.2919 3.90958 15.9276 4.62011 15.4976L8.49999 13.1603Z"
        fill={color}
      />
    </svg>
  );
};
