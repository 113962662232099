import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';
import { Response } from '../../../shared-types';

export type ObjectiveData = Objective[];

export type ObjectiveType = {
  id: string;
  title: string;
  description: string;
  bonusPoints: number;
  xpPoints: number;
};

export type CustomerObjectivePayload = {
  title: string;
  targetAmount: number;
};

export interface Objective {
  bankAccountId: string;
  bonusPoints: number;
  categoryId: string;
  createdAt: number;
  customerId: string;
  id: string;
  isActive: boolean;
  isCompleted: boolean;
  targetAmount: number;
  taskBonusPoints: number;
  taskXpPoints: number;
  title: string;
  xpPoints: number;
}

export type ObjectivePayload = {
  bankAccountId: string;
  categoryId: string;
  objectiveId: string;
  targetAmount: number;
  title: string;
};

type ObjectivesByCategoryResponse = {
  data: ObjectiveData;
};

export type ObjectivesByCategoryPayload = {
  [key: string]: string;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchObjectivesByCategory(categoryId: string | undefined) {
  const { data } = await httpService.get<ObjectivesByCategoryResponse>(
    `/objectives/category/${categoryId}`,
  );
  return data.data;
}

export async function fetchUserObjectives() {
  const { data } = await httpService.get<Response<[Objective]>>('/customer-objectives');
  return data.data;
}

export async function mutateUserObjective(
  objectiveId: string,
  objectiveData: CustomerObjectivePayload,
) {
  const { data } = await httpService.patch<Response<[Objective]>>(
    `/customer-objectives/${objectiveId}`,
    objectiveData,
  );
  return data.data;
}

export function useGetObjectivesByCategory(
  categoryId: string | undefined,
  options?: UseQueryOptions<
    ObjectiveData,
    Error,
    ObjectiveData,
    [REACT_QUERY_CACHE_KEYS_PROPS['objectivesByCategory'], string | undefined]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    ObjectiveData,
    Error,
    ObjectiveData,
    [REACT_QUERY_CACHE_KEYS_PROPS['objectivesByCategory'], string | undefined]
  >(
    [REACT_QUERY_CACHE_KEYS.objectivesByCategory, categoryId],
    () => fetchObjectivesByCategory(categoryId),
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export async function mutateObjective(objectivePayload: ObjectivePayload) {
  const { data } = await httpService.post<ObjectivePayload>('/objectives', objectivePayload);
  return data;
}
