import { Link, Outlet } from 'react-router-dom';

export const AdminLayout = () => {
  console.log('testing pre-push hook');
  return (
    <>
      <header>
        <ul>
          <li>
            <Link to="/admin">Home</Link>
          </li>
          <li>
            <Link to="profile">Profile</Link>
          </li>
        </ul>
      </header>

      <Outlet />
    </>
  );
};
