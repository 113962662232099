import { Alert, Box, Flex, Loader } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { useGetLiability } from '../../service/liabilities';
import { EditLiabilityForm } from '../../components/accounts/components/edit-liability-form';

export const EditAccountPageLiability = () => {
  const navigate = useNavigate();

  const { id: liabilityId } = useParams();
  const { data: liabilityData, isLoading, isError } = useGetLiability(liabilityId);

  const onBackClick = () => navigate(`${CustomerRoutes.ACCOUNTS_INFO_LIABILITY}/${liabilityId}`);

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Liability info"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Liability info"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error loading liability
        </Alert>
      </>
    );
  }

  return (
    <Box mt={30}>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Liability info"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />
      <EditLiabilityForm liabilityData={liabilityData} />
    </Box>
  );
};
