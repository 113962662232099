export const ArrowDiagonallyIcon = ({ color = '#698AFF' }) => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.74062 3.2381L1.50594 10.8686C1.33134 11.059 1.12385 11.1543 0.88348 11.1543C0.64311 11.1543 0.435914 11.059 0.261893 10.8686C0.0872899 10.6781 -1.14441e-05 10.4517 -1.14441e-05 10.1895C-1.14441e-05 9.9273 0.0872899 9.70127 0.261893 9.51143L7.5184 1.90476H0.88348C0.636126 1.90476 0.428639 1.81334 0.26102 1.63048C0.0934014 1.44762 0.00988284 1.22159 0.0104648 0.952384C0.0104648 0.682543 0.0942743 0.456194 0.261893 0.273337C0.429512 0.0904796 0.636708 -0.000631625 0.88348 3.29543e-06H9.61364C9.86099 3.29543e-06 10.0685 0.0914319 10.2361 0.274289C10.4037 0.457146 10.4872 0.683178 10.4867 0.952384V10.4762C10.4867 10.746 10.4028 10.9724 10.2352 11.1552C10.0676 11.3381 9.86041 11.4292 9.61364 11.4286C9.36628 11.4286 9.15879 11.3371 8.99118 11.1543C8.82356 10.9714 8.74004 10.7454 8.74062 10.4762V3.2381Z"
        fill={color}
      />
    </svg>
  );
};
