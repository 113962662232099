import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Divider, Text, TextInput, Title } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { AppleIcon } from '../../../assets/icons/apple';
import { GoogleIcon } from '../../../assets/icons/google';
import { useAuthContext } from '../../../providers';
import { NotAuthorizedException } from '../../../providers/authentication/service';
import { AuthFormFields, useAuthenticationFields } from '../../hooks';
import { useStyles } from '../../hooks/use-styles';

import LottieCheckbox from '../../../assets/lotties/checkmark.json';

const ConditionalRender = ({
  children,
  shouldDisplay,
}: PropsWithChildren<{ shouldDisplay: boolean }>) => {
  if (shouldDisplay) return <>{children}</>;

  return null;
};

const useIsRedirectedFromConfirmation = () => {
  const loc = useLocation();
  return new URLSearchParams(loc.search).get('verified');
};

export const LoginPage = () => {
  const isRedirected = useIsRedirectedFromConfirmation();
  const { signIn, federatedSignIn } = useAuthContext();
  const { mutate, isLoading } = useMutation<void, NotAuthorizedException, AuthFormFields>({
    mutationFn: ({ email, password }) => signIn(email, password),
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useAuthenticationFields();

  const fields = watch();
  const { classes } = useStyles();

  const SocialLoginButtons = () => (
    <ConditionalRender shouldDisplay={!isRedirected}>
      <Button
        size="lg"
        fullWidth
        variant="social"
        mb="md"
        leftIcon={<GoogleIcon />}
        onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Google)}
      >
        Sign in with Google
      </Button>
      <Button
        size="lg"
        mb="md"
        fullWidth
        variant="social"
        leftIcon={<AppleIcon />}
        onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Apple)}
      >
        Sign in with Apple
      </Button>
      <Divider my="md" labelPosition="center" label="Or sign in with email" />
    </ConditionalRender>
  );

  const LoginFooterLinks = () => (
    <ConditionalRender shouldDisplay={!isRedirected}>
      <Text fz="md" mt="xl" ta="center" style={{ marginBottom: '52px' }}>
        <Link to="/forgot-password" className={classes.linkLookAlike}>
          Forgot password?
        </Link>
      </Text>
      <Text fz="md" mt="xl" ta="center">
        Don’t have an account?{' '}
        <Link className={classes.linkLookAlike} to="/register">
          Register now!
        </Link>
      </Text>
    </ConditionalRender>
  );

  const LoginHeader = () => {
    return isRedirected ? (
      <div>
        <Player
          src={LottieCheckbox}
          loop
          autoplay
          style={{ width: 160, height: 160, marginBottom: 50 }}
        />
        <Title order={4} ta="left" mb="xs">
          Success!
        </Title>
        <Text fz="md" ta="left" mb="xl">
          You are successfully registered to Flow. Use your credentials to log in.
        </Text>
      </div>
    ) : (
      <div>
        <Title order={4} ta="center" mb="xs">
          Welcome to Flow!
        </Title>
        <Text fz="md" ta="center" mb="xl">
          Login with...
        </Text>
      </div>
    );
  };

  return (
    <div className={classes.verticalSpaceContainer}>
      <LoginHeader />
      <div>
        <SocialLoginButtons />
        <form
          onSubmit={handleSubmit((state) => {
            mutate(state);
          })}
        >
          <TextInput
            mb="sm"
            id="email"
            type="email"
            label="Email"
            size="lg"
            {...register('email')}
            value={fields.email}
            error={errors.email?.message}
          />
          <TextInput
            mb="md"
            id="password"
            type="password"
            label="Password"
            size="lg"
            {...register('password')}
            value={fields.password}
            error={errors.password?.message}
          />

          <Button
            type="submit"
            size="lg"
            fullWidth
            loading={isLoading}
            disabled={!isValid || isLoading}
            mb="xl"
            style={{ marginTop: '32px' }}
          >
            Sign in
          </Button>

          <LoginFooterLinks />
        </form>
      </div>
    </div>
  );
};
