import { Button, Center, Text } from '@mantine/core';
// TODO Remove image from assets when we fetch data from BE
import { Link } from 'react-router-dom';
// import DummyRewardImage1 from '../../../assets/reward-dummy.jpg';
// import { RewardListItem } from '../../components/rewards';
// import { CustomerRoutes } from '../../routes';
// import { useGetRewards } from '../../service/rewards';
// import { useUserProfileContext } from '../../service/user/context';

export const CustomerRewardsPage = () => {
  // const { data: rewardsData, isLoading, isError } = useGetRewards();
  // const { userProfile } = useUserProfileContext();

  // if (isLoading)
  //   return (
  //     <Box mt={36}>
  //       <Flex justify="center" mt={30}>
  //         <Loader />
  //       </Flex>
  //     </Box>
  //   );

  // if (isError || !rewardsData) {
  //   return (
  //     <Box mt={36}>
  //       <Alert title="Error" color="red" radius="md" mt={30}>
  //         Error fetching rewards
  //       </Alert>
  //     </Box>
  //   );
  // }

  // return (
  //   <Box mt={36}>
  //     {rewardsData.map((item, itemIndex) => {
  //       const isRewardRedeemable = userProfile?.points.bonus
  //         ? userProfile?.points.bonus > item.bonusPoints
  //         : false;
  //       if (isRewardRedeemable)
  //         return (
  //           <Link
  //             key={itemIndex}
  //             to={`${CustomerRoutes.REWARDS_INFO}/${item.id}`}
  //             style={{ textDecoration: 'none' }}
  //           >
  //             <RewardListItem
  //               title={item.title}
  //               text={item.description}
  //               imageSrc={DummyRewardImage1}
  //               badgePoints={item.bonusPoints}
  //               badgeActive={isRewardRedeemable}
  //             />
  //           </Link>
  //         );
  //       else
  //         return (
  //           <RewardListItem
  //             key={itemIndex}
  //             title={item.title}
  //             text={item.description}
  //             imageSrc={DummyRewardImage1}
  //             badgePoints={item.bonusPoints}
  //             badgeActive={isRewardRedeemable}
  //           />
  //         );
  //     })}
  //   </Box>
  // );

  return (
    <Center h="100%" sx={{ flexDirection: 'column' }}>
      <Text mb={30} fw={600}>
        In Progress
      </Text>
      <Button variant="filled" component={Link} to="/customer/dashboard">
        Back to Home
      </Button>
    </Center>
  );
};
