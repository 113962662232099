import { Box, Text, Title } from '@mantine/core';
import { useNavigate, useParams } from 'react-router';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';

export const InfoNotificationPage = () => {
  const navigate = useNavigate();
  const { id: notificationId } = useParams();

  console.log(notificationId);

  const onBackClick = () => navigate(CustomerRoutes.NOTIFICATIONS);

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Notifications"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />

      <Box mt={46}>
        <Title order={5} mb={8}>
          Notification Title
        </Title>
        <Text
          sx={(theme) => ({
            color: theme.colors.dark[0],
            '> p': {
              marginTop: '0',
            },
          })}
        >
          <p>
            Sollicitudin netus feugiat viverra sollicitudin a scelerisque eu ipsum praesent. Elit
            viverra elementum sodales iaculis. Pretium et vel congue vestibulum pretium praesent
            vulputate bibendum sed.
          </p>

          <p>
            Senectus tempor sem amet duis gravida. Erat a non nulla euismod erat mauris massa
            suscipit in.
          </p>
        </Text>
      </Box>
    </>
  );
};
