import { Box, Button, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { Link, useNavigate } from 'react-router-dom';
import { useStyles } from '../../hooks/use-styles';
import { CustomerRoutes } from '../../routes';
import { useExchangePlaidToken, useGetPlaidLinkToken } from '../../service/plaid';

const getLinkTokenFromStorage = () => localStorage.getItem('linkToken');
const removeLinkTokenFromStorage = () => localStorage.removeItem('linkToken');
const setLinkTokenInStorage = (token: string) => localStorage.setItem('linkToken', token);
const isOAuth = () =>
  window.location.href.includes('?oauth_state_id=') && Boolean(getLinkTokenFromStorage());

export const PlaidLinkAccounts = () => {
  const isOauthFlow = isOAuth();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const {
    data,
    isLoading: isCreatingLinkToken,
    refetch: startPlaidWizard,
  } = useGetPlaidLinkToken({
    enabled: false,
    onSuccess: ({ linkToken }) => {
      setLinkTokenInStorage(linkToken);
    },
    onError: () => {
      notifications.show({
        message: 'Failed to initiate the connection with Plaid.',
      });
    },
  });

  const { mutate: exchangeTokens, isLoading: isExchangingTokens } = useExchangePlaidToken({
    onSuccess: () => {
      navigate(CustomerRoutes.DASHBOARD);
      notifications.show({
        message: 'Successfully connected accounts.',
      });
    },
    onError: () => {
      notifications.show({
        message: 'Error finishing up account connection.',
      });
    },
  });

  const config: PlaidLinkOptions = {
    token: isOauthFlow ? getLinkTokenFromStorage() : data?.linkToken || '',
    onSuccess: async (public_token) => exchangeTokens({ publicToken: public_token }),
    onExit: (error) => {
      if (error !== null) {
        notifications.show({
          message: 'Unsuccessful connection of bank accounts. Please, try again',
        });
      }

      // todo :: should we do this only on error
      removeLinkTokenFromStorage();
    },
    ...(isOauthFlow ? { receivedRedirectUri: window.location.href } : {}),
  };

  const { open, ready, error: plaidError } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  const shouldDisplayConnectOption = !isOauthFlow || (isOauthFlow && plaidError);

  return (
    <Flex direction="column" justify="space-between" sx={{ minHeight: `calc(100vh - 160px)` }}>
      <Box py="180px">
        <Title order={4} mb={6}>
          Connect Bank Accounts
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Welcome to Flow, connect bank accounts through Plaid
        </Text>
        {isExchangingTokens && (
          <Text fz="md" c={theme.colors.dark[1]} mb="xl">
            Finishing up Plaid connection...
          </Text>
        )}
        {isOauthFlow && isCreatingLinkToken && (
          <Text fz="md" c={theme.colors.dark[1]} mb="xl">
            Opening Plaid...
          </Text>
        )}
        {plaidError && (
          <Text color="red" fz="md">
            Error connecting to Plaid. Please try again.
          </Text>
        )}
      </Box>

      {shouldDisplayConnectOption && (
        <Flex direction="column" justify="flex-end">
          <Button
            mb="md"
            size="lg"
            fullWidth
            variant="filled"
            loading={isCreatingLinkToken}
            disabled={isCreatingLinkToken}
            onClick={() => startPlaidWizard()}
          >
            {isCreatingLinkToken ? 'Opening Plaid...' : 'Connect accounts'}
          </Button>
          <Link to={CustomerRoutes.PROFILE} className={classes.linkLookAlike}>
            Connect later
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
