import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Flex, Loader, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { customerObjectiveChangeSchema } from '../../../validation-schemas/objectives';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import {
  CustomerObjectivePayload,
  fetchUserObjectives,
  mutateUserObjective,
} from '../../service/objectives';

export const ObjectiveEditPage = () => {
  const navigate = useNavigate();
  const { id: objectiveId } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery('userObjectives', fetchUserObjectives);
  const objectiveDetailData = data?.find((objective) => objective.id === objectiveId);
  const goBack = () => navigate(-1);

  const { mutate, isLoading: isEditLoading } = useMutation({
    mutationFn: (customerObjectivePayload: CustomerObjectivePayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateUserObjective(objectiveId, customerObjectivePayload),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.userObjectives);
      notifications.show({
        message: 'Successfully modified liability.',
      });
      setTimeout(() => {
        goBack();
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });
  const { formState, watch, register, handleSubmit } = useForm<CustomerObjectivePayload>({
    mode: 'onChange',
    defaultValues: {
      title: objectiveDetailData?.title,
      targetAmount: objectiveDetailData?.targetAmount,
    },
    resolver: yupResolver(customerObjectiveChangeSchema),
  });

  const { title, targetAmount } = watch();

  if (isLoading || isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={goBack}
          backText="Objective detail"
          ctaText="Cancel"
          handleCtaClick={goBack}
        />
        {isLoading ? (
          <Flex justify="center" mt={30}>
            <Loader />
          </Flex>
        ) : (
          <Alert title="Error" color="red" radius="md" mt={30}>
            Error fetching objective
          </Alert>
        )}
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={goBack}
        backText="Objective detail"
        ctaText="Cancel"
        handleCtaClick={goBack}
      />
      <form
        onSubmit={handleSubmit(({ title, targetAmount }) => {
          mutate({
            title: title,
            targetAmount: targetAmount,
          });
        })}
      >
        <Flex gap="md">
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              mb="sm"
              size="lg"
              style={{ width: '100%' }}
              label={'Title'}
              value={title}
              {...register('title')}
              error={formState.errors?.title?.message}
            />
          </Flex>
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              size="lg"
              style={{ width: '100%' }}
              label="Amount"
              value={targetAmount}
              {...register('targetAmount')}
              error={formState.errors?.targetAmount?.message}
            />
          </Flex>
        </Flex>
        <Button
          mt={40}
          size="lg"
          fullWidth
          type="submit"
          variant="filled"
          disabled={!formState.isValid || isEditLoading}
        >
          Save changes
        </Button>
      </form>
    </>
  );
};
