export const AccountsFilledIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1146_5498)">
        <path
          d="M24.4744 8.42762H0.525568V5.57647L12.0883 0.0281751L24.4744 5.56161V8.42762ZM24.4744 18.8433V23.9744H0.525568V18.8433H24.4744ZM3.32557 9.67466H4.87443V17.5963H3.32557V9.67466ZM20.1256 9.67466H21.6744V17.5963H20.1256V9.67466ZM8.92557 9.67466H10.4744V17.5963H8.92557V9.67466ZM14.5256 9.67466H16.0744V17.5963H14.5256V9.67466Z"
          fill="#FC9244"
          stroke="#72729B"
          strokeWidth="0.0511364"
        />
      </g>
      <defs>
        <clipPath id="clip0_1146_5498">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
