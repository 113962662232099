import { CustomerRoutes } from './routes';

export const HiddenHeaderRoutes: string[] = [
  CustomerRoutes.SETTINGS,
  CustomerRoutes.NOTIFICATIONS,
  CustomerRoutes.NOTIFICATION_DETAIL,
  CustomerRoutes.QUESTIONNAIRE,
  CustomerRoutes.SUBSCRIPTION,
  CustomerRoutes.QUESTIONNAIRE_START,
  CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO,
  CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS,
  CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA,
  CustomerRoutes.OBJECTIVE_CREATE,
  CustomerRoutes.ACCOUNTS_CREATE,
  CustomerRoutes.ACCOUNTS_CREATE_FORM,
  CustomerRoutes.ACCOUNTS_EDIT_ASSET,
  CustomerRoutes.ACCOUNTS_EDIT_LIABILITY,
  CustomerRoutes.ACCOUNTS_INFO_ASSET,
  CustomerRoutes.ACCOUNTS_INFO_LIABILITY,
  CustomerRoutes.PROFILE_INFO_EDIT,
  CustomerRoutes.PROFILE_ADDRESS_EDIT,
  CustomerRoutes.PROFILE_FINANCIAL_DATA_EDIT,
  CustomerRoutes.REWARDS_INFO,
  CustomerRoutes.REWARDS_REDEEM,
  CustomerRoutes.REWARDS_MANAGE_ADDRESS,
];
