import { useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import { ChevronIcon } from '../../../../../assets/icons/chevron';
import { CustomerRoutes } from '../../../../routes';
import { useObjectiveCreateStyles } from '../../hooks/use-styles';

interface StepperHeaderProps {
  prevStep?: () => void;
}

export const StepperHeader = ({ prevStep }: StepperHeaderProps) => {
  const { classes: objectiveCreateClasses } = useObjectiveCreateStyles();
  const theme = useMantineTheme();
  return (
    <div className={objectiveCreateClasses.objectiveCreateStepperHeader}>
      {prevStep ? (
        <span className={objectiveCreateClasses.objectiveStepperBack} onClick={prevStep}>
          <ChevronIcon color={theme.colors.dark[0]} />
        </span>
      ) : null}
      <Link className={objectiveCreateClasses.objectiveStepperCancel} to={CustomerRoutes.DASHBOARD}>
        Cancel
      </Link>
    </div>
  );
};
