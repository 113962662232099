import { Box, Text, useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import { ArrowDiagonallyIcon } from '../../../../../assets/icons/arrow-diagonally';
import { USDollar } from '../../../../../helpers/formatter';
import { getSymbolFromCurrency } from '../../../../utils';
import { useAccordionListStyles } from './hooks/use-styles';
interface AccountAccordionListItemProps {
  accountName: string;
  accountAmount: string;
  accountCurrency?: string;
  accountUrl: string;
}

export const AccountAccordionListItem = ({
  accountName,
  accountAmount,
  accountCurrency,
  accountUrl,
}: AccountAccordionListItemProps) => {
  const { classes } = useAccordionListStyles();
  const theme = useMantineTheme();

  const amount =
    accountCurrency && accountCurrency !== 'USD'
      ? `${getSymbolFromCurrency(accountCurrency)} ${accountAmount}`
      : USDollar.format(Number(accountAmount));
  return (
    <Box
      sx={(theme) => ({
        borderTop: `1px solid ${theme.colors.dark[6]}`,
        padding: '8px 4px',
      })}
    >
      <Link className={classes.accountName} to={accountUrl}>
        {accountName}
        <ArrowDiagonallyIcon />
      </Link>
      <Text size="md" fw={500} mt={4} c={theme.colors.dark[1]}>
        {amount}
      </Text>
    </Box>
  );
};
