import { Button, Text, useMantineTheme } from '@mantine/core';
import { ReactElement } from 'react';
import { useInfoBoxStyles } from './hooks/use-styles';

interface ProfileInfoBoxProps {
  title: string;
  text?: string | ReactElement;
  onClickManage?: () => void;
}

export const ProfileInfoBox = ({ title, text, onClickManage }: ProfileInfoBoxProps) => {
  const { classes } = useInfoBoxStyles();
  const theme = useMantineTheme();

  return (
    <div className={classes.profileInfoBox}>
      <div className={classes.profileInfoBoxTitleWrapper}>
        <Text fz="xl" fw={700}>
          {title}
        </Text>
        <Button variant="subtle" onClick={onClickManage}>
          Manage
        </Button>
      </div>
      <Text style={{ color: theme.colors.dark[1] }}>{text}</Text>
    </div>
  );
};
