import { Alert, Box, Flex, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router';
import { USDollar } from '../../../helpers/formatter';
import { BankAssetTransactions } from '../../components/accounts/components/bank-asset-transactions';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { AssetTypeNames, useGetAsset } from '../../service/assets';

export const InfoAccountPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { id: assetId } = useParams();

  const { data: assetData, isLoading, isError } = useGetAsset(assetId);

  const assetName =
    assetData?.institutionName ||
    (assetData?.assetType ? AssetTypeNames[assetData.assetType] : null);

  const assetOfficialName = assetData?.plaidOfficialName || assetData?.plaidName || assetData?.name;
  const assetBalance =
    assetData?.plaidCurrentBalance !== undefined
      ? assetData?.plaidCurrentBalance
      : assetData?.currentValue;

  const onBackClick = () => navigate(CustomerRoutes.ACCOUNTS);

  const onHeaderCtaClick = () => navigate(`${CustomerRoutes.ACCOUNTS_EDIT_ASSET}/${assetData?.id}`);

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Accounts"
          ctaText="Edit account"
          handleCtaClick={onHeaderCtaClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Accounts"
          ctaText="Edit account"
          handleCtaClick={onHeaderCtaClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching assets
        </Alert>
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Accounts"
        ctaText="Edit account"
        handleCtaClick={onHeaderCtaClick}
      />

      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.colors.dark[7]}`,
          backgroundColor: theme.colors.dark[8],
          padding: '24px',
          marginTop: '30px',
          marginBottom: '40px',
          borderRadius: '10px',
        })}
      >
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb="sm" lh="22.4px">
          {assetName}
        </Text>
        <Title order={5} mb="md">
          {assetOfficialName}
        </Title>
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb={6} lh="22.4px">
          Balance
        </Text>
        <Title order={6}>{USDollar.format(Number(assetBalance))}</Title>
      </Box>
      {assetData?.transactions && assetData?.transactions.length > 0 ? (
        <BankAssetTransactions />
      ) : null}
    </>
  );
};
