import * as yup from 'yup';
export const CreateObjective = yup.object().shape({
  objectiveTitle: yup.string().required('Required field.'),
  bankAccountId: yup.string().required('Required field.'),
  objectiveAmount: yup.number().typeError('The value must be a number').required('Required field.'),
});
export const customerObjectiveChangeSchema = yup.object().shape({
  title: yup.string().typeError('The value must be a string').required('Required field.'),
  targetAmount: yup.number().typeError('The value must be a number').required('Required field.'),
});
