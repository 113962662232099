import { Avatar, Badge, Box, Button, FileButton, Flex, LoadingOverlay, Title } from '@mantine/core';
import DummyAvatarImage from '../../../assets/avatar.png';
import { useAuthContext } from '../../../providers';
import { UserProfilePersona, useGetUserProfile } from '../../service';
import { useUserProfileContext } from '../../service/user/context';
import { PointsBadge } from '../badge';
import { BadgeStarIcon } from '../badge-star-icon';
import { useUserProfileInfoStyles } from './hooks/use-styles';
import { useMutation, useQueryClient } from 'react-query';
import * as httpService from '../../../service/http';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { notifications } from '@mantine/notifications';
import axios from 'axios';

export const UserProfileInfo = ({ simple }: { simple?: boolean }) => {
  const { classes } = useUserProfileInfoStyles();
  const { refetch } = useGetUserProfile();
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const { userProfile } = useUserProfileContext();

  const avatarImage = () => {
    if (userProfile?.profilePhotoUrl) {
      return userProfile?.profilePhotoUrl;
    }
    if (user?.attributes?.picture) {
      return user?.attributes?.picture;
    }
    return DummyAvatarImage;
  };

  // Function to fetch the presigned URL
  type PresignedUrlResponse = {
    status: string;
    statusCode: number;
    data: {
      presignedUrl: string;
    };
  };

  type PresignedUrl = {
    presignedUrl: string;
  };

  const fetchPresignedUrl = async (): Promise<PresignedUrl> => {
    const { data } = await httpService.get<PresignedUrlResponse>('/profile/photo/upload-url');
    return data.data;
  };

  const uploadImageToUrl = async (presignedUrl: string, image: File): Promise<any> => {
    await httpService
      .putDefault(presignedUrl, image)
      .then(() => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            queryClient.invalidateQueries([REACT_QUERY_CACHE_KEYS.userProfile]);
            resolve();
          }, 2000);
        });
      })
      .catch((error) => console.log('error', error));
  };

  const uploadImage = async (image: File) => {
    const { presignedUrl } = await fetchPresignedUrl();
    await uploadImageToUrl(presignedUrl, image);
  };

  const { mutate, isLoading } = useMutation(uploadImage, {
    onSuccess: () => {
      notifications.show({
        message: 'Successfully changed picture.',
      });
    },
  });

  const handleImageUpload = async (image: File) => {
    if (image) {
      mutate(image);
    }
  };
  const renderAvatarDetails = () => {
    return (
      <>
        <Title order={5} mt={4}>
          {user?.attributes?.given_name} {user?.attributes?.family_name}
        </Title>
        {userProfile?.persona ? (
          <Badge
            pl={0}
            size="lg"
            radius="xl"
            mt={10}
            mb="sm"
            leftSection={BadgeStarIcon}
            classNames={{
              root: classes.profileBadge,
              leftSection: classes.profileBadgeLeftSection,
            }}
          >
            {UserProfilePersona[userProfile?.persona]}
          </Badge>
        ) : null}
        <PointsBadge value={userProfile?.points?.bonus || 0} />
      </>
    );
  };
  return (
    <div className={classes.userProfileInfoWrapper}>
      <div className={classes.userProfileInfo}>
        <Flex justify="center" align="center" direction="column" pos="relative">
          <Avatar size="lg" radius="50%" src={avatarImage()} alt="it's me"></Avatar>

          <LoadingOverlay
            visible={isLoading}
            overlayBlur={1}
            loaderProps={{ size: 20 }}
            radius="50%"
          />
        </Flex>
        <FileButton onChange={handleImageUpload} accept="image/png,image/jpeg" disabled={isLoading}>
          {(props) => (
            <Button {...props} sx={{ overflow: 'hidden' }} variant="subtle" size="xs">
              Change Picture
            </Button>
          )}
        </FileButton>
        {!simple && renderAvatarDetails()}
      </div>
    </div>
  );
};
