import { Button, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { useMutation } from 'react-query';
import { useAuthContext } from '../../../providers';
import { InvalidPasswordException } from '../../../providers/authentication/service';
import { AuthFormFields, useAuthenticationFields } from '../../hooks';
import { useStyles } from '../../hooks/use-styles';

export const RegisterPage = () => {
  const { signUp } = useAuthContext();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { mutate, isLoading } = useMutation<void, InvalidPasswordException, AuthFormFields>({
    mutationFn: ({ email, password }) => signUp(email, password),
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useAuthenticationFields();

  const fields = watch();

  return (
    <div className={classes.verticalSpaceContainer}>
      <div>
        <Title order={4} mb="xs">
          Register with email
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Welcome to Flow, login with
        </Text>
      </div>
      <div>
        <form
          onSubmit={handleSubmit((state) => {
            mutate(state);
          })}
        >
          <TextInput
            size="lg"
            mb="md"
            id="email"
            type="email"
            label="Your email"
            {...register('email')}
            value={fields.email}
            error={errors.email?.message}
          />
          <TextInput
            size="lg"
            mb="md"
            id="password"
            type="password"
            label="Choose password"
            {...register('password')}
            value={fields.password}
            error={errors.password?.message}
          />

          <Button
            size="lg"
            fullWidth
            type="submit"
            variant="filled"
            loading={isLoading}
            disabled={!isValid || isLoading}
            style={{ marginTop: '36px' }}
          >
            Sign up
          </Button>
        </form>
      </div>
    </div>
  );
};
