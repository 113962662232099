export const HomeIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20376 0.616382C6.71731 0.216894 7.34939 0 8.00002 0C8.65068 0 9.28277 0.216907 9.79633 0.616418M9.79633 0.616418L14.3633 4.16863C14.3633 4.16856 14.3634 4.16871 14.3633 4.16863C15.0766 4.72289 15.4937 5.5757 15.4937 6.47862V12.6448C15.4937 13.2695 15.2456 13.8685 14.8039 14.3102C14.3622 14.7519 13.7632 15 13.1385 15H2.8615C2.23688 15 1.63784 14.7519 1.19616 14.3102C0.754479 13.8685 0.506348 13.2695 0.506348 12.6448V6.47862C0.506184 6.03308 0.607815 5.59339 0.803485 5.19311C0.9992 4.79274 1.28382 4.44239 1.63561 4.16881L6.20376 0.616382C6.20373 0.616407 6.20379 0.616357 6.20376 0.616382M8.00002 1.28463C7.63506 1.28463 7.28052 1.4063 6.99246 1.63039L2.42423 5.1829C2.42422 5.1829 2.42423 5.1829 2.42423 5.1829C2.22696 5.33631 2.06735 5.53277 1.9576 5.75728C1.84785 5.98179 1.79086 6.22842 1.79098 6.47832L1.79098 12.6448C1.79098 12.9288 1.90377 13.2011 2.10453 13.4018C2.30529 13.6026 2.57758 13.7154 2.8615 13.7154H13.1385C13.4225 13.7154 13.6948 13.6026 13.8955 13.4018C14.0963 13.2011 14.2091 12.9288 14.2091 12.6448V6.47862C14.2091 5.97199 13.9752 5.49381 13.5752 5.18308L9.00763 1.63043C8.71958 1.40634 8.36498 1.28463 8.00002 1.28463Z"
        fill="#64748B"
      />
    </svg>
  );
};
