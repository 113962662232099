import { Button, useMantineTheme } from '@mantine/core';
import { ChevronIcon } from '../../../../../assets/icons/chevron';
import { useBackButtonStyles } from '../../hooks/use-styles';

export const BackButton = ({ prevStep }: { prevStep: () => void }) => {
  const { classes } = useBackButtonStyles();
  const theme = useMantineTheme();

  return (
    <Button
      className={classes.backButton}
      variant="subtle"
      onClick={prevStep}
      style={{ position: 'absolute', top: '34px' }}
      leftIcon={
        <span>
          <ChevronIcon color={theme.colors.dark[0]} />
        </span>
      }
    >
      Back
    </Button>
  );
};
