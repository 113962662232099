import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../../providers';
import { CustomerRoutes } from '../../../routes';

// todo :: i
export const CreateAccountGuard = ({ children }: PropsWithChildren) => {
  const { isAccountCreationFinished } = useAuthContext();
  const { pathname } = useLocation();

  console.log(pathname);
  if (isAccountCreationFinished === 3) {
    return <Navigate to={CustomerRoutes.DASHBOARD} />;
  } else if (
    isAccountCreationFinished === 0 &&
    pathname !== CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO
  ) {
    return <Navigate to={CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO} />;
  } else if (
    isAccountCreationFinished === 1 &&
    pathname !== CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS
  ) {
    return <Navigate to={CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS} />;
  } else if (
    isAccountCreationFinished === 2 &&
    pathname !== CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA
  ) {
    return <Navigate to={CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA} />;
  }

  return <>{children}</>;
};
