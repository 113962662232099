export const EducationFilledIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.35459 12.3635V18.9858L11.5 23.4L19.6455 18.9858V12.3635L11.5 16.7778L3.35459 12.3635Z"
        fill="#FC9244"
      />
      <path
        d="M11.5 14.4666L21.9728 8.79286V18.7778H24.3V7.53331L11.5 0.599976L0.300049 6.66664V8.39998L11.5 14.4666Z"
        fill="#FC9244"
      />
    </svg>
  );
};
