import { Badge, Box, Button, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useStyles } from '../../../../hooks/use-styles';
import { CustomerRoutes } from '../../../../routes';
import { BadgeStarIcon } from '../../../badge-star-icon';
import { useUserProfileInfoStyles } from '../../../user-profile-info/hooks/use-styles';

interface QuestionnaireSuccessProps {
  spenderType: string;
}

export const QuestionnaireSuccess = ({ spenderType }: QuestionnaireSuccessProps) => {
  const { classes } = useStyles();
  const { classes: userProfileInfoStylesClasses } = useUserProfileInfoStyles();
  return (
    <div className={classes.verticalSpaceContainer} style={{ textAlign: 'center' }}>
      <div>
        <Title order={4} mb="xl" ta="center">
          Based on your answers, you are:
        </Title>
        <Badge
          pl={0}
          size="lg"
          radius="xl"
          leftSection={BadgeStarIcon}
          classNames={{
            root: userProfileInfoStylesClasses.profileBadge,
            leftSection: userProfileInfoStylesClasses.profileBadgeLeftSection,
          }}
        >
          {spenderType}
        </Badge>
      </div>
      <div>
        <Box mb="50px">
          <Text size="md">
            <p>
              Sollicitudin netus feugiat viverra sollicitudin a scelerisque eu ipsum praesent. Elit
              viverra elementum sodales iaculis. Pretium et vel congue vestibulum pretium praesent
              vulputate bibendum sed.
            </p>
            <p>
              Senectus tempor sem amet duis gravida. Erat a non nulla euismod erat mauris massa
              suscipit in.
            </p>
          </Text>
        </Box>
        <Link to={CustomerRoutes.SUBSCRIPTION} style={{ textDecoration: 'none' }}>
          <Button size="lg" fullWidth>
            Got it
          </Button>
        </Link>
      </div>
    </div>
  );
};
