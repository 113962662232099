import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../../../providers';
import { CustomerRoutes } from '../../../routes';
import { useUserProfileContext } from '../../../service/user/context';

export const CustomerGuard = ({ children }: PropsWithChildren) => {
  const { userProfile } = useUserProfileContext();
  const { isAccountCreationFinished } = useAuthContext();
  const location = useLocation();
  const isAllowedToPass = [isAccountCreationFinished].every(Boolean);

  if (isAllowedToPass) {
    if (userProfile?.subscribed) {
      if (location.pathname === CustomerRoutes.SUBSCRIPTION) {
        return <Navigate to={CustomerRoutes.PROFILE} />;
      }
      return <>{children}</>;
    } else {
      if (location.pathname === CustomerRoutes.SUBSCRIPTION) {
        return <>{children}</>;
      }
      return <Navigate to={CustomerRoutes.SUBSCRIPTION} />;
    }
  }

  return isAccountCreationFinished ? (
    <Navigate to={CustomerRoutes.QUESTIONNAIRE_START} />
  ) : (
    <Navigate to={CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO} />
  );
};
