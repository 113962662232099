import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Select, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { updateUserAttributes } from '../../../providers/authentication/service';
import { CustomerRoutes } from '../../routes';
import {
  FinancialDataPayload,
  Frequency,
  RelationshipStatus,
  mutateFinancialData,
} from '../../service';
import useStyles from './use-styles';

export interface FinancialDataFields {
  incomeTitle: string;
  annualAmount: number;
  frequency: `${Frequency}`;
  relationshipStatus: RelationshipStatus;
  dependents: string;
}

export const FormSection = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  return (
    <Box pb="40px">
      <Text size="lg" mb="md">
        {title}
      </Text>
      {children}
    </Box>
  );
};

export const CreateAccountFinancialDataPage = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { formState, watch, register, handleSubmit, control } = useForm<FinancialDataFields>({
    mode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      yup.object().shape({
        incomeTitle: yup.string().required('Required field.'),
        annualAmount: yup.number().required('Required field.'),
        frequency: yup.string().required('Required field.'),
        relationshipStatus: yup.string().required('Required field.'),
        dependents: yup.string().required('Required field.'),
      }),
    ),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (financialDataPayload: FinancialDataPayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateFinancialData(financialDataPayload),
    onSuccess: () => {
      updateUserAttributes({
        'custom:accountDone': '2',
      });
      navigate(CustomerRoutes.SUBSCRIPTION);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { incomeTitle, annualAmount, frequency, relationshipStatus, dependents } = watch();

  return (
    <Box>
      <Box pt={60} pb={30}>
        <Title order={4} mb={6}>
          Your Financial Data
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Input all your sources of income and their frequency.
        </Text>
      </Box>

      <form
        onSubmit={handleSubmit(
          ({
            incomeTitle,
            annualAmount,
            frequency,
            relationshipStatus,
            dependents: dependentsString,
          }) => {
            const dependents = Number(dependentsString);
            mutate({ incomeTitle, annualAmount, frequency, relationshipStatus, dependents });
          },
        )}
      >
        <FormSection title="Income Data">
          <TextInput
            mb="sm"
            size="lg"
            label="Income Title"
            value={incomeTitle}
            {...register('incomeTitle')}
            error={formState.errors?.incomeTitle?.message}
            classNames={{ label: classes.inputLabel }}
          />
          <TextInput
            mb="md"
            size="lg"
            label="Annual Amount"
            value={annualAmount}
            {...register('annualAmount')}
            error={formState.errors?.annualAmount?.message}
            classNames={{ label: classes.inputLabel }}
          />
          <Controller
            render={({ field }) => (
              <Select
                size="lg"
                mb="xl"
                label="Frequency"
                placeholder="Select an frequency"
                data={[
                  { value: Frequency.weekly, label: 'Weekly' },
                  { value: Frequency.biweekly, label: 'Biweekly' },
                  { value: Frequency.monthly, label: 'Monthly' },
                ]}
                {...field}
                value={frequency}
                error={formState.errors?.frequency?.message}
                classNames={{ label: classes.inputLabel }}
              />
            )}
            control={control}
            name="frequency"
          />
        </FormSection>
        <FormSection title="Financial Data">
          <Controller
            render={({ field }) => (
              <Select
                size="lg"
                mb="xl"
                label="Relationship Status"
                placeholder="Select status"
                data={[
                  { value: 'single', label: 'Single' },
                  { value: 'married', label: 'Married' },
                  { value: 'divorced', label: 'Divorced' },
                ]}
                {...field}
                value={relationshipStatus}
                error={formState.errors?.relationshipStatus?.message}
                classNames={{ label: classes.inputLabel }}
              />
            )}
            control={control}
            name="relationshipStatus"
          />
          <Controller
            render={({ field }) => (
              <Select
                size="lg"
                mb="xl"
                label="Dependents"
                placeholder="Select number of dependents"
                data={[
                  { value: '0', label: 'No Dependent' },
                  { value: '1', label: '1 Dependent' },
                  { value: '2', label: '2 Dependents' },
                  { value: '3', label: '3 Dependents' },
                  { value: '4', label: '4 Dependents' },
                  { value: '5', label: '5 Dependents' },
                  { value: '6', label: '6 Dependent' },
                  { value: '7', label: '7 Dependents' },
                  { value: '8', label: '8 Dependents' },
                  { value: '9', label: '9 Dependents' },
                  { value: '10', label: '10 Dependents' },
                ]}
                {...field}
                value={dependents}
                error={formState.errors?.dependents?.message}
                classNames={{ label: classes.inputLabel }}
              />
            )}
            control={control}
            name="dependents"
          />
        </FormSection>
        <Button
          size="lg"
          fullWidth
          type="submit"
          variant="filled"
          loading={isLoading}
          disabled={!formState.isValid || isLoading}
        >
          Create
        </Button>
      </form>
    </Box>
  );
};
