import { Box, Button, Checkbox, Stepper, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { updateUserAttributes } from '../../../../../providers/authentication/service';
import { useStyles } from '../../../../hooks/use-styles';
import { CustomerRoutes } from '../../../../routes';
import {
  QuestionnairePayload,
  mutateQuestionnaire,
  useGetQuestionnaire,
} from '../../../../service';
import { CheckboxIcon } from '../../../checkbox-icon';
import { BackButton } from '../back-button';

const useMapQuestionnaireDataPayload = (questionnairePayload: QuestionnairePayload) => {
  const answerObj = Object.entries(questionnairePayload).map(([key, val]) => {
    return {
      answer: val,
      questionId: key,
    };
  });
  return {
    questionnaire: [...answerObj],
  };
};

interface QuestionnaireStepperProps {
  handleSubmit: any;
}

export const QuestionnaireStepper = ({ handleSubmit }: QuestionnaireStepperProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const { data: questionnaireData, isLoading, isError } = useGetQuestionnaire();

  const [questionnairePayload, setQuestionnairePayload] = useState({} as QuestionnairePayload);
  const mappedQuestionnairePayload = useMapQuestionnaireDataPayload(questionnairePayload);

  const { mutate, isLoading: isSubmitLoading } = useMutation({
    mutationFn: (questionnairePayload: QuestionnairePayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateQuestionnaire(mappedQuestionnairePayload),
    onSuccess: () => {
      updateUserAttributes({
        'custom:accountDone': '3',
      });
      handleSubmit('Big Spender');
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  if (!questionnaireData) {
    navigate(CustomerRoutes.DASHBOARD);
    return null;
  }

  const nextStep = () =>
    setActive((current) => (current < questionnaireData?.length ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const sortedQuestionnaireData = questionnaireData?.sort((a, b) => a.order - b.order);

  if (isLoading) return null;

  if (isError) {
    return <div>Error fetching questionnaire</div>;
  }

  const onSubmit = () => {
    mutate(questionnairePayload);
  };

  return (
    <Stepper
      active={active}
      onStepClick={setActive}
      breakpoint="sm"
      allowNextStepsSelect={false}
      contentPadding={0}
      className={classes.stepperWithoutSteps}
    >
      {sortedQuestionnaireData?.map((question, index) => (
        <Stepper.Step withIcon={false} key={index}>
          {active > 0 ? <BackButton prevStep={prevStep} /> : null}
          <div className={classes.verticalSpaceContainer}>
            <div>
              <Text fz="lg" mb="sm" color={theme.colors.dark[1]}>
                {question.question}
              </Text>
              <Title order={4} mb="xl">
                {question.title}
              </Title>
            </div>
            <div>
              <Box mb="50px">
                {Object.entries(question?.answers).map((answer, index) => (
                  <Checkbox
                    key={index}
                    className={classes.customCheckbox}
                    onChange={() =>
                      setQuestionnairePayload({
                        ...questionnairePayload,
                        [question.id]: answer[1].letter,
                      })
                    }
                    checked={questionnairePayload[question.id] === answer[1].letter ? true : false}
                    size="xl"
                    value={answer[1].letter}
                    label={answer[1].text}
                    mb="md"
                    icon={CheckboxIcon}
                  />
                ))}
              </Box>
              {questionnaireData && active < questionnaireData?.length - 1 ? (
                <Button
                  size="lg"
                  fullWidth
                  onClick={nextStep}
                  disabled={!questionnairePayload[question.id]}
                >
                  Next
                </Button>
              ) : (
                <Button
                  loading={isSubmitLoading}
                  size="lg"
                  fullWidth
                  onClick={onSubmit}
                  disabled={questionnaireData?.length !== Object.keys(questionnairePayload).length}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Stepper.Step>
      ))}
    </Stepper>
  );
};
