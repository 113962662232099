import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  verticalSpaceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 80px)',
    [`&::before`]: {
      content: `''`,
      display: 'block',
      margin: '1px 1px auto 1px',
    },
    [`& > div:last-child`]: {
      marginTop: 'auto',
    },
  },
  linkLookAlike: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: theme.colors.orange[4],
    textDecoration: 'none',
    fontWeight: 700,
    [`&:hover`]: {
      color: theme.colors.blue[4],
    },
  },
  stepperWithoutSteps: {
    '& .mantine-Stepper-separator': {
      display: 'none',
    },
  },
  customCheckbox: {
    '& .mantine-Checkbox-input:checked + div > svg': {
      opacity: 1,
    },
  },
  customCheckboxIconWrapper: {
    '& svg': {
      position: 'absolute',
      inset: '0rem',
      color: 'rgb(255, 255, 255)',
      transform: 'translateY(0rem) scale(0.35)',
      transitionPoperty: 'opacity, transform',
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
      zIndex: 1,
      margin: 'auto',
      padding: '0',
      opacity: 0,
    },
  },
}));
