import { Button, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useStyles } from '../../hooks/use-styles';
import { CustomerRoutes } from '../../routes';

export const QuestionnaireStartPage = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.verticalSpaceContainer}>
      <div>
        <Title order={4} ta="center" mb="xs">
          We would like to know you better
        </Title>
        <Text fz="md" ta="center" mb="xl">
          Fill in a quick questionnaire in order to get a more accurate and personalized experience.
        </Text>
      </div>
      <div>
        <Link to={CustomerRoutes.QUESTIONNAIRE} style={{ textDecoration: 'none' }}>
          <Button size="lg" fullWidth>
            Start Now
          </Button>
        </Link>
      </div>
    </div>
  );
};
