import { createStyles, rem } from '@mantine/core';

export const useCheckboxCardStyles = createStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderRadius: '10px',
    padding: theme.spacing.xl,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
    },
  },
}));
