import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useAppStore } from '../../../store/appStore';
import { redeemAddressSchema } from '../../../validation-schemas/redeem-address';
import { PhoneInput } from '../../components/common/phone-input';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
interface Fields {
  city: string;
  state: string;
  zipCode: string;
  primaryAddress: string;
  phoneNumber: string;
}

export const ManageAddressRewardPage = () => {
  const navigate = useNavigate();
  const { id: rewardId } = useParams();

  const onBackClick = () => navigate(`${CustomerRoutes.REWARDS_REDEEM}/${rewardId}`);

  const { setRedeemSecondaryAddress, redeemSecondaryAddress: redeemSecondaryAddressJson } =
    useAppStore((state) => {
      return {
        setRedeemSecondaryAddress: state.setRedeemSecondaryAddress,
        redeemSecondaryAddress: state.redeemSecondaryAddress,
      };
    });

  const redeemSecondaryAddress = redeemSecondaryAddressJson
    ? JSON.parse(redeemSecondaryAddressJson)
    : {};

  const { formState, watch, register, handleSubmit, control } = useForm<Fields>({
    defaultValues: {
      city: redeemSecondaryAddress.city,
      state: redeemSecondaryAddress.state,
      zipCode: redeemSecondaryAddress.postalCode,
      primaryAddress: redeemSecondaryAddress.addressLine1,
      phoneNumber: redeemSecondaryAddress.contactPhone,
    },
    mode: 'onChange',
    resolver: yupResolver(redeemAddressSchema),
  });

  const handleClick = (data: Fields) => {
    const redeemSecondaryAddress = JSON.stringify({
      addressLine1: data.primaryAddress,
      city: data.city,
      contactPhone: data.phoneNumber,
      postalCode: data.zipCode,
      state: data.state,
    });

    setRedeemSecondaryAddress(redeemSecondaryAddress);
    navigate(`${CustomerRoutes.REWARDS_REDEEM}/${rewardId}`);
  };

  const { primaryAddress, city, state, zipCode } = watch();

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Manage Address"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />

      <Box mt={30}>
        <form
          onSubmit={handleSubmit((data) => {
            handleClick(data);
          })}
        >
          <Box>
            <TextInput
              mb="sm"
              size="lg"
              label="Address 1"
              value={primaryAddress}
              {...register('primaryAddress')}
              error={formState.errors?.primaryAddress?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="City"
              value={city}
              {...register('city')}
              error={formState.errors?.city?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="State"
              value={state}
              {...register('state')}
              error={formState.errors?.state?.message}
            />
            <TextInput
              size="lg"
              label="Zip Code"
              value={zipCode}
              {...register('zipCode')}
              error={formState.errors?.zipCode?.message}
            />
            <PhoneInput formState={formState} control={control} />
          </Box>
          <Button size="lg" fullWidth type="submit" variant="filled" disabled={!formState.isValid}>
            Apply
          </Button>
        </form>
      </Box>
    </>
  );
};
