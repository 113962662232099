import { Alert, Badge, Box, Flex, Loader, Progress, Text, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { fetchUserObjectives } from '../../service/objectives';
import { useQuery } from 'react-query';
import { fetchUserTasks } from '../../service/upcoming-tasks';

export const ObjectiveInfoPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { id: objectiveId } = useParams();

  const { data, isLoading, isError } = useQuery('userObjectives', fetchUserObjectives);

  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    isError: isErrorLoadingTasks,
  } = useQuery('userUpcomingTasks', fetchUserTasks);

  const objectiveDetailData = data?.find((objective) => objective.id === objectiveId);

  const renderUpcomingTasks = () => {
    if (isLoadingTasks) {
      return (
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      );
    }
    if (isErrorLoadingTasks) {
      return (
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching objective
        </Alert>
      );
    }
    if (tasksData) {
      const upcomingTasks = tasksData.tasks[Object.keys(tasksData.tasks)[0]];
      return upcomingTasks.map((task, index) => (
        <Box
          p={25}
          mb="md"
          mt="16px"
          sx={{ borderRadius: theme.radius.md, border: '1px solid #343449' }}
          bg={theme.colors.dark[8]}
          key={task.objectiveId + index}
        >
          <Flex>
            <div
              style={{
                width: '3px',
                backgroundColor: '#698AFF',
                borderRadius: '3px',
                marginRight: '10px',
              }}
            ></div>
            <Box sx={{ flex: 1 }}>
              <Text size="18px" c="#fff" fw={700}>
                Contribute ${task.taskAmount}
              </Text>
              <Flex justify="space-between" align="end" mt="8px">
                <Text size="14px" c={theme.colors.dark[3]}>
                  Transfer{' '}
                  <Text span c="#fff" inherit>
                    ${task.taskAmount}
                  </Text>{' '}
                  to{' '}
                  <Text span c="#fff" inherit>
                    {task.bankAccountName}.
                  </Text>
                </Text>
                <Badge
                  variant="outline"
                  size="lg"
                  radius="md"
                  color="orange"
                  sx={{ flexShrink: 0, padding: '5px 8px', fontSize: '12px' }}
                >
                  {task.bonusPoints} BP
                </Badge>
              </Flex>
            </Box>
          </Flex>
        </Box>
      ));
    }
    return null;
  };

  const goBack = () => navigate(CustomerRoutes.DASHBOARD);
  const editObjective = () => navigate(`${CustomerRoutes.OBJECTIVE_EDIT}/${objectiveId}`);

  if (isLoading || isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={goBack}
          backText="Dashboard"
          ctaText="Edit objective"
          handleCtaClick={editObjective}
        />
        {isLoading ? (
          <Flex justify="center" mt={30}>
            <Loader />
          </Flex>
        ) : (
          <Alert title="Error" color="red" radius="md" mt={30}>
            Error fetching objective
          </Alert>
        )}
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={goBack}
        backText="Dashboard"
        ctaText="Edit objective"
        handleCtaClick={editObjective}
      />
      <Box p={25} mx={-24} mt={-70} bg={theme.colors.dark[8]} key={objectiveDetailData?.id}>
        <Flex justify="space-between" align="center">
          <Text size="xl" c={theme.white} fw={700} sx={{ lineHeight: '1.625rem' }}>
            {objectiveDetailData?.title}
          </Text>
        </Flex>
        <Badge
          variant="outline"
          size="lg"
          radius="md"
          color="orange"
          sx={{ flexShrink: 0, padding: '5px 8px', fontSize: '12px' }}
        >
          {objectiveDetailData?.xpPoints} XP
        </Badge>
        <Text size="lg" fw={600} h="20px">
          <span style={{ color: theme.colors.blue[4] }}>$3.850 /</span> $4.100
        </Text>
        <Text size="sm" c={theme.colors.dark[3]}>
          $150 remaining
        </Text>
        <Flex justify="space-between" align="center">
          <Progress value={92} w="100%" />
          <Text sx={{ flexShrink: 0, margin: '0 9px' }}>92%</Text>
        </Flex>
      </Box>
      <Box my={26}>
        <Text fw={700} fz={18} color="#72729B" lh="21.6px">
          Upcoming Tasks
        </Text>
        {renderUpcomingTasks()}
      </Box>
    </>
  );
};
