import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { profileAddressSchema } from '../../../validation-schemas/profile-address';
import { PhoneInput } from '../../components/common/phone-input';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';

interface Fields {
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  primaryAddress: string;
  secondaryAddress: string;
}

export const EditCustomerProfileAddressPage = () => {
  const navigate = useNavigate();
  const { updateUserAttributes, user } = useAuthContext();
  const { formState, watch, register, handleSubmit, control } = useForm<Fields>({
    mode: 'onChange',
    defaultValues: {
      city: user?.attributes?.['custom:city'],
      state: user?.attributes?.['custom:state'],
      zipCode: user?.attributes?.['custom:zipCode'],
      phoneNumber: user?.attributes?.['custom:phoneNumber'],
      primaryAddress: user?.attributes?.['custom:primaryAddress'],
      secondaryAddress: user?.attributes?.['custom:secondaryAddress'],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(profileAddressSchema),
  });

  const { mutate, isLoading } = useMutation<void, Exception, Fields>({
    mutationFn: ({ city, state, zipCode, primaryAddress, secondaryAddress }) =>
      updateUserAttributes({
        'custom:city': city,
        'custom:state': state,
        'custom:zipCode': zipCode,
        'custom:phoneNumber': phoneNumber,
        'custom:primaryAddress': primaryAddress,
        'custom:secondaryAddress': secondaryAddress,
      }),
    onSuccess: () => {
      navigate(CustomerRoutes.SETTINGS);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { primaryAddress, secondaryAddress, city, state, zipCode, phoneNumber } = watch();

  const onBackClick = () => navigate(CustomerRoutes.SETTINGS);

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="User Address"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />
      <form
        style={{ height: 'calc(100% - 70px)' }}
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <Flex pt={30} direction="column" justify="space-between" style={{ height: '100%' }}>
          <Box>
            <TextInput
              mb="sm"
              size="lg"
              label="Address 1"
              value={primaryAddress}
              {...register('primaryAddress')}
              error={formState.errors?.primaryAddress?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="Address 2"
              value={secondaryAddress}
              {...register('secondaryAddress')}
              error={formState.errors?.secondaryAddress?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="City"
              value={city}
              {...register('city')}
              error={formState.errors?.city?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="State"
              value={state}
              {...register('state')}
              error={formState.errors?.state?.message}
            />
            <TextInput
              size="lg"
              label="Zip Code"
              mb="sm"
              value={zipCode}
              {...register('zipCode')}
              error={formState.errors?.zipCode?.message}
            />
            <PhoneInput formState={formState} control={control} />
          </Box>
          <Button
            size="lg"
            fullWidth
            type="submit"
            variant="filled"
            loading={isLoading}
            disabled={!formState.isValid || isLoading}
          >
            Done
          </Button>
        </Flex>
      </form>
    </>
  );
};
