import { Alert, Box, Button, Flex, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../providers';
import { useAppStore } from '../../../store/appStore';
import { CheckboxCard } from '../../components/common/checkbox-card';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { RewardPayload, mutateRedeemReward, useGetReward } from '../../service/rewards';

type RedeemAddress = {
  addressLine1: string;
  city: string;
  contactPhone: string;
  postalCode: string;
  state: string;
};

export const RedeemRewardPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { user } = useAuthContext();
  const { id: rewardId } = useParams();
  const userProfileAddressObj = {
    city: user?.attributes?.['custom:city'] || '',
    state: user?.attributes?.['custom:state'] || '',
    contactPhone: user?.attributes?.['custom:phoneNumber'] || '',
    postalCode: user?.attributes?.['custom:zipCode'] || '',
    addressLine1: user?.attributes?.['custom:primaryAddress'] || '',
  };

  const { data: rewardData, isLoading, isError } = useGetReward(rewardId);

  const { redeemSecondaryAddress: redeemSecondaryAddressJson, clearRedeemSecondaryAddress } =
    useAppStore((state) => {
      return {
        redeemSecondaryAddress: state.redeemSecondaryAddress,
        clearRedeemSecondaryAddress: state.clearRedeemSecondaryAddress,
      };
    });

  const redeemSecondaryAddress = redeemSecondaryAddressJson
    ? JSON.parse(redeemSecondaryAddressJson)
    : {};

  const redeemAddresses: { [key: string]: RedeemAddress } = {
    cognitoAddress: userProfileAddressObj,
    secondaryAddress: redeemSecondaryAddress,
  };

  const onBackClick = () => {
    clearRedeemSecondaryAddress();
    navigate(`${CustomerRoutes.REWARDS_INFO}/${rewardId}`);
  };

  const [checkedCardCheckbox, setCheckedCardCheckbox] = useState<string>(
    Object.keys(redeemSecondaryAddress).length ? 'secondaryAddress' : 'cognitoAddress',
  );

  const handleCardCheckboxChange = (id: string) => {
    setCheckedCardCheckbox(id);
  };

  const { mutate, isLoading: isLoadingMutate } = useMutation({
    mutationFn: (rewardPayload: RewardPayload) => mutateRedeemReward(rewardId, rewardPayload),
    onSuccess: () => {
      notifications.show({
        message: 'Successfully redeem reward.',
      });
      clearRedeemSecondaryAddress();
      setTimeout(() => {
        navigate(CustomerRoutes.REWARDS);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Reward"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError || !rewardData || !rewardId) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Reward"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching reward
        </Alert>
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText={rewardData.title}
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />

      <Box mt={30}>
        <Title order={5} c={theme.white} mb={8}>
          Select delivery address
        </Title>
        <Text size="md" fw={700} c={theme.white} mb={40}>
          Select the address that reward is being delivered to.
        </Text>
        {userProfileAddressObj ? (
          <CheckboxCard
            mb="sm"
            defaultChecked
            checked={checkedCardCheckbox === 'cognitoAddress'}
            title="My Default Address"
            onChange={() => handleCardCheckboxChange('cognitoAddress')}
            descriptionArray={[
              `${userProfileAddressObj.addressLine1},`,
              `${userProfileAddressObj.city}, ${userProfileAddressObj.state}`,
              `${userProfileAddressObj.postalCode}`,
            ]}
          />
        ) : null}
        {Object.keys(redeemSecondaryAddress).length ? (
          <CheckboxCard
            mb="sm"
            title="Secondary address"
            checked={checkedCardCheckbox === 'secondaryAddress'}
            onChange={() => handleCardCheckboxChange('secondaryAddress')}
            descriptionArray={[
              `${redeemSecondaryAddress.addressLine1},`,
              `${redeemSecondaryAddress.city}, ${redeemSecondaryAddress.state}`,
              `${redeemSecondaryAddress.postalCode}`,
            ]}
          />
        ) : null}
        <Box mt={30} ta="center">
          <Link
            to={`${CustomerRoutes.REWARDS_MANAGE_ADDRESS}/${rewardId}`}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="subtle">Use different address</Button>
          </Link>
        </Box>
        <Button
          fullWidth
          size="lg"
          mt={40}
          loading={isLoadingMutate}
          onClick={() =>
            mutate({
              deliveryAddress: redeemAddresses[checkedCardCheckbox],
            })
          }
        >
          Redeem
        </Button>
      </Box>
    </>
  );
};
