import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import { QuestionnaireStepper, QuestionnaireSuccess } from '../../components/questionnaire';
import { CustomerRoutes } from '../../routes';
import { UserProfileData } from '../../service';

export const QuestionnairePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [questionnaireSuccess, setQuestionnaireSuccess] = useState(false);
  const [spenderType, setSpenderType] = useState('');

  const { user } = useAuthContext();
  console.log(user);

  const handleSubmit = async (spenderType: string) => {
    setSpenderType(spenderType);
    setQuestionnaireSuccess(true);
    queryClient.setQueryData<UserProfileData>('userProfile', (oldData) => ({
      ...(oldData as UserProfileData),
      hasSubmittedQuestionnaire: true,
    }));
    queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.questionnaire);
    navigate(CustomerRoutes.PLAID_LINK_ACCOUNTS);
  };

  return questionnaireSuccess ? (
    <QuestionnaireSuccess spenderType={spenderType} />
  ) : (
    <QuestionnaireStepper handleSubmit={handleSubmit} />
  );
};
