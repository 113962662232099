import { createStyles } from '@mantine/core';

export const useInfoBoxStyles = createStyles((theme) => ({
  profileInfoBox: {
    background: theme.colors.dark[8],
    border: `1px solid ${theme.colors.dark[7]}`,
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '16px',
  },
  profileInfoBoxTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
}));
