import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { profileAddressSchema } from '../../../validation-schemas/profile-address';
import { PhoneInput } from '../../components/common/phone-input';
import { CustomerRoutes } from '../../routes';
interface Fields {
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  primaryAddress: string;
  secondaryAddress: string;
}

export const CreateAccountAddressPage = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { updateUserAttributes } = useAuthContext();

  const { formState, watch, register, handleSubmit, control } = useForm<Fields>({
    mode: 'onChange',
    defaultValues: {
      city: '',
      state: '',
      zipCode: '',
      phoneNumber: '',
      primaryAddress: '',
      secondaryAddress: '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(profileAddressSchema),
  });

  const { mutate, isLoading } = useMutation<void, Exception, Fields>({
    mutationFn: ({ city, state, zipCode, phoneNumber, primaryAddress, secondaryAddress }) =>
      updateUserAttributes({
        'custom:city': city,
        'custom:state': state,
        'custom:zipCode': zipCode,
        'custom:phoneNumber': phoneNumber,
        'custom:primaryAddress': primaryAddress,
        'custom:secondaryAddress': secondaryAddress,
        'custom:accountDone': '2',
      }),
    onSuccess: () => {
      navigate(CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { primaryAddress, secondaryAddress, city, state, zipCode } = watch();

  return (
    <Box>
      <Box pt="94px" pb="64px">
        <Title order={4} mb={6}>
          Account Data
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Welcome to Flow, login with
        </Text>
      </Box>
      <form
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
      >
        <Text size="lg" mb="md">
          Your Address
        </Text>
        <TextInput
          mb="sm"
          size="lg"
          label="Address 1"
          value={primaryAddress}
          {...register('primaryAddress')}
          error={formState.errors?.primaryAddress?.message}
        />
        <TextInput
          mb="sm"
          size="lg"
          label="Address 2"
          value={secondaryAddress}
          {...register('secondaryAddress')}
          error={formState.errors?.secondaryAddress?.message}
        />
        <TextInput
          mb="sm"
          size="lg"
          label="City"
          value={city}
          {...register('city')}
          error={formState.errors?.city?.message}
        />
        <TextInput
          mb="sm"
          size="lg"
          label="State"
          value={state}
          {...register('state')}
          error={formState.errors?.state?.message}
        />
        <TextInput
          mb="40px"
          size="lg"
          label="Zip Code"
          value={zipCode}
          {...register('zipCode')}
          error={formState.errors?.zipCode?.message}
        />
        <PhoneInput formState={formState} control={control} />
        <Button
          size="lg"
          fullWidth
          type="submit"
          variant="filled"
          disabled={!formState.isValid || isLoading}
        >
          Done
        </Button>
      </form>
    </Box>
  );
};
