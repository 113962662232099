import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';

export enum AssetType {
  REAL_ESTATE = 'REAL_ESTATE',
  INVESTMENT = 'INVESTMENT',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CRYPTO = 'CRYPTO',
}

export enum AssetTypeNames {
  REAL_ESTATE = 'Real estate',
  INVESTMENT = 'Investment',
  BANK_ACCOUNT = 'Bank account',
  CRYPTO = 'Crypto',
}

export enum AssetTypeLower {
  realEstates = AssetType['REAL_ESTATE'],
  investments = AssetType['INVESTMENT'],
  bankAccount = AssetType['BANK_ACCOUNT'],
  crypto = AssetType['CRYPTO'],
}

type RealEstateAddress = {
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  postalCode: string;
};

type BankAccounts = {
  plaidName: string;
  assetType: AssetType;
  plaidCurrentBalance: string;
  createdAt: string;
  plaidAccountId: string;
  plaidOfficialName: string;
  plaidCurrencyCode: string;
  id: string;
  customerId: string;
};

type Investments = {
  purchaseDate: string;
  currentValue: number;
  purchaseValue: number;
  assetType: string;
  createdAt: string;
  id: string;
};

type RealEstates = {
  purchaseDate: string;
  currentValue: number;
  purchaseValue: number;
  assetType: AssetType;
  createdAt: string;
  address: RealEstateAddress;
  id: string;
};

export type AssetsData = {
  bankAccounts: BankAccounts[];
  realEstates: RealEstates[];
  crypto: any;
  investments: Investments[];
}[];

type AssetsResponse = {
  data: AssetsData;
};

export type AssetData = {
  id: string;
  assetType: AssetType;
  plaidAccountId: string;
  plaidCurrentBalance: number;
  plaidCurrencyCode: string;
  plaidName: string;
  plaidOfficialName: string;
  institutionName: string;
  name: string;
  currentValue: number;
  purchaseValue: number;
  balanceHistory: any;
  transactions: any;
};

type AssetResponse = {
  data: AssetData;
};

export type AssetsPayload = Pick<RealEstates, 'purchaseValue' | 'currentValue'> & {
  name: string;
  assetType: AssetTypeLower;
};

export type AssetPayload = Pick<RealEstates, 'purchaseValue' | 'currentValue'> & {
  name: string;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchAssets() {
  const { data } = await httpService.get<AssetsResponse>('/assets');
  return data.data;
}

export function useGetAssets(
  options?: UseQueryOptions<
    AssetsData,
    Error,
    AssetsData,
    [REACT_QUERY_CACHE_KEYS_PROPS['assets']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<AssetsData, Error, AssetsData, [REACT_QUERY_CACHE_KEYS_PROPS['assets']]>(
    [REACT_QUERY_CACHE_KEYS.assets],
    fetchAssets,
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export async function mutateAssets(assetsPayload: AssetsPayload) {
  const { data } = await httpService.post<AssetsPayload>('/assets', assetsPayload);
  return data;
}

export async function fetchAsset(assetId: string | undefined) {
  const { data } = await httpService.get<AssetResponse>(`/assets/${assetId}`);
  return data.data;
}

export function useGetAsset(
  assetId: string | undefined,
  options?: UseQueryOptions<
    AssetData,
    Error,
    AssetData,
    [REACT_QUERY_CACHE_KEYS_PROPS['asset'], string | undefined]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    AssetData,
    Error,
    AssetData,
    [REACT_QUERY_CACHE_KEYS_PROPS['asset'], string | undefined]
  >([REACT_QUERY_CACHE_KEYS.asset, assetId], () => fetchAsset(assetId), {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function disconnectAsset(assetId: string | undefined) {
  const { data } = await httpService.remove<AssetResponse>(`/assets/${assetId}`);
  return data.data;
}

export async function updateAsset(assetId: string | undefined, assetsPayload: AssetPayload) {
  const { data } = await httpService.patch<AssetResponse>(`/assets/${assetId}`, assetsPayload);
  return data;
}
