import { Box, Button, Text, useMantineTheme } from '@mantine/core';

interface AccountItemProps {
  title: string;
  text: string;
  ctaText: string;
  loading?: boolean;
  disabled?: boolean;
  handleCtaClick?: () => void;
}

export const AccountItem = ({
  title,
  text,
  ctaText,
  handleCtaClick,
  loading = false,
  disabled = false,
}: AccountItemProps) => {
  const theme = useMantineTheme();
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.colors.dark[6]}`,
        background: theme.colors.dark[8],
        borderRadius: '10px',
        padding: '25px',
        marginBottom: '16px',
      })}
    >
      <Text size="xl" fw={700} c={theme.white} mb="md">
        {title}
      </Text>
      <Text c={theme.colors.dark[1]} mb="md">
        {text}
      </Text>
      <Button onClick={handleCtaClick} loading={loading} disabled={disabled}>
        {ctaText}
      </Button>
    </Box>
  );
};
