import { useMantineTheme } from '@mantine/styles';
import { ChevronIcon } from '../../../assets/icons/chevron';
import { useHeaderBackAndCtaStyles } from './hooks/use-styles';

interface HeaderBackAndCtaProps {
  handleBackClick: () => void;
  backText?: string;
  ctaText?: string;
  handleCtaClick?: () => void;
}

export const HeaderBackAndCta = ({
  handleBackClick,
  backText,
  ctaText,
  handleCtaClick,
}: HeaderBackAndCtaProps) => {
  const { classes } = useHeaderBackAndCtaStyles();
  const theme = useMantineTheme();
  return (
    <>
      <div className={classes.headerWrapper}>
        <span className={classes.headerBack} onClick={handleBackClick}>
          <ChevronIcon color={theme.colors.dark[0]} />
          {backText ? <span className={classes.headerBackText}>{backText}</span> : null}
        </span>
        {ctaText ? (
          <span className={classes.headerCta} onClick={handleCtaClick}>
            {ctaText}
          </span>
        ) : null}
      </div>
      <div className={classes.headerClear}></div>
    </>
  );
};
