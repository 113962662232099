export const BellIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33333 10.3333V11C4.33333 11.5304 4.54405 12.0391 4.91912 12.4142C5.29419 12.7893 5.8029 13 6.33333 13C6.86377 13 7.37247 12.7893 7.74755 12.4142C8.12262 12.0391 8.33333 11.5304 8.33333 11V10.3333M5 2.33333C5 1.97971 5.14048 1.64057 5.39052 1.39052C5.64057 1.14048 5.97971 1 6.33333 1C6.68696 1 7.02609 1.14048 7.27614 1.39052C7.52619 1.64057 7.66667 1.97971 7.66667 2.33333C8.43227 2.69535 9.08493 3.25888 9.5547 3.96353C10.0245 4.66818 10.2936 5.48738 10.3333 6.33333V8.33333C10.3835 8.7478 10.5303 9.14471 10.7619 9.49208C10.9935 9.83946 11.3034 10.1276 11.6667 10.3333H1C1.36329 10.1276 1.67321 9.83946 1.90479 9.49208C2.13638 9.14471 2.28316 8.7478 2.33333 8.33333V6.33333C2.37304 5.48738 2.6422 4.66818 3.11197 3.96353C3.58173 3.25888 4.23439 2.69535 5 2.33333Z"
        stroke="#72729B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
