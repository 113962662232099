import { createStyles } from '@mantine/core';

export const useNotificationListStyles = createStyles((theme) => ({
  listItemWrapper: {
    overflow: 'hidden',
  },
  listItem: {
    padding: '16px 24px',
    position: 'relative',
    margin: '0 -24px',
    justifyContent: 'space-between',
    '&::after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '0',
      height: '1px',
      width: '100%',
      backgroundColor: theme.colors.dark[7],
    },
  },
  icon: {
    '& > svg': {
      width: '18px',
      height: '20px',
    },
  },
}));
