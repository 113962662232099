import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';
import { Response } from '../../../shared-types';

type LinkTokenData = {
  linkToken: string;
};
const fetchLinkToken = async () => {
  const { data } = await httpService.get<Response<LinkTokenData>>('/plaid/get-link-token');

  return data.data;
};

export const useGetPlaidLinkToken = (
  options?: UseQueryOptions<
    LinkTokenData,
    unknown,
    LinkTokenData,
    REACT_QUERY_CACHE_KEYS_PROPS['linkToken']
  >,
) => {
  const { isAuthenticated } = useAuthContext();

  return useQuery(REACT_QUERY_CACHE_KEYS.linkToken, fetchLinkToken, {
    enabled: isAuthenticated,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

type ExchangeTokenPayload = {
  publicToken: string;
};
const exchangeTokens = async (payload: ExchangeTokenPayload) => {
  const response = await httpService.post<unknown, ExchangeTokenPayload>(
    '/plaid/exchange-tokens',
    payload,
  );

  return response;
};

export const useExchangePlaidToken = (
  options?: UseMutationOptions<unknown, unknown, ExchangeTokenPayload>,
) => {
  return useMutation(REACT_QUERY_CACHE_KEYS.exchangeTokens, exchangeTokens, options);
};
