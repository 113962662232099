import { Route, RouteObject } from 'react-router-dom';
import {
  CreateAccountGuard,
  CustomerGuard,
  OnlyPublicGuard,
  QuestionnaireGuard,
} from './components';
import { PlaidLinkAccounts, QuestionnairePage, QuestionnaireStartPage } from './pages';
import { CustomerAccountsPage } from './pages/accounts';
import { CreateAccountFormPage } from './pages/accounts/create-account-form-page';
import { CreateAccountFormPageLiability } from './pages/accounts/create-account-form-page-liability';
import { CreateAccountPage } from './pages/accounts/create-account-page';
import { CreateAccountPageLiability } from './pages/accounts/create-account-page-liability';
import { EditAccountPageAsset } from './pages/accounts/edit-account-page-asset';
import { EditAccountPageLiability } from './pages/accounts/edit-acount-page-liability';
import { InfoAccountPage } from './pages/accounts/info-account-page';
import { InfoAccountPageLiability } from './pages/accounts/info-account-page-liability';
import { ConfirmEmailPage } from './pages/confirm-email';
import { CustomerProfileInfoEditPage, CustomerProfilePage } from './pages/customer-profile';
import { EditCustomerProfileAddressPage } from './pages/customer-profile/edit-address-page';
import { EditCustomerFinancialDataPage } from './pages/customer-profile/edit-financial-data-page';
import { CustomerDashboardPage } from './pages/dashboard';
import { CustomerEducationPage } from './pages/education';
import {
  CreateAccountAddressPage,
  CreateAccountPersonalInfoPage,
} from './pages/finish-account-creation';
import { CreateAccountFinancialDataPage } from './pages/finish-account-creation/financial-data-page';
import { ForgotPasswordPage } from './pages/forgot-password';
import { LoginPage } from './pages/login';
import { CustomerNotificationsPage } from './pages/notifications';
import { InfoNotificationPage } from './pages/notifications/info-notification-page';
import { ObjectiveCreatePage } from './pages/objective-create';
import { ObjectiveInfoPage } from './pages/objective-detail';
import { ObjectiveEditPage } from './pages/ojective-edit';
import { RegisterPage } from './pages/register';
import { ResetPasswordPage } from './pages/reset-password';
import { CustomerRewardsPage } from './pages/rewards';
import { InfoRewardPage } from './pages/rewards/info-reward-page';
import { ManageAddressRewardPage } from './pages/rewards/manage-address-reward-page';
import { RedeemRewardPage } from './pages/rewards/redeem-reward-page';
import { CustomerSettingsPage } from './pages/settings';
import { CustomerTasksPage } from './pages/tasks';
import { CustomerRoutes } from './routes';
import { SubscriptionPage } from './pages/subscription';

const CustomerDashboard = () => <h1>Welcome Customer Dashboard</h1>;

const routeConfig: RouteObject[] = [
  {
    path: CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO,
    element: (
      <CreateAccountGuard>
        <CreateAccountPersonalInfoPage />
      </CreateAccountGuard>
    ),
  },
  {
    path: CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS,
    element: (
      <CreateAccountGuard>
        <CreateAccountAddressPage />
      </CreateAccountGuard>
    ),
  },
  {
    path: CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA,
    element: (
      <CreateAccountGuard>
        <CreateAccountFinancialDataPage />
      </CreateAccountGuard>
    ),
  },
  {
    index: true,
    element: (
      <CustomerGuard>
        <CustomerDashboard />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.PROFILE,
    element: (
      <CustomerGuard>
        <CustomerProfilePage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.PROFILE_INFO_EDIT,
    element: (
      <CustomerGuard>
        <CustomerProfileInfoEditPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.PROFILE_ADDRESS_EDIT,
    element: (
      <CustomerGuard>
        <EditCustomerProfileAddressPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.PROFILE_FINANCIAL_DATA_EDIT,
    element: (
      <CustomerGuard>
        <EditCustomerFinancialDataPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.DASHBOARD,
    element: (
      <CustomerGuard>
        <CustomerDashboardPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.SETTINGS,
    element: (
      <CustomerGuard>
        <CustomerSettingsPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.NOTIFICATIONS,
    element: (
      <CustomerGuard>
        <CustomerNotificationsPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.NOTIFICATION_DETAIL}/:id`,
    element: (
      <CustomerGuard>
        <InfoNotificationPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.TASKS,
    element: (
      <CustomerGuard>
        <CustomerTasksPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.ACCOUNTS,
    element: (
      <CustomerGuard>
        <CustomerAccountsPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.ACCOUNTS_INFO_ASSET}/:id`,
    element: (
      <CustomerGuard>
        <InfoAccountPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.ACCOUNTS_INFO_LIABILITY}/:id`,
    element: (
      <CustomerGuard>
        <InfoAccountPageLiability />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.ACCOUNTS_EDIT_ASSET}/:id`,
    element: (
      <CustomerGuard>
        <EditAccountPageAsset />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.ACCOUNTS_EDIT_LIABILITY}/:id`,
    element: (
      <CustomerGuard>
        <EditAccountPageLiability />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.ACCOUNTS_CREATE,
    element: (
      <CustomerGuard>
        <CreateAccountPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.ACCOUNTS_CREATE_LIABILITY,
    element: (
      <CustomerGuard>
        <CreateAccountPageLiability />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.ACCOUNTS_CREATE_LIABILITY_FORM}/:liabilityType`,
    element: (
      <CustomerGuard>
        <CreateAccountFormPageLiability />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.ACCOUNTS_CREATE_FORM,
    element: (
      <CustomerGuard>
        <CreateAccountFormPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.EDUCATION,
    element: (
      <CustomerGuard>
        <CustomerEducationPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.REWARDS,
    element: (
      <CustomerGuard>
        <CustomerRewardsPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.REWARDS_INFO}/:id`,
    element: (
      <CustomerGuard>
        <InfoRewardPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.REWARDS_REDEEM}/:id`,
    element: (
      <CustomerGuard>
        <RedeemRewardPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.REWARDS_MANAGE_ADDRESS}/:id`,
    element: (
      <CustomerGuard>
        <ManageAddressRewardPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.QUESTIONNAIRE_START,
    element: (
      <QuestionnaireGuard>
        <QuestionnaireStartPage />
      </QuestionnaireGuard>
    ),
  },
  {
    path: CustomerRoutes.QUESTIONNAIRE,
    element: (
      <QuestionnaireGuard>
        <QuestionnairePage />
      </QuestionnaireGuard>
    ),
  },
  {
    path: CustomerRoutes.SUBSCRIPTION,
    element: (
      <CustomerGuard>
        <SubscriptionPage />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.PLAID_LINK_ACCOUNTS,
    element: (
      <CustomerGuard>
        <PlaidLinkAccounts />
      </CustomerGuard>
    ),
  },
  {
    path: CustomerRoutes.OBJECTIVE_CREATE,
    element: (
      <CustomerGuard>
        <ObjectiveCreatePage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.OBJECTIVE_DETAIL}/:id`,
    element: (
      <CustomerGuard>
        <ObjectiveInfoPage />
      </CustomerGuard>
    ),
  },
  {
    path: `${CustomerRoutes.OBJECTIVE_EDIT}/:id`,
    element: (
      <CustomerGuard>
        <ObjectiveEditPage />
      </CustomerGuard>
    ),
  },
];

const publicRouteConfig: RouteObject[] = [
  {
    path: CustomerRoutes.LOGIN,
    element: (
      <OnlyPublicGuard>
        <LoginPage />
      </OnlyPublicGuard>
    ),
  },
  {
    path: CustomerRoutes.REGISTER,
    element: (
      <OnlyPublicGuard>
        <RegisterPage />
      </OnlyPublicGuard>
    ),
  },
  {
    path: CustomerRoutes.CONFIRM_EMAIL,
    element: (
      <OnlyPublicGuard>
        <ConfirmEmailPage />
      </OnlyPublicGuard>
    ),
  },
  {
    path: CustomerRoutes.FORGOT_PASSWORD,
    element: (
      <OnlyPublicGuard>
        <ForgotPasswordPage />
      </OnlyPublicGuard>
    ),
  },
  {
    path: CustomerRoutes.RESET_PASSWORD,
    element: (
      <OnlyPublicGuard>
        <ResetPasswordPage />
      </OnlyPublicGuard>
    ),
  },
];

export const customerRoutes = routeConfig.map((route, index) => {
  // eslint-disable-next-line
  // @ts-ignore
  return <Route {...route} key={index} />;
});

export const publicRoutes = publicRouteConfig.map((route, index) => {
  // eslint-disable-next-line
  // @ts-ignore
  return <Route {...route} key={index} />;
});
