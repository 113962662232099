import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        financialData: {
          relationshipStatus: {
            single: 'Single',
            married: 'Married',
            divorced: 'Divorced',
          },
          frequency: {
            MONTHLY: 'Monthly',
            WEEKLY: 'Weekly',
            BIWEEKLY: 'Biweekly',
          },
        },
      },
    },
  },
  lng: 'en',
});
