import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Flex,
  Loader,
  Select,
  SelectItem,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useObjectiveCreateStyles } from '../../hooks/use-styles';
import { StepperHeader } from '../stepper-header';

import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PlaidLinkError, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { REACT_QUERY_CACHE_KEYS } from '../../../../../constants/react-query-cache-keys';
import { CreateObjective } from '../../../../../validation-schemas/objectives';
import { CustomerRoutes } from '../../../../routes';
import { useGetUnassignedBankAccounts } from '../../../../service/bank-accounts';
import { ObjectivePayload, mutateObjective } from '../../../../service/objectives';
import { useExchangePlaidToken, useGetPlaidLinkToken } from '../../../../service/plaid';

interface CreateObjectiveFormFields {
  objectiveTitle: string;
  objectiveAmount: number;
  bankAccountId: string;
}

interface CreateObjectiveThirdStepProps {
  categoryId: string;
  objectiveId: string;
  prevStep: () => void;
}

export const CreateObjectiveThirdStep = ({
  categoryId,
  objectiveId,
  prevStep,
}: CreateObjectiveThirdStepProps) => {
  const { classes: objectiveCreateClasses } = useObjectiveCreateStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpenPlaidValid, setIsOpenPlaidValid] = useState(false);

  const removeLinkTokenFromStorage = () => localStorage.removeItem('linkToken');
  const setLinkTokenInStorage = (token: string) => localStorage.setItem('linkToken', token);

  let unassignedBankAccounts = [] as SelectItem[];

  const { data: unassignedBankAccountsData, isLoading: isLoadingBankAccountsData } =
    useGetUnassignedBankAccounts();

  if (unassignedBankAccountsData) {
    unassignedBankAccounts = unassignedBankAccountsData.map((item) => {
      return { value: item.id, label: item.plaidName };
    });
  }

  const { formState, watch, register, handleSubmit, control } = useForm<CreateObjectiveFormFields>({
    mode: 'onChange',
    resolver: yupResolver(CreateObjective),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (objectivePayload: ObjectivePayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateObjective(objectivePayload),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.unassignedBankAccounts);
      notifications.show({
        message: 'Successfully created objective.',
      });
      setTimeout(() => {
        navigate(CustomerRoutes.DASHBOARD);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const {
    data,
    isLoading: isCreatingLinkToken,
    refetch: startPlaidWizard,
  } = useGetPlaidLinkToken({
    enabled: false,
    onSuccess: ({ linkToken }) => {
      setLinkTokenInStorage(linkToken);
    },
    onError: () => {
      notifications.show({
        message: 'Failed to initiate the connection with Plaid.',
      });
    },
  });

  const { mutate: exchangeTokens, isLoading: isExchangingTokens } = useExchangePlaidToken({
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.unassignedBankAccounts);
      navigate(CustomerRoutes.OBJECTIVE_CREATE);
      notifications.show({
        message: 'Successfully connected accounts.',
      });
    },
    onError: () => {
      notifications.show({
        message: 'Error finishing up account connection.',
      });
    },
  });

  const config: PlaidLinkOptions = {
    token: data?.linkToken || '',
    onSuccess: async (public_token: string) => exchangeTokens({ publicToken: public_token }),
    onExit: (error: PlaidLinkError | null) => {
      if (error !== null) {
        notifications.show({
          message: 'Unsuccessful connection of bank accounts. Please, try again',
        });
      }

      // todo :: should we do this only on error
      removeLinkTokenFromStorage();
    },
    ...{},
  };

  const { open, ready, exit, error: plaidError } = usePlaidLink(config);

  useEffect(() => {
    if (ready && isOpenPlaidValid) {
      open();
    }
  }, [open, ready, isOpenPlaidValid]);

  useEffect(() => {
    if (exit) {
      setIsOpenPlaidValid(false);
    }
  }, [exit]);

  const { objectiveTitle, objectiveAmount, bankAccountId } = watch();
  return (
    <>
      <StepperHeader prevStep={prevStep} />
      <div className={objectiveCreateClasses.objectivesVerticalSpaceContainer}>
        <Box>
          <Text ta="center" fz="lg" mb="sm" color={theme.colors.dark[1]}>
            Step 3 of 3
          </Text>
          <Title ta="center" order={4} mb="xl">
            Set Your Objective
          </Title>
        </Box>
        <Box>
          <form
            onSubmit={handleSubmit(({ objectiveTitle, objectiveAmount, bankAccountId }) => {
              mutate({
                bankAccountId: bankAccountId,
                categoryId: categoryId,
                objectiveId: objectiveId,
                targetAmount: objectiveAmount,
                title: objectiveTitle,
              });
            })}
          >
            <TextInput
              mb="sm"
              size="lg"
              label="Objective title"
              value={objectiveTitle}
              placeholder="Name the objective..."
              {...register('objectiveTitle')}
              error={formState.errors?.objectiveTitle?.message}
            />
            <TextInput
              mb="sm"
              size="lg"
              label="Objective amount"
              value={objectiveAmount}
              placeholder="$ 00,00"
              {...register('objectiveAmount')}
              error={formState.errors?.objectiveAmount?.message}
            />
            <Title order={5} mb="xl" mt={56}>
              Associated Account
            </Title>
            <Text c={theme.colors.dark[1]} mb="md">
              Each objective requires a dedicated bank account associated with it. If you don’t have
              available accounts contact your bank in order to open it and then start the process of
              creating a new objective.
            </Text>
            {isLoadingBankAccountsData ? (
              <Flex justify="center" mb="xl">
                <Loader />
              </Flex>
            ) : unassignedBankAccounts.length > 0 ? (
              <Controller
                render={({ field }) => (
                  <Select
                    size="lg"
                    mb="xl"
                    label="Associated Account"
                    placeholder="Select an account"
                    data={unassignedBankAccounts}
                    {...field}
                    value={bankAccountId}
                    error={formState.errors?.bankAccountId?.message}
                  />
                )}
                control={control}
                name="bankAccountId"
              />
            ) : null}
            {isExchangingTokens && (
              <Text fz="md" c={theme.colors.dark[1]} mb="xl">
                Finishing up Plaid connection...
              </Text>
            )}
            {isCreatingLinkToken && (
              <Text fz="md" c={theme.colors.dark[1]} mb="xl">
                Opening Plaid...
              </Text>
            )}
            {plaidError && (
              <Text color="red" fz="md">
                Error connecting to Plaid. Please try again.
              </Text>
            )}
            <Button
              variant="outline"
              size="lg"
              pl={32}
              pr={32}
              onClick={() => {
                setIsOpenPlaidValid(true);
                startPlaidWizard();
              }}
            >
              Connect Account
            </Button>
            <Button
              size="lg"
              fullWidth
              type="submit"
              variant="filled"
              disabled={!formState.isValid || isLoading}
              mt={56}
            >
              Create
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
};
