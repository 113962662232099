import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { AssetTypeLower } from '../customer-app/service/assets';

type AppStoreState = {
  assetType: keyof typeof AssetTypeLower | string;
  activeTab: string;
  redeemSecondaryAddress: string;
  setAssetType: (assetType: keyof typeof AssetTypeLower) => void;
  setAccountActiveTab: (activeTab: 'assets' | 'liabilities') => void;
  setRedeemSecondaryAddress: (address: string) => void;
  clearAssetType: () => void;
  clearRedeemSecondaryAddress: () => void;
};

type MyPersist = (
  config: StateCreator<AppStoreState>,
  options: PersistOptions<AppStoreState>,
) => StateCreator<AppStoreState>;

const initialState = {
  assetType: '',
  activeTab: 'assets',
  redeemSecondaryAddress: '',
};

export const useAppStore = create<AppStoreState>(
  (persist as MyPersist)(
    (set) => ({
      ...initialState,
      setAssetType: (assetType) => set(() => ({ assetType: assetType })),
      setAccountActiveTab: (activeTab) => set(() => ({ activeTab: activeTab })),
      setRedeemSecondaryAddress: (redeemSecondaryAddress) =>
        set(() => ({ redeemSecondaryAddress: redeemSecondaryAddress })),
      clearAssetType: () => set(() => ({ assetType: initialState.assetType })),
      clearRedeemSecondaryAddress: () => set(() => ({ redeemSecondaryAddress: '' })),
    }),
    {
      name: 'AppStorage',
    },
  ),
);
