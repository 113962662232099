import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';
import { AssetType } from '../assets';

export type UnassignedBankAccountsData = {
  plaidName: string;
  institutionName: string;
  assetType: AssetType;
  plaidCurrentBalance: 100;
  transactions: [];
  plaidOfficialName: string;
  balanceHistory: [];
  plaidCurrencyCode: string;
  id: string;
  customerId: string;
}[];

type UnassignedBankAccountsResponse = {
  data: UnassignedBankAccountsData;
};

export type UnassignedBankAccountsPayload = {
  [key: string]: string;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchUnassignedBankAccounts() {
  const { data } = await httpService.get<UnassignedBankAccountsResponse>(
    '/unassigned-bank-accounts',
  );
  return data.data;
}

export function useGetUnassignedBankAccounts(
  options?: UseQueryOptions<
    UnassignedBankAccountsData,
    Error,
    UnassignedBankAccountsData,
    [REACT_QUERY_CACHE_KEYS_PROPS['unassignedBankAccounts']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    UnassignedBankAccountsData,
    Error,
    UnassignedBankAccountsData,
    [REACT_QUERY_CACHE_KEYS_PROPS['unassignedBankAccounts']]
  >([REACT_QUERY_CACHE_KEYS.unassignedBankAccounts], fetchUnassignedBankAccounts, {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}
