import { Box, NavLink, Text } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { NavLinkChevronIcon } from '../../../assets/icons/nav-link-chevron';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';

export const CustomerSettingsPage = () => {
  const navigate = useNavigate();
  const onBackClick = () => navigate(CustomerRoutes.DASHBOARD);
  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Dashboard"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />
      <Box pt={32}>
        <Text mb="sm" size="lg" fw={600} c="#94A3B8">
          User Settings
        </Text>
        <Link to={CustomerRoutes.PROFILE_INFO_EDIT} style={{ textDecoration: 'none' }}>
          <NavLink label="Personal Data" rightSection={<NavLinkChevronIcon />} />
        </Link>
        <Link to={CustomerRoutes.PROFILE_ADDRESS_EDIT} style={{ textDecoration: 'none' }}>
          <NavLink label="User Address" rightSection={<NavLinkChevronIcon />} />
        </Link>
        <Link to={CustomerRoutes.PROFILE_FINANCIAL_DATA_EDIT} style={{ textDecoration: 'none' }}>
          <NavLink label="Financial data" rightSection={<NavLinkChevronIcon />} />
        </Link>
        <Text mt="xl" mb="sm" size="lg" fw={600} c="#94A3B8">
          Application Settings
        </Text>
        <Link to={CustomerRoutes.NOTIFICATIONS} style={{ textDecoration: 'none' }}>
          <NavLink label="Notifications" rightSection={<NavLinkChevronIcon />} />
        </Link>
        <Text mt="xl" mb="sm" size="lg" fw={600} c="#94A3B8">
          Support
        </Text>

        {/* <Link to={CustomerRoutes.DASHBOARD} style={{ textDecoration: 'none' }}>
          <NavLink label="Privacy Policy" rightSection={<NavLinkChevronIcon />} />
        </Link> */}
        <Link
          to="https://techflowventures.com/terms-and-conditions"
          style={{ textDecoration: 'none' }}
          target="_blank"
        >
          <NavLink label="Terms & Conditions" rightSection={<NavLinkChevronIcon />} />
        </Link>
      </Box>
    </>
  );
};
