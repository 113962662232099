export const AccountsIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.7253 0.5L0 4.20692V6.13546H16V4.19669L7.7253 0.5ZM14.9333 5.07243H1.06667V4.8749L7.74137 1.67212L14.9333 4.88513V5.07243ZM0 16.5H16V13.0452H0V16.5ZM1.06667 14.1082H14.9333V15.437H1.06667V14.1082ZM1.86667 6.93273H2.93333V12.2479H1.86667V6.93273ZM13.0667 6.93273H14.1333V12.2479H13.0667V6.93273ZM5.6 6.93273H6.66667V12.2479H5.6V6.93273ZM9.33333 6.93273H10.4V12.2479H9.33333V6.93273Z"
        fill="#72729B"
      />
    </svg>
  );
};
