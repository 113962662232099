import { Button, Center, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

export const CustomerEducationPage = () => {
  return (
    <Center h="100%" sx={{ flexDirection: 'column' }}>
      <Text mb={30} fw={600}>
        In Progress
      </Text>
      <Button variant="filled" component={Link} to="/customer/dashboard">
        Back to Home
      </Button>
    </Center>
  );
};
