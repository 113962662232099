import { createStyles } from '@mantine/core';

export const useUserProfileInfoStyles = createStyles((theme) => ({
  userProfileInfoWrapper: {
    padding: '31px 24px',
    margin: '0 -24px',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      background: 'linear-gradient(0.74deg, rgba(12, 77, 69, 0) 50.04%, #179989 167.18%)',
      top: '0',
      left: `calc(-50vw + 50% - 9px)`,
      width: '100vw',
      height: '100%',
    },
  },
  userProfileInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
  },
  profileBadge: {
    backgroundColor: theme.colors.dark[7],
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: theme.white,
    padding: '5px 16px 5px 12px',
    height: 'auto',
  },
  profileBadgeLeftSection: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    marginLeft: '12px',
  },
}));
