import { useQuery, UseQueryOptions } from 'react-query';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';
import { Response } from '../../../shared-types';

export enum LiabilityType {
  MORTGAGE = 'MORTGAGE',
  AUTO_LOAN = 'AUTO_LOAN',
  CREDIT_CARD = 'CREDIT_CARD',
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  STUDENT_LOAN = 'STUDENT_LOAN',
}

export enum LiabilityTypeNames {
  MORTGAGE = 'Mortgage',
  AUTO_LOAN = 'Auto Loan',
  CREDIT_CARD = 'Credit Card',
  PERSONAL_LOAN = 'Personal Loan',
  STUDENT_LOAN = 'Student Loan',
}

export enum LiabilityTypeLower {
  Mortgage = LiabilityType['MORTGAGE'],
  Auto_Loan = LiabilityType['AUTO_LOAN'],
  Credit_Card = LiabilityType['CREDIT_CARD'],
  Personal_Loan = LiabilityType['PERSONAL_LOAN'],
  Student_Loan = LiabilityType['STUDENT_LOAN'],
}

// type RealEstateAddress = {
//   country: string;
//   state: string;
//   city: string;
//   streetAddress: string;
//   postalCode: string;
// };

// type BankAccounts = {
//   plaidName: string;
//   assetType: AssetType;
//   plaidCurrentBalance: string;
//   createdAt: string;
//   plaidAccountId: string;
//   plaidOfficialName: string;
//   plaidCurrencyCode: string;
//   id: string;
//   customerId: string;
// };

// type Investments = {
//   purchaseDate: string;
//   currentValue: number;
//   purchaseValue: number;
//   assetType: string;
//   createdAt: string;
//   id: string;
// };

// type RealEstates = {
//   purchaseDate: string;
//   currentValue: number;
//   purchaseValue: number;
//   assetType: AssetType;
//   createdAt: string;
//   address: RealEstateAddress;
//   id: string;
// };

export type LiabilitiesData = LiabilityData[];

type LiabilitiesResponse = {
  data: LiabilitiesData;
};

export type LiabilityData = {
  interestRate: number;
  monthlyDueDate: number;
  createdAt: number;
  name: string;
  liabilityType: LiabilityType;
  amount: number;
  id: string;
  customerId: string;
};

type LiabilityResponse = {
  data: LiabilityData;
};

// export type AssetsPayload = Pick<RealEstates, 'purchaseValue' | 'currentValue'> & {
//   name: string;
//   assetType: AssetTypeLower;
// };

export type LiabilityPayload = {
  name: string;
  amount: number;
  monthlyDueDate: number;
  interestRate: number;
  liabilityType: string;
};

// // Replace 'unknown' with the type of your error
// type Error = unknown;

export async function fetchLiabilities() {
  const { data } = await httpService.get<LiabilitiesResponse>('/liabilities');
  return data.data;
}

export function useGetLiabilities(
  options?: UseQueryOptions<LiabilitiesData, Error, LiabilitiesData, ['liabilities']>,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<LiabilitiesData, Error, LiabilitiesData, ['liabilities']>(
    ['liabilities'],
    fetchLiabilities,
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export async function mutateLiability(liabilityPayload: LiabilityPayload) {
  const { data } = await httpService.post<LiabilityPayload>('/liabilities', liabilityPayload);
  return data;
}

export async function fetchLiability(liabilityId: string | undefined) {
  const { data } = await httpService.get<LiabilityResponse>(`/liabilities/${liabilityId}`);
  return data.data;
}

export function useGetLiability(
  liabilityId: string | undefined,
  options?: UseQueryOptions<LiabilityData, Error, LiabilityData, ['liability', string | undefined]>,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<LiabilityData, Error, LiabilityData, ['liability', string | undefined]>(
    ['liability', liabilityId],
    () => fetchLiability(liabilityId),
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export async function removeLiability(liabilityId: string | undefined) {
  const { data } = await httpService.remove<Response<string>>(`/liabilities/${liabilityId}`);
  return data.data;
}

export async function updateLiability(
  liabilityId: string | undefined,
  liabilityPayload: Omit<LiabilityPayload, 'liabilityType'>,
) {
  const { data } = await httpService.patch<LiabilityPayload>(
    `/liabilities/${liabilityId}`,
    liabilityPayload,
  );
  return data;
}
