import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Input, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { CustomerRoutes } from '../../routes';
import { formatBirthDate } from '../../utils';
import useStyles from './use-styles';

interface Fields {
  firstName: string;
  lastName: string;
}

const FormSection = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  return (
    <Box pb="40px">
      <Text size="lg" mb="md">
        {title}
      </Text>
      {children}
    </Box>
  );
};

const EditDateOfBirth = ({
  birthDateDay,
  birthDateMonth,
  birthDateYear,
  setBirthDateDay,
  setBirthDateMonth,
  setBirthDateYear,
}: {
  birthDateDay: string;
  birthDateMonth: string;
  birthDateYear: string;
  setBirthDateDay: React.Dispatch<React.SetStateAction<string>>;
  setBirthDateMonth: React.Dispatch<React.SetStateAction<string>>;
  setBirthDateYear: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleDay = useCallback(
    (value: string | ChangeEvent<HTMLInputElement>) => {
      const inputValue = typeof value === 'string' ? value : value.target.value;
      if (inputValue === '') {
        setBirthDateDay(inputValue);
      } else {
        switch (birthDateMonth) {
          case '1':
          case '3':
          case '5':
          case '7':
          case '8':
          case '10':
          case '12':
            if (parseInt(inputValue) <= 31) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('31');
            }

            break;
          case '4':
          case '6':
          case '9':
          case '11':
            if (parseInt(inputValue) <= 30) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('30');
            }
            break;
          case '2':
            if (parseInt(inputValue) <= 28) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('28');
            }
            break;
          default:
            setBirthDateDay(inputValue);
            break;
        }
      }
    },
    [birthDateMonth, setBirthDateDay],
  );

  useEffect(() => {
    handleDay(birthDateDay);
  }, [birthDateMonth, birthDateDay, handleDay]);

  const handleMonth = (event: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) <= 12 || event.target.value === '') {
      setBirthDateMonth(event.target.value);
    } else {
      setBirthDateMonth('12');
    }
  };

  const handleYear = (event: ChangeEvent<HTMLInputElement>) => {
    setBirthDateYear(event.target.value);
  };

  return (
    <>
      {/* <DatePickerInput
        mx="auto"
        size="lg"
        maw={400}
        value={birthDate}
        label="Date of birth"
        icon={<CalendarIcon />}
        placeholder="Pick a date"
        onChange={(date) => setBirthDate(date)}
        classNames={{ label: classes.inputLabel }}
      /> */}
      <Flex justify="space-between" mx="-10px">
        <Box style={{ flex: 1 }} mx="10px">
          <Input placeholder="Day" value={birthDateDay} onChange={handleDay} inputMode="numeric" />
        </Box>
        <Box style={{ flex: 1 }} mx="10px">
          <Input
            placeholder="Month"
            value={birthDateMonth}
            onChange={handleMonth}
            inputMode="numeric"
          />
        </Box>
        <Box style={{ flex: 1 }} mx="10px">
          <Input
            placeholder="Year"
            value={birthDateYear}
            onChange={handleYear}
            inputMode="numeric"
          />
        </Box>
      </Flex>
    </>
  );
};

export const CreateAccountPersonalInfoPage = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { updateUserAttributes, user } = useAuthContext();

  const [birthDateDay, setBirthDateDay] = useState<string>(
    user?.attributes?.birthdate ? new Date(user?.attributes?.birthdate).getDate().toString() : '',
  );

  const [birthDateMonth, setBirthDateMonth] = useState<string>(
    user?.attributes?.birthdate
      ? (new Date(user?.attributes?.birthdate).getMonth() + 1).toString()
      : '',
  );

  const [birthDateYear, setBirthDateYear] = useState<string>(
    user?.attributes?.birthdate
      ? new Date(user?.attributes?.birthdate).getFullYear().toString()
      : '',
  );

  const { formState, watch, register, handleSubmit } = useForm<Fields>({
    mode: 'onChange',
    defaultValues: {
      firstName: user?.attributes?.given_name || '',
      lastName: user?.attributes?.family_name || '',
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required('Required field.'),
        lastName: yup.string().required('Required field.'),
      }),
    ),
  });

  const { mutate, isLoading } = useMutation<void, Exception, Fields & { birthDate: Date }>({
    mutationFn: ({ firstName, lastName, birthDate }) =>
      updateUserAttributes({
        given_name: firstName,
        family_name: lastName,
        birthdate: formatBirthDate(birthDate),
        'custom:accountDone': '1',
      }),
    onSuccess: () => {
      navigate(CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { firstName, lastName } = watch();
  const isFormValid = useMemo(
    () =>
      formState.isValid &&
      Boolean(birthDateDay) &&
      Boolean(birthDateMonth) &&
      Boolean(birthDateYear),
    [formState, birthDateDay, birthDateMonth, birthDateYear],
  );

  return (
    <Box>
      <Box py="94px">
        <Title order={4} mb={6}>
          Account setup
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Welcome to Flow, login with
        </Text>
      </Box>

      <form
        onSubmit={handleSubmit(({ firstName, lastName }) => {
          const birthDate = new Date(
            parseInt(birthDateYear as string),
            parseInt(birthDateMonth as string) - 1,
            parseInt(birthDateDay as string),
          );
          mutate({ firstName, lastName, birthDate: birthDate as Date });
        })}
      >
        <FormSection title="Your Legal Name">
          <TextInput
            mb="sm"
            size="lg"
            label="First Name"
            value={firstName}
            {...register('firstName')}
            error={formState.errors?.firstName?.message}
            classNames={{ label: classes.inputLabel }}
          />
          <TextInput
            mb="md"
            size="lg"
            label="Last Name"
            value={lastName}
            {...register('lastName')}
            error={formState.errors?.lastName?.message}
            classNames={{ label: classes.inputLabel }}
          />
        </FormSection>
        <FormSection title="Your Date of Birth">
          {/* <DatePickerInput
            mx="auto"
            size="lg"
            maw={400}
            value={birthDate}
            label="Date of birth"
            icon={<CalendarIcon />}
            placeholder="Pick a date"
            onChange={(date) => setBirthDate(date)}
            classNames={{ label: classes.inputLabel }}
          /> */}
          <EditDateOfBirth
            birthDateDay={birthDateDay}
            setBirthDateDay={setBirthDateDay}
            birthDateMonth={birthDateMonth}
            setBirthDateMonth={setBirthDateMonth}
            birthDateYear={birthDateYear}
            setBirthDateYear={setBirthDateYear}
          />
        </FormSection>
        <Button
          size="lg"
          fullWidth
          type="submit"
          variant="filled"
          loading={isLoading}
          disabled={!isFormValid || isLoading}
        >
          Done
        </Button>
      </form>
    </Box>
  );
};
