import { Flex, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { useGetObjectivesByCategory } from '../../../../service/objectives';
import { useObjectiveCreateStyles } from '../../hooks/use-styles';
import { StepperHeader } from '../stepper-header';

interface CreateObjectiveSecondStepProps {
  categoryId: string;
  handleObjectiveClick: (objectiveId: string) => void;
  prevStep: () => void;
}

export const CreateObjectiveSecondStep = ({
  categoryId,
  handleObjectiveClick,
  prevStep,
}: CreateObjectiveSecondStepProps) => {
  const { classes: objectiveCreateClasses } = useObjectiveCreateStyles();
  const theme = useMantineTheme();
  const { data, isLoading } = useGetObjectivesByCategory(categoryId);

  if (isLoading)
    return (
      <>
        <StepperHeader />
        <div className={objectiveCreateClasses.objectivesVerticalSpaceContainer}>
          <Flex justify="center">
            <Loader />
          </Flex>
          <div></div>
        </div>
      </>
    );

  return (
    <>
      <StepperHeader prevStep={prevStep} />
      <div className={objectiveCreateClasses.objectivesVerticalSpaceContainer}>
        <div>
          <Text ta="center" fz="lg" mb="sm" color={theme.colors.dark[1]}>
            Step 2 of 3
          </Text>
          <Title ta="center" order={4} mb="xl">
            Choose Your Objective
          </Title>
        </div>
        <div className={objectiveCreateClasses.categoryBoxWrapper}>
          {data?.map((objective, index) => (
            <div
              key={`subcategory${index}`}
              className={objectiveCreateClasses.categoryBox}
              onClick={() => handleObjectiveClick(objective.id)}
            >
              {objective.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
