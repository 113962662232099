import { Input } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

interface PhoneInputProps {
  formState: any;
  control: any;
}

export function PhoneInput({ formState, control }: PhoneInputProps) {
  return (
    <Input.Wrapper label="Phone number" error={formState.errors?.phoneNumber?.message}>
      <Controller
        render={({ field }) => (
          <Input<any> size="lg" component={IMaskInput} mask="(000) 000-0000" {...field} />
        )}
        control={control}
        name="phoneNumber"
      />
    </Input.Wrapper>
  );
}
