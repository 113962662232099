import { createStyles } from '@mantine/core';

export const useBackButtonStyles = createStyles((theme) => ({
  backButton: {
    color: theme.colors.dark[0],
    '& .mantine-Button-leftIcon': {
      marginRight: '15px',
      position: 'relative',
      top: '1px',
    },
  },
}));
