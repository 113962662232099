import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const emailValidation = yup.string().email('Invalid format.').required('Field is required.');
export const passwordValidation = yup
  .string()
  .required('Required field.')
  .min(8, 'Password must contain at least 8 characters.');

const authSchema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export interface FormFields {
  email: string;
  password: string;
}

export const useAuthenticationFields = (schema = authSchema) => {
  return useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
};
