import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_URL, // Replace with your backend API URL
  timeout: 5000, // Your desired timeout
});

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      if (config.headers) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
      if (config.params) {
        delete config?.headers?.Authorization;
      }
    } catch (e) {
      if (config.headers) {
        delete config.headers.Authorization;
      }
      console.error(e);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    // handle the response error
    return Promise.reject(error);
  },
);

export async function get<T = any>(endpoint: string, params?: object): Promise<AxiosResponse<T>> {
  return axiosInstance.get<T>(endpoint, { params });
}

export async function post<T = any, P = any>(
  endpoint: string,
  data?: P,
): Promise<AxiosResponse<T>> {
  return axiosInstance.post<T>(endpoint, data);
}

export async function put<T = any>(
  endpoint: string,
  data?: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> {
  return axiosInstance.put<T>(endpoint, data, config);
}

export async function patch<T = any>(endpoint: string, data?: object): Promise<AxiosResponse<T>> {
  return axiosInstance.patch<T>(endpoint, data);
}

export async function remove<T = any>(endpoint: string, data?: object): Promise<AxiosResponse<T>> {
  return axiosInstance.delete<T>(endpoint, data);
}

export async function putDefault<T = any>(
  endpoint: string,
  data?: object,
): Promise<AxiosResponse<T>> {
  return axios.put<T>(endpoint, data);
}
