export const EducationIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12.6598L7.99996 14.9098L3.99997 12.6598V9.28263L2.85712 8.64771V13.3282L7.99996 16.221L13.1428 13.3282V8.64771L12 9.28263V12.6598Z"
        fill="#72729B"
      />
      <path
        d="M7.99998 0.778931L0 4.92706V5.91756L7.99998 10.3619L14.8571 6.55249V9.7083H16V4.92706L7.99998 0.778931ZM13.7143 5.88003L12.5714 6.51492L7.99998 9.05473L3.42856 6.51492L2.28571 5.88003L1.4936 5.43996L7.99998 2.06629L14.5064 5.43996L13.7143 5.88003Z"
        fill="#72729B"
      />
    </svg>
  );
};
