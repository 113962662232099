import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { emailValidation } from '../../hooks/use-authentication-form';
import { useStyles } from '../../hooks/use-styles';

export const ForgotPasswordPage = () => {
  const { initiateForgotPassword } = useAuthContext();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { formState, watch, register, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(yup.object().shape({ email: emailValidation })),
  });

  const { mutate: mutateForgotPassword, isLoading } = useMutation<
    void,
    Exception,
    { email: string }
  >({
    mutationFn: ({ email }) => initiateForgotPassword(email),
    onError: (error) => {
      notifications.show({
        message: error?.message || 'Something went wrong. Please, try again.',
      });
    },
  });

  const fields = watch();

  return (
    <div className={classes.verticalSpaceContainer}>
      <div>
        <Title order={4} mb="xs">
          Forgot Password?
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Input your email address and we’ll send the password reset link.
        </Text>
      </div>
      <div>
        <form onSubmit={handleSubmit((data) => mutateForgotPassword({ email: data.email }))}>
          <TextInput
            mb="md"
            size="lg"
            type="email"
            label="Your Email"
            {...register('email')}
            value={fields.email}
            error={formState.errors?.email?.message}
          />

          <Button
            style={{ marginTop: '36px' }}
            size="lg"
            fullWidth
            type="submit"
            loading={isLoading}
            disabled={!formState.isValid || isLoading}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};
