import { Container } from '@mantine/core';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../providers';
import { Header } from './components';
import { BottomNavigation } from './components/bottom-navigation';
import { HiddenBottomNavigationRoutes } from './hiddenBottomNavigationRoutes';
import { HiddenHeaderRoutes } from './hiddenHeaderRoutes';
import { CustomerRoutes } from './routes';
import { UserProfileProvider } from './service/user/context';

const TITLES = {
  [CustomerRoutes.FINISH_ACCOUNT_CREATION_ADDRESS]: 'Account',
  [CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO]: 'Account',
  [CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA]: 'Account',
  [CustomerRoutes.PROFILE]: 'My Profile',
  [CustomerRoutes.DASHBOARD]: 'Dashboard',
  [CustomerRoutes.QUESTIONNAIRE]: 'Questionnaire',
  [CustomerRoutes.TASKS]: 'Tasks',
  [CustomerRoutes.ACCOUNTS]: 'Accounts',
  [CustomerRoutes.EDUCATION]: 'Education',
  [CustomerRoutes.REWARDS]: 'Rewards',
  [CustomerRoutes.PLAID_LINK_ACCOUNTS]: 'Bank Accounts',
  [CustomerRoutes.PROFILE_INFO_EDIT]: 'Manage Data',
  [CustomerRoutes.PROFILE_ADDRESS_EDIT]: 'Manage Address',
  [CustomerRoutes.PROFILE_FINANCIAL_DATA_EDIT]: 'Manage Financial Data',
};

const HEADER_HEIGHT = 70;
const BOTTOM_NAVIGATION_HEIGHT = 56;

export const CustomerLayout = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthContext();
  const isHeaderVisible =
    isAuthenticated && !HiddenHeaderRoutes.find((item) => pathname.includes(item));

  const isBottomNavigationVisible =
    isAuthenticated && !HiddenBottomNavigationRoutes.find((item) => pathname.includes(item));
  const activeNavigationHeight =
    0 +
    (isHeaderVisible ? HEADER_HEIGHT : 0) +
    (isBottomNavigationVisible ? BOTTOM_NAVIGATION_HEIGHT : 0);

  return (
    <>
      <UserProfileProvider>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {isHeaderVisible && <Header title={TITLES[pathname]} />}
        <Container
          size="xs"
          sx={() => ({ height: `calc(100dvh - ${activeNavigationHeight}px)`, overflowY: 'auto' })}
        >
          <Outlet />
        </Container>
        {isBottomNavigationVisible && <BottomNavigation />}
      </UserProfileProvider>
    </>
  );
};
