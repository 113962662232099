import { Tabs } from '@mantine/core';
import { Assets } from '../../components/accounts/components/assets';
import { Liabilities } from '../../components/accounts/components/liabilities';
import { useAppStore } from '../../../store/appStore';

export const CustomerAccountsPage = () => {
  const { setAccountActiveTab, activeTab } = useAppStore((state) => {
    return { setAccountActiveTab: state.setAccountActiveTab, activeTab: state.activeTab };
  });

  return (
    <Tabs mt={32} color="dark" variant="pills" value={activeTab} onTabChange={setAccountActiveTab}>
      <Tabs.List grow>
        <Tabs.Tab value="assets">Assets</Tabs.Tab>
        <Tabs.Tab value="liabilities">Liabilities</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="assets" pt={36}>
        <Assets />
      </Tabs.Panel>

      <Tabs.Panel value="liabilities" pt={36}>
        <Liabilities />
      </Tabs.Panel>
    </Tabs>
  );
};
