import { notifications } from '@mantine/notifications';
import { Hub } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerRoutes } from '../../customer-app';
import {
  AuthServiceMethods,
  InvalidPasswordException,
  NotAuthorizedException,
  User,
  confirmSignUp,
  federatedSignIn,
  getAuthenticatedUserInfo,
  getIsAccountCreationFinished,
  initiateForgotPassword,
  resendVerificationCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
  updateUserAttributes,
} from './service';

interface AuthContextType extends AuthServiceMethods {
  user: User | null;
  isAuthenticated: boolean;
  isAccountCreationFinished: number;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isAccountCreationFinished: 0,
  signUp: async () => {
    throw new Error('Not implemented');
  },
  confirmSignUp: async () => {
    throw new Error('Not implemented');
  },
  resendVerificationCode: async () => {
    throw new Error('Not implemented');
  },
  initiateForgotPassword: async () => {
    throw new Error('Not implemented');
  },
  resetPassword: async () => {
    throw new Error('Not implemented');
  },
  signIn: async () => {
    throw new Error('Not implemented');
  },
  signOut: async () => {
    throw new Error('Not implemented');
  },
  federatedSignIn: async () => {
    throw new Error('Not implemented');
  },
  updateUserAttributes: async () => {
    throw new Error('Not implemented');
  },
});

type Props = {
  children: React.ReactNode;
};

const AuthenticationProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [isInitialized, initializeProvider] = useState(false);

  console.log({ user });

  useEffect(() => {
    const checkUserSession = async () => {
      const userInfo = await getAuthenticatedUserInfo();
      setUser(userInfo);
      initializeProvider(true);
      console.log('checking user session...', { userInfo });
    };

    checkUserSession();

    const unsubscribe = Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signUp': {
          if (data.user.username) {
            navigate(
              CustomerRoutes.CONFIRM_EMAIL + `?email=${encodeURIComponent(data.user.username)}`,
            );
          }
          break;
        }
        case 'confirmSignUp': {
          if (data === 'SUCCESS') navigate(CustomerRoutes.LOGIN + `?verified=true`);
          break;
        }
        case 'signIn': {
          const isAccountFinished = await getIsAccountCreationFinished();
          console.log('Is account finished', isAccountFinished);
          if (isAccountFinished === 3) {
            navigate(CustomerRoutes.DASHBOARD);
          } else if (isAccountFinished === 0) {
            navigate(CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO);
          } else if (isAccountFinished === 1) {
            navigate(CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO);
          } else if (isAccountFinished === 2) {
            navigate(CustomerRoutes.FINISH_ACCOUNT_CREATION_FINANCIAL_DATA);
          }
          break;
        }
        case 'signOut': {
          navigate(CustomerRoutes.LOGIN);
          break;
        }
        case 'signUp_failure': {
          // todo :: @nemanja - Cognito should not return the account already exists
          const exception = data as InvalidPasswordException;
          if (exception.code === 'InvalidPasswordException') {
            notifications.show({
              message: exception.message,
            });
          } else {
            notifications.show({
              message: 'Something went wrong. Please, try again.',
            });
          }
          break;
        }

        case 'signIn_failure': {
          const exception = data as NotAuthorizedException;
          if (exception.code === 'NotAuthorizedException') {
            notifications.show({
              message: exception.message,
            });
          } else {
            notifications.show({
              message: 'Something went wrong. Please, try again.',
            });
          }
          break;
        }

        case 'forgotPassword': {
          navigate(CustomerRoutes.RESET_PASSWORD + `?email=${encodeURIComponent(data.username)}`);
          notifications.show({
            message: 'Verification code sent. Please, check your email',
          });
          break;
        }

        case 'forgotPasswordSubmit': {
          navigate(CustomerRoutes.LOGIN);
          notifications.show({
            message: 'Password successfully changed.',
          });
          break;
        }

        case 'updateUserAttributes': {
          checkUserSession();
          notifications.show({
            message: 'Information successfully updated.',
          });
          break;
        }

        default:
          break;
      }
    });

    return unsubscribe;
  }, [navigate, setUser, initializeProvider]);

  const isAuthenticated = useMemo(() => Boolean(user), [user]);
  const isAccountCreationFinished = useMemo(
    () => Number(user?.attributes['custom:accountDone']),
    [user],
  );

  const value = {
    user,
    isAuthenticated,
    isAccountCreationFinished,
    signUp,
    signIn,
    signOut,
    confirmSignUp,
    federatedSignIn,
    updateUserAttributes,
    resendVerificationCode,
    initiateForgotPassword,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{isInitialized && children}</AuthContext.Provider>;
};

const useAuthContext = () => useContext(AuthContext);

export { AuthenticationProvider, useAuthContext };
