import { Box, Title } from '@mantine/core';
import { useNavigate } from 'react-router';
import { AccountItem } from '../../components/accounts/components/account-item';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { liabilitiesNames } from '../../components/accounts/components/liabilities';

export const CreateAccountPageLiability = () => {
  const navigate = useNavigate();
  const onBackClick = () => navigate(CustomerRoutes.ACCOUNTS);
  const onCreateAccountClick = (liabilityType: string) => {
    navigate(`${CustomerRoutes.ACCOUNTS_CREATE_LIABILITY_FORM}/${liabilityType.toLowerCase()}`);
  };
  const renderLiabilitiesOptions = () => {
    return Object.entries(liabilitiesNames).map((liability) => {
      return (
        <AccountItem
          key={liability[0]}
          title={liability[1]}
          text={`Text related to adding new ${liability[1]}`}
          ctaText={`Add ${liability[1]} Liability`}
          handleCtaClick={() => onCreateAccountClick(liability[0])}
        />
      );
    });
  };

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Accounts"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />
      <Title order={5} mt={30} mb={8}>
        Add New Liability
      </Title>
      <Box>{renderLiabilitiesOptions()}</Box>
    </>
  );
};
