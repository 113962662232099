import { Box, Text, useMantineTheme } from '@mantine/core';

interface TransactionItemProps {
  transactionDate: string;
  transactionAmount: string;
}

export const TransactionItem = ({ transactionDate, transactionAmount }: TransactionItemProps) => {
  const theme = useMantineTheme();
  return (
    <Box
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.dark[6]}`,
        padding: '8px 4px',
        marginBottom: '12px',
      })}
    >
      <Text size="lg" mb={4} fw={600} c={theme.white}>
        {transactionDate}
      </Text>
      <Text fw={500} c={theme.colors.dark[1]}>
        {transactionAmount}
      </Text>
    </Box>
  );
};
