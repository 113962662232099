import { MantineProvider, MantineTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import './font.css';
import themeSettings from './theme';

import { Amplify } from 'aws-amplify';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AdminLayout, adminRoutes } from './admin-app';
import awsExports from './aws-exports';
import { AdminGuard, AuthGuard } from './components';
import { CustomerLayout, CustomerRoutes, customerRoutes, publicRoutes } from './customer-app';
import { AuthenticationProvider } from './providers';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const [localRedirectSignIn, devRedirectSignIn] = awsExports.oauth.redirectSignIn.split(',');
const [localRedirectSignOut, devRedirectSignOut] = awsExports.oauth.redirectSignOut.split(',');
Amplify.configure({
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  // ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : devRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : devRedirectSignOut,
  },
});

const queryClient = new QueryClient();

function App() {
  const { pathname } = useLocation();

  const overrideThemeSettings = {
    ...themeSettings,
    globalStyles: (theme: MantineTheme) => ({
      body: {
        backgroundColor:
          pathname === CustomerRoutes.QUESTIONNAIRE_START
            ? theme.colors.dark[7]
            : theme.colors.dark[9],
      },
      h4: {
        letterSpacing: '0.3px',
      },
    }),
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={overrideThemeSettings}>
      <Notifications position="top-center" />
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <Router />
        </AuthenticationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MantineProvider>
  );
}

function Router() {
  return (
    <Routes>
      <Route path="/" element={<CustomerLayout />}>
        {publicRoutes}

        <Route path="/customer" element={<AuthGuard />}>
          {customerRoutes}
        </Route>
      </Route>

      <Route path="/admin" element={<AdminLayout />}>
        <Route element={<AdminGuard />}>{adminRoutes}</Route>
      </Route>
    </Routes>
  );
}

export default App;
