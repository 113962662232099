import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Input, Text, TextInput, createStyles } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldErrors, UseFormReturn, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { formatBirthDate } from '../../utils';
import { UserProfileInfo } from '../../components/user-profile-info';

const useStyles = createStyles((theme) => ({
  inputLabel: {
    color: theme.colors.dark[1],
  },
}));

interface EditProfileInfoFields {
  firstName: string;
  lastName: string;
}

interface EditLegalNameProps {
  fields: EditProfileInfoFields;
  errors: FieldErrors<EditProfileInfoFields>;
  register: UseFormReturn<EditProfileInfoFields>['register'];
}

const EditLegalName = (props: EditLegalNameProps) => {
  const { classes } = useStyles();
  const { fields, errors, register } = props;

  return (
    <Box mb={30}>
      <Text size="lg" fw={400} mb={16}>
        Legal Name
      </Text>
      <TextInput
        mb="sm"
        size="lg"
        label="First Name"
        value={fields.firstName}
        {...register('firstName')}
        error={errors?.firstName?.message}
        classNames={{ label: classes.inputLabel }}
      />
      <TextInput
        mb="md"
        size="lg"
        label="Last Name"
        value={fields.lastName}
        {...register('lastName')}
        error={errors?.lastName?.message}
        classNames={{ label: classes.inputLabel }}
      />
    </Box>
  );
};

const EditDateOfBirth = ({
  birthDateDay,
  birthDateMonth,
  birthDateYear,
  setBirthDateDay,
  setBirthDateMonth,
  setBirthDateYear,
}: {
  birthDateDay: string;
  birthDateMonth: string;
  birthDateYear: string;
  setBirthDateDay: React.Dispatch<React.SetStateAction<string>>;
  setBirthDateMonth: React.Dispatch<React.SetStateAction<string>>;
  setBirthDateYear: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleDay = useCallback(
    (value: string | ChangeEvent<HTMLInputElement>) => {
      const inputValue = typeof value === 'string' ? value : value.target.value;
      if (inputValue === '') {
        setBirthDateDay(inputValue);
      } else {
        switch (birthDateMonth) {
          case '1':
          case '3':
          case '5':
          case '7':
          case '8':
          case '10':
          case '12':
            if (parseInt(inputValue) <= 31) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('31');
            }

            break;
          case '4':
          case '6':
          case '9':
          case '11':
            if (parseInt(inputValue) <= 30) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('30');
            }
            break;
          case '2':
            if (parseInt(inputValue) <= 28) {
              setBirthDateDay(inputValue);
            } else {
              setBirthDateDay('28');
            }
            break;
          default:
            setBirthDateDay(inputValue);
            break;
        }
      }
    },
    [birthDateMonth, setBirthDateDay],
  );
  useEffect(() => {
    handleDay(birthDateDay);
  }, [birthDateMonth, birthDateDay, handleDay]);
  const handleMonth = (event: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) <= 12 || event.target.value === '') {
      setBirthDateMonth(event.target.value);
    } else {
      setBirthDateMonth('12');
    }
  };

  const handleYear = (event: ChangeEvent<HTMLInputElement>) => {
    setBirthDateYear(event.target.value);
  };

  return (
    <>
      <Text size="lg" fw={400} mb={16}>
        Date of Birth
      </Text>
      {/* <DatePickerInput
        mx="auto"
        size="lg"
        maw={400}
        value={birthDate}
        label="Date of birth"
        icon={<CalendarIcon />}
        placeholder="Pick a date"
        onChange={(date) => setBirthDate(date)}
        classNames={{ label: classes.inputLabel }}
      /> */}
      <Flex justify="space-between" mx="-10px">
        <Box style={{ flex: 1 }} mx="10px">
          <Input placeholder="Day" value={birthDateDay} onChange={handleDay} inputMode="numeric" />
        </Box>
        <Box style={{ flex: 1 }} mx="10px">
          <Input
            placeholder="Month"
            value={birthDateMonth}
            onChange={handleMonth}
            inputMode="numeric"
          />
        </Box>
        <Box style={{ flex: 1 }} mx="10px">
          <Input
            placeholder="Year"
            value={birthDateYear}
            onChange={handleYear}
            inputMode="numeric"
          />
        </Box>
      </Flex>
    </>
  );
};

export const CustomerProfileInfoEditPage = () => {
  const navigate = useNavigate();
  const { user, updateUserAttributes } = useAuthContext();

  const [birthDateDay, setBirthDateDay] = useState<string>(
    user?.attributes?.birthdate ? new Date(user?.attributes?.birthdate).getDate().toString() : '',
  );

  const [birthDateMonth, setBirthDateMonth] = useState<string>(
    user?.attributes?.birthdate
      ? (new Date(user?.attributes?.birthdate).getMonth() + 1).toString()
      : '',
  );

  const [birthDateYear, setBirthDateYear] = useState<string>(
    user?.attributes?.birthdate
      ? new Date(user?.attributes?.birthdate).getFullYear().toString()
      : '',
  );

  const { formState, watch, register, handleSubmit } = useForm<EditProfileInfoFields>({
    mode: 'onChange',
    defaultValues: {
      firstName: user?.attributes?.given_name || '',
      lastName: user?.attributes?.family_name || '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required('Required field.'),
        lastName: yup.string().required('Required field.'),
      }),
    ),
  });

  const { mutate, isLoading } = useMutation<
    void,
    Exception,
    EditProfileInfoFields & { birthDate: Date }
  >({
    mutationFn: ({ firstName, lastName, birthDate }) =>
      updateUserAttributes({
        given_name: firstName,
        family_name: lastName,
        birthdate: formatBirthDate(birthDate),
      }),
    onSuccess: () => {
      navigate(CustomerRoutes.SETTINGS);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const fields = watch();

  const isFormValid = useMemo(
    () =>
      formState.isValid &&
      Boolean(birthDateDay) &&
      Boolean(birthDateMonth) &&
      Boolean(birthDateYear),
    [formState, birthDateDay, birthDateMonth, birthDateYear],
  );

  const onBackClick = () => navigate(CustomerRoutes.SETTINGS);

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Personal Data"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />
      <form
        style={{ height: 'calc(100% - 70px)' }}
        onSubmit={handleSubmit(({ firstName, lastName }) => {
          const birthDate = new Date(
            parseInt(birthDateYear as string),
            parseInt(birthDateMonth as string) - 1,
            parseInt(birthDateDay as string),
          );
          mutate({ firstName, lastName, birthDate: birthDate as Date });
        })}
      >
        <Flex direction="column" justify="space-between" style={{ height: '100%' }}>
          <Box>
            {/* <EditProfilePicture setProfilePicture={setProfilePicture} /> */}
            <UserProfileInfo simple />
            <EditLegalName register={register} fields={fields} errors={formState.errors} />
            <EditDateOfBirth
              birthDateDay={birthDateDay}
              setBirthDateDay={setBirthDateDay}
              birthDateMonth={birthDateMonth}
              setBirthDateMonth={setBirthDateMonth}
              birthDateYear={birthDateYear}
              setBirthDateYear={setBirthDateYear}
            />
          </Box>
          <Button
            size="lg"
            fullWidth
            type="submit"
            variant="filled"
            loading={isLoading}
            disabled={!isFormValid || isLoading}
          >
            Done
          </Button>
        </Flex>
      </form>
    </>
  );
};
