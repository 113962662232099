import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { REACT_QUERY_CACHE_KEYS } from '../../../../../constants/react-query-cache-keys';
import { AddEditAsset } from '../../../../../validation-schemas/assets';
import { AssetFormFields } from '../../../../pages/accounts/create-account-form-page';
import { CustomerRoutes } from '../../../../routes';
import { AssetData, AssetPayload, updateAsset } from '../../../../service/assets';

interface EditAssetFormProps {
  assetData: AssetData | undefined;
}

export const EditAssetForm = ({ assetData }: EditAssetFormProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (assetsPayload: AssetPayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      updateAsset(assetData?.id, assetsPayload),
    onSuccess: () => {
      queryClient.invalidateQueries([REACT_QUERY_CACHE_KEYS.asset, assetData?.id]);
      notifications.show({
        message: 'Successfully updated asset.',
      });
      setTimeout(() => {
        navigate(`${CustomerRoutes.ACCOUNTS_INFO_ASSET}/${assetData?.id}`);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { formState, watch, register, handleSubmit } = useForm<AssetFormFields>({
    mode: 'onChange',
    defaultValues: {
      accountName: assetData?.name,
      purchaseValue: assetData?.purchaseValue,
      currentValue: assetData?.currentValue,
    },
    resolver: yupResolver(AddEditAsset),
  });

  const { accountName, purchaseValue, currentValue } = watch();

  return (
    <form
      onSubmit={handleSubmit(({ accountName, purchaseValue, currentValue }) => {
        mutate({
          name: accountName,
          purchaseValue,
          currentValue,
        });
      })}
    >
      <TextInput
        mb="sm"
        size="lg"
        label="Account name"
        value={accountName}
        {...register('accountName')}
        error={formState.errors?.accountName?.message}
      />
      <Flex gap="md">
        <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
          <TextInput
            size="lg"
            label="Value When Purchased"
            value={purchaseValue}
            {...register('purchaseValue')}
            error={formState.errors?.purchaseValue?.message}
          />
        </Flex>
        <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
          <TextInput
            size="lg"
            label="Current Value"
            value={currentValue}
            min={0}
            {...register('currentValue')}
            error={formState.errors?.currentValue?.message}
          />
        </Flex>
      </Flex>
      <Button
        mt={10}
        size="lg"
        fullWidth
        type="submit"
        variant="filled"
        disabled={!formState.isValid || isLoading}
      >
        Save changes
      </Button>
    </form>
  );
};
