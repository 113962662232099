import {
  Alert,
  Box,
  Button,
  Flex,
  Image,
  Loader,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import DummyRewardImage1 from '../../../assets/reward-dummy.jpg';
import { PointsBadge } from '../../components';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { useGetReward } from '../../service/rewards';

export const InfoRewardPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { id: rewardId } = useParams();

  const { data: rewardData, isLoading, isError } = useGetReward(rewardId);

  const onBackClick = () => navigate(CustomerRoutes.REWARDS);

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Rewards"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError || !rewardData || !rewardId) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Rewards"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching reward
        </Alert>
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Rewards"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />

      <Box mt={30}>
        <Image
          w={152}
          maw={152}
          radius="md"
          mb="md"
          src={DummyRewardImage1}
          alt={rewardData?.title}
        />
        <Box>
          <Title order={4} c={theme.white} mb="md">
            {rewardData?.title}
          </Title>
          <Text size="md" fw={700} c={theme.white} mb="md">
            {rewardData?.description}
          </Text>
          <PointsBadge value={rewardData?.bonusPoints} />
        </Box>
        <Link
          to={`${CustomerRoutes.REWARDS_REDEEM}/${rewardId}`}
          style={{ textDecoration: 'none' }}
        >
          <Button fullWidth size="lg" mt={40}>
            Redeem Reward for {rewardData?.bonusPoints} BP
          </Button>
        </Link>
      </Box>
    </>
  );
};
