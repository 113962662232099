import { useMantineTheme } from '@mantine/core';
import { CheckmarkIcon } from '../../../assets/icons/checkmark';
import { useStyles } from '../../hooks/use-styles';

export const CheckboxIcon = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <div className={classes.customCheckboxIconWrapper}>
      <CheckmarkIcon color={theme.white} />
    </div>
  );
};
