import { createStyles } from '@mantine/core';

export default createStyles((theme) => ({
  inputLabel: {
    color: theme.colors.dark[1],
    fontSize: '0.875rem',
    fontWeight: 400,
    marginBottom: '4px',
  },
}));
