import { createStyles } from '@mantine/core';

export const useHeaderBackAndCtaStyles = createStyles((theme) => ({
  headerWrapper: {
    top: '0',
    left: '0',
    width: '100%',
    padding: '23px 1.5rem',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.dark[8],
    height: '70px',
  },
  headerClear: {
    height: '70px',
  },
  headerBack: {
    padding: '0 10px 0 0',
    display: 'flex',
    alignItems: 'center',
    '& > svg > path': {
      stroke: '#CBD5E1',
    },
  },
  headerBackText: {
    marginLeft: '15px',
    fontSize: '1rem',
    fontWeight: 500,
    color: '#CBD5E1',
  },
  headerCta: {
    marginLeft: 'auto',
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.colors.blue[4],
    textDecoration: 'none',
    background: 'transparent',
    border: 0,
    outline: 'none',
    fontFamily: 'SFProDisplay,sans-serif',
  },
}));
