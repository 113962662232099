import { Accordion, Alert, Box, Button, Flex, Loader, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AccordionChevronIcon } from '../../../../../assets/icons/accordion-chevron';
import { USDollar } from '../../../../../helpers/formatter';
import { CustomerRoutes } from '../../../../routes';
import { useGetAssets } from '../../../../service/assets';
import { AccountAccordionListItem } from '../accordion-list-item';

export const assetsNames: { [key: string]: string } = {
  bankAccounts: 'Banking accounts',
  crypto: 'Crypto',
  investments: 'Investment',
  realEstates: 'Real estate',
};

export const Assets = () => {
  const { data: assetsData, isError, isLoading } = useGetAssets();

  const isAssetsDataEmpty =
    assetsData &&
    Object.keys(assetsData).every(function (key) {
      // eslint-disable-next-line
      // @ts-ignore
      return assetsData[key].length === 0;
    });

  if (isLoading)
    return (
      <Flex justify="center">
        <Loader />
      </Flex>
    );

  if (isError) {
    return (
      <Alert title="Error" color="red" radius="md">
        Error fetching assets
      </Alert>
    );
  }

  if (isAssetsDataEmpty)
    return (
      <Flex
        justify="center"
        align="center"
        sx={() => ({
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-45%)',
          left: 0,
          width: '100%',
        })}
      >
        <Box ta="center">
          <Text fw={600}>No Assets Connected</Text>
          <Link to={CustomerRoutes.ACCOUNTS_CREATE} style={{ textDecoration: 'none' }}>
            <Button mt={32} size="md">
              Add New Asset
            </Button>
          </Link>
        </Box>
      </Flex>
    );
  return (
    <>
      <Accordion
        radius="md"
        variant="separated"
        chevron={<AccordionChevronIcon />}
        chevronPosition="left"
      >
        {assetsData &&
          Object.entries(assetsData).map((asset, index) => {
            const totalBalance = Object.values(asset[1]).reduce(function (prev, cur) {
              return (
                prev +
                Number(
                  cur.plaidCurrentBalance !== undefined
                    ? cur.plaidCurrentBalance
                    : cur.currentValue,
                )
              );
            }, 0);
            return Object.values(asset[1]).length ? (
              <Accordion.Item value={asset[0]} key={index}>
                <Accordion.Control>
                  {assetsNames[asset[0]]}
                  <span className="amount">{USDollar.format(totalBalance)}</span>
                </Accordion.Control>
                <Accordion.Panel>
                  {Object.values(asset[1]).map((item, itemIndex) => (
                    <AccountAccordionListItem
                      accountUrl={`${CustomerRoutes.ACCOUNTS_INFO_ASSET}/${item.id}`}
                      accountName={item.plaidOfficialName || item.plaidName || item.name}
                      accountAmount={
                        item.plaidCurrentBalance !== undefined
                          ? item.plaidCurrentBalance
                          : item.currentValue
                      }
                      accountCurrency={item.plaidCurrencyCode}
                      key={itemIndex}
                    />
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            ) : null;
          })}
      </Accordion>
      <Link to={CustomerRoutes.ACCOUNTS_CREATE} style={{ textDecoration: 'none' }}>
        <Button mt={32} size="lg" fullWidth variant="outline">
          New Asset
        </Button>
      </Link>
    </>
  );
};
