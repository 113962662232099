import { Box, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { PlaidLinkError, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { useAppStore } from '../../../store/appStore';
import { AccountItem } from '../../components/accounts/components/account-item';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { AssetTypeLower } from '../../service/assets';
import { useExchangePlaidToken, useGetPlaidLinkToken } from '../../service/plaid';

export const CreateAccountPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const [isOpenPlaidValid, setIsOpenPlaidValid] = useState(false);

  const { setAssetType, clearAssetType } = useAppStore((state) => {
    return { setAssetType: state.setAssetType, clearAssetType: state.clearAssetType };
  });

  const removeLinkTokenFromStorage = () => localStorage.removeItem('linkToken');
  const setLinkTokenInStorage = (token: string) => localStorage.setItem('linkToken', token);

  const onBackClick = () => navigate(CustomerRoutes.ACCOUNTS);

  const onCreateAccountClick = (assetType: string) => {
    setAssetType(assetType as keyof typeof AssetTypeLower);
    navigate(CustomerRoutes.ACCOUNTS_CREATE_FORM);
  };

  const {
    data,
    isLoading: isCreatingLinkToken,
    refetch: startPlaidWizard,
  } = useGetPlaidLinkToken({
    enabled: false,
    onSuccess: ({ linkToken }) => {
      setLinkTokenInStorage(linkToken);
    },
    onError: () => {
      notifications.show({
        message: 'Failed to initiate the connection with Plaid.',
      });
    },
  });

  const { mutate: exchangeTokens, isLoading: isExchangingTokens } = useExchangePlaidToken({
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.assets);
      navigate(CustomerRoutes.ACCOUNTS);
      notifications.show({
        message: 'Successfully connected accounts.',
      });
    },
    onError: () => {
      notifications.show({
        message: 'Error finishing up account connection.',
      });
    },
  });

  const config: PlaidLinkOptions = {
    token: data?.linkToken || '',
    onSuccess: async (public_token: string) => exchangeTokens({ publicToken: public_token }),
    onExit: (error: PlaidLinkError | null) => {
      if (error !== null) {
        notifications.show({
          message: 'Unsuccessful connection of bank accounts. Please, try again',
        });
      }

      // todo :: should we do this only on error
      removeLinkTokenFromStorage();
    },
    ...{},
  };

  const { open, ready, exit, error: plaidError } = usePlaidLink(config);

  useEffect(() => {
    clearAssetType();
  }, []);

  useEffect(() => {
    if (ready && isOpenPlaidValid) {
      open();
    }
  }, [open, ready]);

  useEffect(() => {
    if (exit) {
      setIsOpenPlaidValid(false);
    }
  }, [exit]);

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Accounts"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />
      <Title order={5} mt={30} mb={8}>
        Create New Account
      </Title>
      <Text mb={30} c={theme.colors.dark[0]}>
        Based on the questionnaire you took your financial persona is Big Spender.{' '}
      </Text>
      <Box>
        {isExchangingTokens && (
          <Text fz="md" c={theme.colors.dark[1]} mb="xl">
            Finishing up Plaid connection...
          </Text>
        )}
        {isCreatingLinkToken && (
          <Text fz="md" c={theme.colors.dark[1]} mb="xl">
            Opening Plaid...
          </Text>
        )}
        {plaidError && (
          <Text color="red" fz="md">
            Error connecting to Plaid. Please try again.
          </Text>
        )}
        <AccountItem
          title="Bank Account"
          text="Connect bank accounts with Flow app and allow transaction tracking. We support over 10,000 institutions."
          ctaText={isCreatingLinkToken ? 'Opening Plaid...' : 'Connect Bank Account'}
          loading={isCreatingLinkToken}
          disabled={isCreatingLinkToken}
          handleCtaClick={() => {
            setIsOpenPlaidValid(true);
            startPlaidWizard();
          }}
        />
        <AccountItem
          title="Crypto"
          text="Currently we only support adding of crypto assets manually."
          ctaText="Add Crypto Asset"
          handleCtaClick={() => onCreateAccountClick('crypto')}
        />
        <AccountItem
          title="Investment"
          text="Currently we only support adding of crypto assets manually."
          ctaText="Add Investment"
          handleCtaClick={() => onCreateAccountClick('investments')}
        />
        <AccountItem
          title="Real Estate"
          text="Currently we only support adding of crypto assets manually."
          ctaText="Add Real Estate"
          handleCtaClick={() => onCreateAccountClick('realEstates')}
        />
      </Box>
    </>
  );
};
