import { createStyles } from '@mantine/core';

export const useBottomNavigationStyles = createStyles((theme) => ({
  bottomNavigationIconWrapper: {
    height: '40px',
    width: '43px',
    position: 'relative',
    color: theme.colors.dark[2],
    '& > div': {
      height: '8px',
    },
    '& > svg': {
      height: '24px',
      width: '24px',
    },
  },
  bottomNavigationIconWrapperActive: {
    color: theme.colors.orange[3],
    width: '43px',
    fontWeight: 700,
    position: 'relative',
    '& > div': {
      height: '7px',
    },
    '& > svg': {
      height: '24px',
      width: '24px',
    },
  },
  bottomNavigationText: {
    lineHeight: 0,
    marginTop: '3px',
    fontSize: '0.625rem',
  },
  bottomNavigationNotification: {
    position: 'absolute',
    top: '-2px',
    right: '6px',
    backgroundColor: theme.colors.red[8],
    borderRadius: '50%',
    lineHeight: '1px',
    fontSize: '0.47rem',
    color: theme.white,
    width: '10px',
    height: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
