export const HomeFilledIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9001 0.990723C12.3169 0.990723 11.6433 1.2427 11.2272 1.4171C10.9947 1.51452 10.7896 1.61118 10.6429 1.6833C10.5692 1.71954 10.5093 1.75004 10.4669 1.77194L10.4168 1.79812L10.4023 1.80579L10.3979 1.80816L10.3954 1.80951C10.3953 1.80957 10.3954 1.80951 10.8421 2.6398L10.3954 1.80951C10.349 1.83444 10.3049 1.86321 10.2633 1.89551L3.55774 7.11014C3.04134 7.51172 2.62355 8.026 2.33626 8.6137C2.04903 9.20127 1.89985 9.84714 1.90009 10.5011V19.5521C1.90009 20.469 2.26432 21.3484 2.91266 21.9967C3.561 22.645 4.44034 23.0093 5.35722 23.0093H20.4429C21.3598 23.0093 22.2391 22.645 22.8875 21.9967C23.5358 21.3484 23.9001 20.469 23.9001 19.5521V10.5007C23.9001 9.17531 23.2878 7.92347 22.2408 7.10987L15.537 1.89562C14.7831 1.30914 13.8552 0.990723 12.9001 0.990723Z"
        fill="#FC9244"
      />
    </svg>
  );
};
