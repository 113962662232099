import { Badge } from '@mantine/core';

const BADGE_ACTIVE_COLOR = '#FC9244';

export const PointsBadge = ({ value, active = true }: { value: number; active?: boolean }) => (
  <Badge
    p="4px 8px"
    radius="md"
    variant="outline"
    h={24}
    sx={(theme) => ({
      color: active ? BADGE_ACTIVE_COLOR : theme.colors.dark[2],
      border: `1px solid ${active ? BADGE_ACTIVE_COLOR : theme.colors.dark[2]}`,
      fontSize: '12px',
    })}
  >
    {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} bp
  </Badge>
);
