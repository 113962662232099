import { Alert, Box, Flex, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router';
import { USDollar } from '../../../helpers/formatter';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { useGetLiability } from '../../service/liabilities';

export const InfoAccountPageLiability = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { id: liabilityId } = useParams();

  const { data: liabilityData, isLoading, isError } = useGetLiability(liabilityId);
  const liabilityOfficialName = liabilityData?.name;
  const liabilityBalance = liabilityData?.amount;

  const onBackClick = () => navigate(CustomerRoutes.ACCOUNTS);

  const onHeaderCtaClick = () =>
    navigate(`${CustomerRoutes.ACCOUNTS_EDIT_LIABILITY}/${liabilityData?.id}`);

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Accounts"
          ctaText="Edit liability"
          handleCtaClick={onHeaderCtaClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Accounts"
          ctaText="Edit liability"
          handleCtaClick={onHeaderCtaClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching liabilities
        </Alert>
      </>
    );
  }

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Liabilities"
        ctaText="Edit liability"
        handleCtaClick={onHeaderCtaClick}
      />

      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.colors.dark[7]}`,
          backgroundColor: theme.colors.dark[8],
          padding: '24px',
          marginTop: '30px',
          marginBottom: '40px',
          borderRadius: '10px',
        })}
      >
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb="sm" lh="22.4px">
          Liability Name
        </Text>
        <Title order={5} mb="md">
          {liabilityOfficialName}
        </Title>
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb={6} lh="22.4px">
          Balance
        </Text>
        <Title order={6}>{USDollar.format(Number(liabilityBalance))}</Title>
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb={6} lh="22.4px">
          Due Date
        </Text>
        <Title order={6}>{liabilityData?.monthlyDueDate}</Title>
        <Text size="lg" c={theme.colors.dark[1]} fw={500} mb={6} lh="22.4px">
          Interest rate
        </Text>
        <Title order={6}>{liabilityData?.interestRate}</Title>
      </Box>
      {/* {liabilityData?.transactions && liabilityData?.transactions.length > 0 ? (
                <BankAssetTransactions />
            ) : null} */}
    </>
  );
};
