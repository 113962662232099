import { Box, Text, useMantineTheme } from '@mantine/core';
import { TransactionItem } from '../transactions-item';

export const BankAssetTransactions = () => {
  const theme = useMantineTheme();
  return (
    <>
      <Text size="xl" c={theme.colors.dark[2]} fw={700} mb="23px">
        Account Transactions
      </Text>
      <Box>
        <TransactionItem transactionDate="4 April 2023 | 20:45" transactionAmount="$450" />
        <TransactionItem transactionDate="5 March 2023 | 20:45" transactionAmount="$450" />
        <TransactionItem transactionDate="20 February 2023 | 20:45" transactionAmount="$50" />
        <TransactionItem transactionDate="1 February 2023 | 20:45" transactionAmount="$400" />
      </Box>
    </>
  );
};
