import {
  Alert,
  Box,
  Button,
  Flex,
  Loader,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { EditAssetForm } from '../../components/accounts/components/edit-asset-form';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { AssetType, disconnectAsset, useGetAsset } from '../../service/assets';

export const EditAccountPageAsset = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const [opened, { toggle, close }] = useDisclosure(false);

  const { id: assetId } = useParams();
  const { data: assetData, isLoading, isError } = useGetAsset(assetId);

  const { mutate, isLoading: isDisconnectLoading } = useMutation({
    mutationFn: (assetId: string | undefined) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      disconnectAsset(assetId),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.assets);
      notifications.show({
        message: 'Successfully disconnected account.',
      });
      setTimeout(() => {
        navigate(CustomerRoutes.ACCOUNTS);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const onBackClick = () => navigate(`${CustomerRoutes.ACCOUNTS_INFO_ASSET}/${assetData?.id}`);

  if (isLoading)
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Account info"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      </>
    );

  if (isError) {
    return (
      <>
        <HeaderBackAndCta
          handleBackClick={onBackClick}
          backText="Account info"
          ctaText="Cancel"
          handleCtaClick={onBackClick}
        />
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching assets
        </Alert>
      </>
    );
  }

  return (
    <Box mt={30}>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Account info"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />
      {assetData?.assetType === AssetType.BANK_ACCOUNT ? (
        <TextInput
          mb="sm"
          size="lg"
          label="Account name"
          value={assetData?.plaidOfficialName || assetData?.plaidName}
          disabled
        />
      ) : (
        <EditAssetForm assetData={assetData} />
      )}

      <Button
        variant="outline"
        sx={{
          color: theme.colors.red[5],
          borderColor: theme.colors.red[5],
          backgroundColor: '#260908',
        }}
        size="lg"
        fullWidth
        mt={40}
        onClick={toggle}
        loading={isDisconnectLoading}
      >
        Disconnect Account
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        size="327px"
        padding="25px"
      >
        <LoadingOverlay visible={isDisconnectLoading} overlayBlur={2} />
        <Stack>
          <Text fz="18px" fw={700} align="center">
            Are you sure you’d like to disconnect this account?
          </Text>
          <Button onClick={() => mutate(assetId)}>Delete</Button>
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
        </Stack>
      </Modal>
    </Box>
  );
};
