import { createStyles } from '@mantine/core';

export const useObjectiveCreateStyles = createStyles((theme) => ({
  objectivesVerticalSpaceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 100px)',
    [`&::before`]: {
      content: `''`,
      display: 'block',
      margin: '30px 0 0',
    },
    [`& > div:last-child`]: {
      marginTop: 'auto',
    },
  },
  categoryBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  categoryBox: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.6rem',
    padding: '25px',
    background: '#1C1C27',
    border: '1px solid #343449',
    borderRadius: '10px',
  },
  objectiveCreateStepperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 0 28px',
  },
  objectiveStepperBack: {
    padding: '0 10px 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  objectiveStepperCancel: {
    marginLeft: 'auto',
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.colors.blue[4],
    textDecoration: 'none',
  },
}));
