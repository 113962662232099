import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../../../providers';
import { CustomerRoutes } from '../../../routes';
import { useUserProfileContext } from '../../../service/user/context';

export const QuestionnaireGuard = ({ children }: PropsWithChildren) => {
  const { userProfile } = useUserProfileContext();
  const { isAccountCreationFinished } = useAuthContext();

  const isAllowedToPass = [
    isAccountCreationFinished,
    !userProfile?.hasSubmittedQuestionnaire,
  ].every(Boolean);

  if (isAllowedToPass) return <>{children}</>;

  return userProfile?.hasSubmittedQuestionnaire ? (
    <Navigate to={CustomerRoutes.DASHBOARD} />
  ) : (
    <Navigate to={CustomerRoutes.FINISH_ACCOUNT_CREATION_PERSONAL_INFO} />
  );
};
