import { Badge, Button, Flex, Progress, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../providers';
import { ProfileInfoBox } from '../../components/profile-info-box';
import { useInfoBoxStyles } from '../../components/profile-info-box/hooks/use-styles';
import { UserProfileInfo } from '../../components/user-profile-info';
import { CustomerRoutes } from '../../routes';
import { useUserProfileContext } from '../../service/user/context';

export const CustomerProfilePage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { signOut } = useAuthContext();
  const { classes } = useInfoBoxStyles();
  const { t } = useTranslation();
  const { userProfile } = useUserProfileContext();
  const userProfileSallary = userProfile?.salary;

  const handleAddressOnClick = () => navigate(CustomerRoutes.PROFILE_FINANCIAL_DATA_EDIT);

  const handleSubscriptionOnClick = () => {
    console.log('Manage subscription');
  };

  const myFinancialDataInfoTextNode = (
    <>
      {t(`${userProfileSallary?.relationshipStatus}`)}
      <br /> {userProfileSallary?.dependents}{' '}
      {Number(userProfileSallary?.dependents) === 1 ? 'Dependent' : 'Dependents'}
      <br />
      {userProfileSallary?.annualAmount}$ /{' '}
      {t(`financialData.frequency.${userProfileSallary?.frequency}`)}
    </>
  );

  return (
    <>
      <UserProfileInfo />
      <div className={classes.profileInfoBox}>
        <div className={classes.profileInfoBoxTitleWrapper}>
          <Text fz="xl" fw={700}>
            Experience Level
          </Text>
        </div>
        {userProfile?.points?.level ? (
          <div>
            <Flex justify="space-between" align="center" mb="14px">
              <Flex align="center">
                <Badge
                  p="5px 8px"
                  radius="md"
                  variant="outline"
                  h={24}
                  sx={() => ({
                    color: '#1FCCB7',
                    border: '1px solid #1FCCB7',
                    fontSize: '12px',
                    fontWeight: 700,
                    textTransform: 'none',
                  })}
                >
                  Level {userProfile?.points?.level}
                </Badge>
                {/* <Text size="md" fw={700} mr="8px">
                  Level {userProfile?.points?.level}
                </Text> */}
              </Flex>
              <Text size="md" fw={700} color={theme.colors.dark[1]}>
                Level {userProfile?.points?.level + 1}
              </Text>
            </Flex>
            <Progress
              color="#1FCCB7"
              size="sm"
              radius="xl"
              value={Math.round((userProfile?.points?.xp / userProfile?.points?.nextLevelXp) * 100)}
            />
            <Text size="sm" fw={500} lh={1.2} color={theme.colors.dark[3]} mt="6px">
              {(userProfile?.points?.nextLevelXp - userProfile?.points?.xp).toLocaleString('fr')}{' '}
              remaining to reach level {userProfile?.points?.level + 1}
            </Text>
          </div>
        ) : null}
      </div>
      <ProfileInfoBox
        title="Financial Data"
        text={myFinancialDataInfoTextNode}
        onClickManage={handleAddressOnClick}
      />
      <ProfileInfoBox
        title="Subscription / Billing "
        text={
          <>
            Subscription
            <Text fz="md" fw={700} mb="md">
              Yearly Plan (9,99/month)
            </Text>
            Next billing date
            <Text fz="md" fw={700} mb="md">
              15 Apr 2023
            </Text>
            Credit card
            <Text fz="md" fw={700}>
              xxxx-xxxx-xxxx-9090
            </Text>
          </>
        }
        onClickManage={handleSubscriptionOnClick}
      />
      <Button size="lg" fullWidth variant="outline" onClick={signOut}>
        Logout
      </Button>
    </>
  );
};
