import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Select, TextInput, createStyles } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { FinancialDataPayload, Frequency, mutateFinancialData } from '../../service';
import { useUserProfileContext } from '../../service/user/context';
import { FinancialDataFields, FormSection } from '../finish-account-creation/financial-data-page';

const useStyles = createStyles((theme) => ({
  inputLabel: {
    color: theme.colors.dark[1],
  },
}));

export const EditCustomerFinancialDataPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const { userProfile } = useUserProfileContext();
  const userProfileSallary = userProfile?.salary;
  console.log('FFF', userProfile);
  const { formState, watch, register, handleSubmit, control } = useForm<FinancialDataFields>({
    mode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      yup.object().shape({
        incomeTitle: yup.string().required('Required field.'),
        annualAmount: yup.number().required('Required field.'),
        frequency: yup.string().required('Required field.'),
        relationshipStatus: yup.string().required('Required field.'),
        dependents: yup.string().required('Required field.'),
      }),
    ),
    defaultValues: {
      incomeTitle: userProfileSallary?.incomeTitle ?? '',
      annualAmount: userProfileSallary?.annualAmount ?? 0,
      frequency: userProfileSallary?.frequency ?? undefined,
      relationshipStatus: userProfileSallary?.relationshipStatus ?? undefined,
      dependents: String(userProfileSallary?.dependents) ?? '',
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (financialDataPayload: FinancialDataPayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateFinancialData(financialDataPayload),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.userProfile);
      notifications.show({
        message: 'Successfully modified financial data.',
      });
      navigate(CustomerRoutes.PROFILE);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { incomeTitle, annualAmount, frequency, relationshipStatus, dependents } = watch();

  const onBackClick = () => navigate(CustomerRoutes.PROFILE);

  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="User Address"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />
      <form
        style={{ height: 'calc(100% - 70px)' }}
        onSubmit={handleSubmit(
          ({
            incomeTitle,
            annualAmount,
            frequency,
            relationshipStatus,
            dependents: dependentsString,
          }) => {
            const dependents = Number(dependentsString);
            mutate({ incomeTitle, annualAmount, frequency, relationshipStatus, dependents });
          },
        )}
      >
        <Flex pt={30} direction="column" justify="space-between" style={{ height: '100%' }}>
          <Box>
            <FormSection title="Income Data">
              <TextInput
                mb="sm"
                size="lg"
                label="Income Title"
                value={incomeTitle}
                {...register('incomeTitle')}
                error={formState.errors?.incomeTitle?.message}
                classNames={{ label: classes.inputLabel }}
              />
              <TextInput
                mb="md"
                size="lg"
                label="Annual Amount"
                value={annualAmount}
                {...register('annualAmount')}
                error={formState.errors?.annualAmount?.message}
                classNames={{ label: classes.inputLabel }}
              />
              <Controller
                render={({ field }) => (
                  <Select
                    size="lg"
                    mb="xl"
                    label="Frequency"
                    placeholder="Select an frequency"
                    data={[
                      { value: Frequency.weekly, label: 'Weekly' },
                      { value: Frequency.biweekly, label: 'Biweekly' },
                      { value: Frequency.monthly, label: 'Monthly' },
                    ]}
                    {...field}
                    value={frequency}
                    error={formState.errors?.frequency?.message}
                    classNames={{ label: classes.inputLabel }}
                  />
                )}
                control={control}
                name="frequency"
              />
            </FormSection>
            <FormSection title="Financial Data">
              <Controller
                render={({ field }) => (
                  <Select
                    size="lg"
                    mb="xl"
                    label="Relationship Status"
                    placeholder="Select status"
                    data={[
                      { value: 'single', label: 'Single' },
                      { value: 'married', label: 'Married' },
                      { value: 'divorced', label: 'Divorced' },
                    ]}
                    {...field}
                    value={relationshipStatus}
                    error={formState.errors?.relationshipStatus?.message}
                    classNames={{ label: classes.inputLabel }}
                  />
                )}
                control={control}
                name="relationshipStatus"
              />
              <Controller
                render={({ field }) => (
                  <Select
                    size="lg"
                    mb="xl"
                    label="Dependents"
                    placeholder="Select number of dependents"
                    data={[
                      { value: '0', label: 'No Dependent' },
                      { value: '1', label: '1 Dependent' },
                      { value: '2', label: '2 Dependents' },
                      { value: '3', label: '3 Dependents' },
                      { value: '4', label: '4 Dependents' },
                      { value: '5', label: '5 Dependents' },
                      { value: '6', label: '6 Dependent' },
                      { value: '7', label: '7 Dependents' },
                      { value: '8', label: '8 Dependents' },
                      { value: '9', label: '9 Dependents' },
                      { value: '10', label: '10 Dependents' },
                    ]}
                    {...field}
                    value={dependents}
                    error={formState.errors?.dependents?.message}
                    classNames={{ label: classes.inputLabel }}
                  />
                )}
                control={control}
                name="dependents"
              />
            </FormSection>
          </Box>
          <Button
            size="lg"
            fullWidth
            type="submit"
            variant="filled"
            loading={isLoading}
            disabled={!formState.isValid || isLoading}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};
