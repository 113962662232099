import { Flex, Loader, Text, Title, useMantineTheme } from '@mantine/core';
import { useGetCategories } from '../../../../service/categories';
import { useObjectiveCreateStyles } from '../../hooks/use-styles';
import { StepperHeader } from '../stepper-header';

interface CreateObjectiveFirstStepProps {
  handleCategoryClick: (categoryId: string) => void;
}

export const CreateObjectiveFirstStep = ({
  handleCategoryClick,
}: CreateObjectiveFirstStepProps) => {
  const { classes: objectiveCreateClasses } = useObjectiveCreateStyles();
  const theme = useMantineTheme();
  const { data, isLoading } = useGetCategories();

  if (isLoading)
    return (
      <>
        <StepperHeader />
        <div className={objectiveCreateClasses.objectivesVerticalSpaceContainer}>
          <Flex justify="center">
            <Loader />
          </Flex>
          <div></div>
        </div>
      </>
    );

  return (
    <>
      <StepperHeader />
      <div className={objectiveCreateClasses.objectivesVerticalSpaceContainer}>
        <div>
          <Text ta="center" fz="lg" mb="sm" color={theme.colors.dark[1]}>
            Step 1 of 3
          </Text>
          <Title ta="center" order={4} mb="xl">
            Choose Your Objective
          </Title>
        </div>
        <div className={objectiveCreateClasses.categoryBoxWrapper}>
          {data?.map((category, index) => (
            <div
              key={`category${index}`}
              onClick={() => handleCategoryClick(category.id)}
              className={objectiveCreateClasses.categoryBox}
            >
              {category.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
