export const AccordionChevronIcon = ({ color = '#334155' }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1493_4080)">
        <path
          d="M0.857143 3.42859L6 8.57145L11.1429 3.42859"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1493_4080">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
