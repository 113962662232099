import { Stepper } from '@mantine/core';
import { useState } from 'react';
import { useStyles } from '../../../../hooks/use-styles';
import { CreateObjectiveFirstStep } from '../create-objective-first-step';
import { CreateObjectiveSecondStep } from '../create-objective-second-step';
import { CreateObjectiveThirdStep } from '../create-objective-third-step';

export const ObjectiveCreateStepper = () => {
  const { classes } = useStyles();
  const [active, setActive] = useState(0);
  const [activeCategory, setActiveCategory] = useState('');
  const [activeObjective, setActiveObjective] = useState('');
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const handleCategoryClick = (categoryId: string) => {
    nextStep();
    setActiveCategory(categoryId);
  };

  const handleObjectiveClick = (objectiveId: string) => {
    nextStep();
    setActiveObjective(objectiveId);
  };

  return (
    <Stepper
      active={active}
      onStepClick={setActive}
      breakpoint="sm"
      allowNextStepsSelect={false}
      contentPadding={0}
      className={classes.stepperWithoutSteps}
    >
      <Stepper.Step withIcon={false} key={0}>
        <CreateObjectiveFirstStep handleCategoryClick={handleCategoryClick} />
      </Stepper.Step>
      <Stepper.Step withIcon={false} key={1}>
        <CreateObjectiveSecondStep
          categoryId={activeCategory}
          handleObjectiveClick={handleObjectiveClick}
          prevStep={prevStep}
        />
      </Stepper.Step>
      <Stepper.Step withIcon={false} key={2}>
        <CreateObjectiveThirdStep
          prevStep={prevStep}
          categoryId={activeCategory}
          objectiveId={activeObjective}
        />
      </Stepper.Step>
    </Stepper>
  );
};
