import { Route, RouteObject } from 'react-router-dom';

const ProfilePage = () => <h1>Welcome - Admin Profile {process.env.REACT_APP_REST_URL}</h1>;
const DashboardPage = () => <h1>Welcome - Admin Dashboard</h1>;

const routeConfig: RouteObject[] = [
  {
    index: true,
    element: <DashboardPage />,
  },
  {
    path: '/admin/profile',
    element: <ProfilePage />,
  },
];

export const adminRoutes = routeConfig.map((route, index) => {
  // eslint-disable-next-line
  // @ts-ignore
  return <Route {...route} key={index} />;
});
