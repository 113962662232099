import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { useAuthContext } from '../../../providers';
import { Exception } from '../../../providers/authentication/service';
import { useGetEmailFromQueryParams } from '../../hooks';
import { passwordValidation } from '../../hooks/use-authentication-form';
import { useStyles } from '../../hooks/use-styles';

interface ResetPasswordFields {
  code: string;
  password: string;
  confirmPassword: string;
}

export const ResetPasswordPage = () => {
  const { resetPassword, initiateForgotPassword } = useAuthContext();
  const email = useGetEmailFromQueryParams();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { formState, watch, register, handleSubmit } = useForm<ResetPasswordFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        code: yup.string().required('Required field.').min(6, 'Code must contain 6 digits.'),
        password: passwordValidation,
        confirmPassword: yup
          .string()
          .required('Required field.')
          .oneOf([yup.ref('password')], 'Passwords must match.'),
      }),
    ),
  });

  const { mutate: mutateResetPassword, isLoading } = useMutation<
    void,
    Exception,
    { email: string; code: string; password: string }
  >({
    mutationFn: ({ email, code, password }) => resetPassword(email, code, password),
    onError: (error) => {
      notifications.show({
        message: error?.message || 'Something went wrong. Please, try again.',
      });
    },
  });

  const { mutate: mutateResendCode, isLoading: isResending } = useMutation<
    void,
    Exception,
    { email: string }
  >({
    mutationFn: ({ email }) => initiateForgotPassword(email),
    onError: (error) => {
      notifications.show({
        message: error?.message || 'Something went wrong. Please, try again.',
      });
    },
  });
  const fields = watch();

  return (
    <div className={classes.verticalSpaceContainer}>
      <div>
        <Title order={4} mb="xs">
          Reset Password
        </Title>
        <Text fz="md" c={theme.colors.dark[1]} mb="xl">
          Paste the code sent to your email address and choose a new password afterwards.
        </Text>
      </div>
      <div>
        <form
          onSubmit={handleSubmit((data) =>
            mutateResetPassword({
              email: email as string,
              code: data.code,
              password: data.password,
            }),
          )}
        >
          <TextInput
            size="lg"
            style={{ marginBottom: '30px' }}
            label="Code"
            value={fields.code}
            error={formState?.errors?.code?.message}
            {...register('code')}
          />
          <TextInput
            size="lg"
            style={{ marginBottom: '30px' }}
            id="password"
            type="password"
            label="New password"
            value={fields.password}
            error={formState?.errors?.password?.message}
            {...register('password')}
          />
          <TextInput
            size="lg"
            mb="xl"
            type="password"
            id="confirmPassword"
            label="Confirm password"
            value={fields.confirmPassword}
            error={formState?.errors?.confirmPassword?.message}
            {...register('confirmPassword')}
          />
          <Button
            size="lg"
            fullWidth
            variant="filled"
            type="submit"
            loading={isLoading}
            disabled={!formState.isValid || isLoading}
            style={{ marginTop: '36px', marginBottom: '30px' }}
          >
            Submit
          </Button>
          <Text fz="md" mt="xl" ta="center">
            Didn’t get the code?{' '}
            <span className={classes.linkLookAlike} onClick={() => mutateResendCode({ email })}>
              Click here to resend.
            </span>
          </Text>
        </form>
      </div>
    </div>
  );
};
