export enum CustomerRoutes {
  LOGIN = '/',
  REGISTER = '/register',
  CONFIRM_EMAIL = '/confirm-email',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  // authenticated routes (/customer/*)
  FINISH_ACCOUNT_CREATION_PERSONAL_INFO = '/customer/create-account/personal-info',
  FINISH_ACCOUNT_CREATION_ADDRESS = '/customer/create-account/address',
  FINISH_ACCOUNT_CREATION_FINANCIAL_DATA = '/customer/create-account/financial-data',
  // account creation finished routes (/customer/profile/*)
  QUESTIONNAIRE_START = '/customer/questionnaire-start',
  QUESTIONNAIRE = '/customer/questionnaire',
  SUBSCRIPTION = '/customer/subscription',
  // account + questionnaire finished
  PLAID_LINK_ACCOUNTS = '/customer/plaid/link-accounts',
  // plaid
  PROFILE = '/customer/profile',
  PROFILE_INFO_EDIT = '/customer/profile/info/edit',
  PROFILE_ADDRESS_EDIT = '/customer/profile/address/edit',
  PROFILE_FINANCIAL_DATA_EDIT = '/customer/profile/financial-data/edit',
  DASHBOARD = '/customer/dashboard',
  SETTINGS = '/customer/settings',
  NOTIFICATIONS = '/customer/notifications',
  NOTIFICATION_DETAIL = '/customer/notification-info',
  OBJECTIVES = '/customer/objectives',
  OBJECTIVE_DETAIL = '/customer/objective/info',
  OBJECTIVE_CREATE = '/customer/objective-create',
  OBJECTIVE_EDIT = '/customer/objective-edit',
  TASKS = '/customer/tasks',
  ACCOUNTS = '/customer/accounts',
  ACCOUNTS_INFO_ASSET = '/customer/accounts/info/asset',
  ACCOUNTS_INFO_LIABILITY = '/customer/accounts/info/liability',
  ACCOUNTS_EDIT_ASSET = '/customer/accounts/aset/edit',
  ACCOUNTS_EDIT_LIABILITY = '/customer/accounts/liability/edit',
  ACCOUNTS_CREATE = '/customer/accounts/create',
  ACCOUNTS_CREATE_LIABILITY = '/customer/accounts/liability/create',
  ACCOUNTS_CREATE_LIABILITY_FORM = '/customer/accounts/liability/create-form',
  ACCOUNTS_CREATE_FORM = '/customer/accounts/create-form',
  LIABILITIES_INFO = '/customer/accounts/info',
  EDUCATION = '/customer/education',
  REWARDS = '/customer/rewards',
  REWARDS_INFO = '/customer/rewards/info',
  REWARDS_REDEEM = '/customer/rewards/redeem',
  REWARDS_MANAGE_ADDRESS = '/customer/rewards/manage-address',
}
