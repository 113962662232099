import { createStyles } from '@mantine/core';

export const useAccordionListStyles = createStyles((theme) => ({
  accountName: {
    color: theme.white,
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '4px',
    '& > svg': {
      marginLeft: '7px',
    },
  },
}));
