import { Box, Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { BellIcon } from '../../../../../assets/icons/bell';
import { BellFilledIcon } from '../../../../../assets/icons/bell-filled';
import { CustomerRoutes } from '../../../../routes';
import { useNotificationListStyles } from './hooks/use-styles';

interface NotificationListItemProps {
  id: string;
  title: string;
  text: string;
  active: boolean;
}

export const NotificationListItem = ({ id, title, text, active }: NotificationListItemProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { classes } = useNotificationListStyles();
  const ActiveBellIcon = active ? BellFilledIcon : BellIcon;
  const buttonVariant = active ? 'filled' : 'outline';

  const handleViewClick = () => {
    navigate(`${CustomerRoutes.NOTIFICATION_DETAIL}/${id}`);
  };

  return (
    <Flex align="center" className={classes.listItem}>
      <Flex align="center">
        <Flex className={classes.icon}>
          <ActiveBellIcon />
        </Flex>
        <Box ml={20} mr={10}>
          <Text size="lg" fw={700} c={active ? theme.white : theme.colors.dark[1]} mb={4}>
            {title}
          </Text>
          <Text size="md" lh={1.4} fw={400} c={theme.colors.dark[2]}>
            {text}
          </Text>
        </Box>
      </Flex>
      <Button
        variant={buttonVariant}
        disabled={!active}
        lh={1}
        pl={19}
        pr={19}
        onClick={handleViewClick}
      >
        View
      </Button>
    </Flex>
  );
};
