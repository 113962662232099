import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../../providers';
import { PropsWithChildren } from 'react';

export const OnlyPublicGuard = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) return <Navigate to="/customer/profile" />;

  return <>{children}</>;
};
