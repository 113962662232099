import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';

export type RewardsData = RewardData[];

type RewardsResponse = {
  data: RewardsData;
};

type RewardData = {
  id: string;
  title: string;
  description: string;
  bonusPoints: number;
  photoUrl: string;
};

type RewardResponse = {
  data: RewardData;
};

type RewardDeliveryAddress = {
  addressLine1: string;
  city: string;
  contactPhone: string;
  postalCode: string;
  state: string;
};

export type RewardPayload = {
  deliveryAddress: RewardDeliveryAddress;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchRewards() {
  const { data } = await httpService.get<RewardsResponse>(`/rewards`);
  return data.data;
}

export function useGetRewards(
  options?: UseQueryOptions<
    RewardsData,
    Error,
    RewardsData,
    [REACT_QUERY_CACHE_KEYS_PROPS['rewards']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<RewardsData, Error, RewardsData, [REACT_QUERY_CACHE_KEYS_PROPS['rewards']]>(
    [REACT_QUERY_CACHE_KEYS.rewards],
    () => fetchRewards(),
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export async function fetchReward(rewardId: string | undefined) {
  const { data } = await httpService.get<RewardResponse>(`/rewards/${rewardId}`);
  return data.data;
}

export function useGetReward(
  assetId: string | undefined,
  options?: UseQueryOptions<
    RewardData,
    Error,
    RewardData,
    [REACT_QUERY_CACHE_KEYS_PROPS['reward'], string | undefined]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    RewardData,
    Error,
    RewardData,
    [REACT_QUERY_CACHE_KEYS_PROPS['reward'], string | undefined]
  >([REACT_QUERY_CACHE_KEYS.reward, assetId], () => fetchReward(assetId), {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function mutateRedeemReward(
  rewardId: string | undefined,
  rewardPayload: RewardPayload,
) {
  const { data } = await httpService.post<RewardPayload>(
    `/rewards/${rewardId}/reedem`,
    rewardPayload,
  );
  return data;
}
