import {
  Alert,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Loader,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CustomerRoutes } from '../../routes';
import { fetchUserObjectives } from '../../service/objectives';
import { fetchUserTasks } from '../../service/upcoming-tasks';

export const CustomerTasksPage = () => {
  const theme = useMantineTheme();
  const { data, isLoading, isError } = useQuery('userObjectives', fetchUserObjectives);
  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    isError: isErrorLoadingTasks,
  } = useQuery('userUpcomingTasks', fetchUserTasks, {
    enabled: Boolean(data?.length),
  });

  const renderUpcomingTasks = () => {
    if (isLoadingTasks || isLoading) {
      return (
        <Flex justify="center" mt={30}>
          <Loader />
        </Flex>
      );
    }
    if (isErrorLoadingTasks || isError) {
      return (
        <Alert title="Error" color="red" radius="md" mt={30}>
          Error fetching objective
        </Alert>
      );
    }

    if (tasksData) {
      const upcomingTasks = tasksData.tasks[Object.keys(tasksData.tasks)[0]];
      return (
        <Box my={26}>
          <Text fw={700} fz={18} color="#72729B" lh="21.6px">
            Upcoming Tasks
          </Text>
          {upcomingTasks.map((task, index) => (
            <Box
              p={25}
              mb="md"
              mt="16px"
              sx={{ borderRadius: theme.radius.md, border: '1px solid #343449' }}
              bg={theme.colors.dark[8]}
              key={task.objectiveId + index}
            >
              <Flex>
                <div
                  style={{
                    width: '3px',
                    backgroundColor: '#698AFF',
                    borderRadius: '3px',
                    marginRight: '10px',
                  }}
                ></div>
                <Box sx={{ flex: 1 }}>
                  <Text size="18px" c="#fff" fw={700}>
                    Contribute ${task.taskAmount}
                  </Text>
                  <Flex justify="space-between" align="end" mt="8px">
                    <Text size="14px" c={theme.colors.dark[3]}>
                      Transfer{' '}
                      <Text span c="#fff" inherit>
                        ${task.taskAmount}
                      </Text>{' '}
                      to{' '}
                      <Text span c="#fff" inherit>
                        {task.bankAccountName}.
                      </Text>
                    </Text>
                    <Badge
                      variant="outline"
                      size="lg"
                      radius="md"
                      color="orange"
                      sx={{ flexShrink: 0, padding: '5px 8px', fontSize: '12px' }}
                    >
                      {task.bonusPoints} BP
                    </Badge>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      );
    }
    return (
      <Center h="100%" sx={{ flexDirection: 'column' }}>
        <Text mb={30} fw={600}>
          No Objectives = No Tasks
        </Text>
        <Button variant="filled" component={Link} to={CustomerRoutes.OBJECTIVE_CREATE}>
          Create Your Objective
        </Button>
      </Center>
    );
  };

  return <Box h="100%">{renderUpcomingTasks()}</Box>;
};
