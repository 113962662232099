import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { REACT_QUERY_CACHE_KEYS } from '../../../constants/react-query-cache-keys';
import { liabilitySchema } from '../../../validation-schemas/liabilities';
import { liabilitiesNames } from '../../components/accounts/components/liabilities';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { CustomerRoutes } from '../../routes';
import { LiabilityPayload, mutateLiability } from '../../service/liabilities';

export interface LiabilityFormFields {
  accountName: string;
  amount: number;
  monthlyDueDate: number;
  interestRate: number;
}

export const CreateAccountFormPageLiability = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { liabilityType } = useParams();
  const onBackClick = () => navigate(CustomerRoutes.ACCOUNTS_CREATE_LIABILITY);

  const { mutate, isLoading } = useMutation({
    mutationFn: (liabilityPayload: LiabilityPayload) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      mutateLiability(liabilityPayload),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.liabilities);
      notifications.show({
        message: 'Successfully created account.',
      });
      setTimeout(() => {
        navigate(CustomerRoutes.ACCOUNTS);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { formState, watch, register, handleSubmit } = useForm<LiabilityFormFields>({
    mode: 'onChange',
    resolver: yupResolver(liabilitySchema),
  });

  const { accountName, amount, monthlyDueDate, interestRate } = watch();

  return (
    <Box pt={30}>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Add New Liability"
        ctaText="Cancel"
        handleCtaClick={onBackClick}
      />
      <Box>
        <form
          onSubmit={handleSubmit(({ accountName, amount, monthlyDueDate, interestRate }) => {
            mutate({
              amount: amount,
              monthlyDueDate: monthlyDueDate,
              interestRate: interestRate,
              liabilityType: liabilityType ? liabilityType.toUpperCase() : 'PERSONAL_LOAN',
              name: accountName,
            });
          })}
        >
          <Flex gap="md">
            <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
              <TextInput
                mb="sm"
                size="lg"
                style={{ width: '100%' }}
                label={`${
                  liabilityType ? liabilitiesNames[liabilityType.toUpperCase()] : 'Liability'
                } name`}
                value={accountName}
                {...register('accountName')}
                error={formState.errors?.accountName?.message}
              />
            </Flex>
            <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
              <TextInput
                size="lg"
                style={{ width: '100%' }}
                label="Amount"
                value={amount}
                {...register('amount')}
                error={formState.errors?.amount?.message}
              />
            </Flex>
          </Flex>
          <Flex gap="md">
            <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
              <TextInput
                size="lg"
                style={{ width: '100%' }}
                label="Due Date"
                value={monthlyDueDate}
                {...register('monthlyDueDate')}
                error={formState.errors?.monthlyDueDate?.message}
              />
            </Flex>
            <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
              <TextInput
                size="lg"
                style={{ width: '100%' }}
                label="Interest Rate"
                value={interestRate}
                min={0}
                {...register('interestRate')}
                error={formState.errors?.interestRate?.message}
              />
            </Flex>
          </Flex>
          <Button
            mt={40}
            size="lg"
            fullWidth
            type="submit"
            variant="filled"
            disabled={!formState.isValid || isLoading}
            loading={isLoading}
          >
            Add Libility
          </Button>
        </form>
      </Box>
    </Box>
  );
};
