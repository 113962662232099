export const LogoutIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.333334 10.6671V10.6667V1.33333C0.333334 1.05586 0.428323 0.826417 0.627703 0.627037C0.827071 0.427669 1.05616 0.332998 1.33293 0.333334H1.33333H5.66667V1H1.33333H1V1.33333V10.6667V11H1.33333H5.66667V11.6667H1.33333C1.05586 11.6667 0.826417 11.5717 0.627037 11.3723C0.427669 11.1729 0.332998 10.9438 0.333334 10.6671ZM9.45 6.33333H4.33333V5.66667H9.45H10.2547L9.6857 5.09763L8.21523 3.62716L8.67301 3.14441L11.5286 6L8.67301 8.85559L8.21523 8.37284L9.6857 6.90237L10.2547 6.33333H9.45Z"
        fill="#72729B"
        stroke="#8F8FAF"
        strokeWidth="0.666667"
      />
    </svg>
  );
};
