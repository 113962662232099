export interface REACT_QUERY_CACHE_KEYS_PROPS {
  userProfile: 'userProfile';
  userObjectives: 'userObjectives';
  userProfilePhotoUploadUrl: 'userProfilePhotoUploadUrl';
  objectivesByCategory: 'objectivesByCategory';
  unassignedBankAccounts: 'unassignedBankAccounts';
  asset: 'asset';
  assets: 'assets';
  categories: 'categories';
  linkToken: 'linkToken';
  exchangeTokens: 'exchangeTokens';
  questionnaire: 'questionnaire';
  rewards: 'rewards';
  reward: 'reward';
  liabilities: 'liabilities';
}

export const REACT_QUERY_CACHE_KEYS: REACT_QUERY_CACHE_KEYS_PROPS = {
  userProfile: 'userProfile',
  userObjectives: 'userObjectives',
  objectivesByCategory: 'objectivesByCategory',
  unassignedBankAccounts: 'unassignedBankAccounts',
  asset: 'asset',
  assets: 'assets',
  categories: 'categories',
  linkToken: 'linkToken',
  exchangeTokens: 'exchangeTokens',
  questionnaire: 'questionnaire',
  rewards: 'rewards',
  reward: 'reward',
  userProfilePhotoUploadUrl: 'userProfilePhotoUploadUrl',
  liabilities: 'liabilities',
};
