import { createContext, ReactNode, useContext } from 'react';
import { UserProfileData, useGetUserProfile } from './data';
import { UseQueryResult } from 'react-query';

type UserProfileContextType = {
  userProfile: UserProfileData | undefined;
  refetchUserProfile: UseQueryResult['refetch'];
};

const UserProfileContext = createContext<UserProfileContextType | null>(null);

interface UserProfileProviderProps {
  children: ReactNode;
}

export function UserProfileProvider({ children }: UserProfileProviderProps) {
  const { data, isLoading, isError, refetch } = useGetUserProfile();

  if (isLoading) return null;

  if (isError) {
    return <div>Error fetching user profile</div>;
  }

  const value = {
    userProfile: data,
    refetchUserProfile: refetch,
  };

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
}
export const useUserProfileContext = () => {
  const ctx = useContext(UserProfileContext);
  if (!ctx) {
    throw new Error('useUserProfileContext must be wrapped inside the provider');
  }

  return ctx;
};
