import { Checkbox, Text, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import React from 'react';
import { useCheckboxCardStyles } from './hooks/use-styles';

interface CheckboxCardProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?(checked: boolean): void;
  title: React.ReactNode;
  description?: React.ReactNode;
  descriptionArray?: string[];
}

export function CheckboxCard({
  checked,
  defaultChecked,
  onChange,
  title,
  description,
  descriptionArray,
  className,
  ...others
}: CheckboxCardProps & Omit<UnstyledButtonProps, keyof CheckboxCardProps>) {
  const { classes, cx } = useCheckboxCardStyles();

  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  });

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(!value)}
      className={cx(classes.button, className)}
    >
      <div>
        <Text size="xl" fw={700} mb="md">
          {title}
        </Text>
        <Text fz="md" sx={(theme) => ({ color: theme.colors.dark[1] })}>
          {description}
          {descriptionArray?.map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </Text>
      </div>
      <Checkbox
        checked={value}
        tabIndex={-1}
        onChange={() => null}
        size="md"
        ml="sm"
        styles={{ input: { cursor: 'pointer' } }}
      />
    </UnstyledButton>
  );
}
