import { Accordion, Alert, Box, Button, Flex, Loader, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AccordionChevronIcon } from '../../../../../assets/icons/accordion-chevron';
import { USDollar } from '../../../../../helpers/formatter';
import { CustomerRoutes } from '../../../../routes';
import { LiabilityData, useGetLiabilities } from '../../../../service/liabilities';
import { AccountAccordionListItem } from '../accordion-list-item';

export const liabilitiesNames: { [key: string]: string } = {
  MORTGAGE: 'Mortgage',
  AUTO_LOAN: 'Auto Loan',
  CREDIT_CARD: 'Credit Card',
  PERSONAL_LOAN: 'Personal Loan',
  STUDENT_LOAN: 'Student Loan',
};
export const Liabilities = () => {
  const { data: liabilitiesData, isError, isLoading } = useGetLiabilities();
  const categorizedLiabilities: { [key: string]: LiabilityData[] } = {};
  liabilitiesData &&
    liabilitiesData.forEach((liability) => {
      const { liabilityType } = liability;
      if (!categorizedLiabilities[liabilityType]) {
        categorizedLiabilities[liabilityType] = [];
      }
      categorizedLiabilities[liabilityType].push(liability);
    });
  const isLiabilitiesDataEmpty =
    categorizedLiabilities &&
    Object.keys(categorizedLiabilities).every(function (key) {
      // eslint-disable-next-line
      // @ts-ignore
      return categorizedLiabilities[key].length === 0;
    });

  if (isLoading)
    return (
      <Flex justify="center">
        <Loader />
      </Flex>
    );

  if (isError) {
    return (
      <Alert title="Error" color="red" radius="md">
        Error fetching liabilities
      </Alert>
    );
  }

  if (isLiabilitiesDataEmpty)
    return (
      <Flex
        justify="center"
        align="center"
        sx={() => ({
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-45%)',
          left: 0,
          width: '100%',
        })}
      >
        <Box ta="center">
          <Text fw={600}>No Liabilities Connected</Text>
          <Link to={CustomerRoutes.ACCOUNTS_CREATE_LIABILITY} style={{ textDecoration: 'none' }}>
            <Button mt={32} size="md">
              Add New Liability
            </Button>
          </Link>
        </Box>
      </Flex>
    );

  return (
    <>
      <Accordion
        radius="md"
        variant="separated"
        chevron={<AccordionChevronIcon />}
        chevronPosition="left"
      >
        {Object.keys(categorizedLiabilities).map((liabilityType, index) => {
          const totalBalance = categorizedLiabilities[liabilityType].reduce(function (prev, cur) {
            return prev + cur.amount;
          }, 0);
          return (
            <Accordion.Item value={liabilitiesNames[liabilityType]} key={index}>
              <Accordion.Control>
                {liabilitiesNames[liabilityType]}
                <span className="amount">{USDollar.format(totalBalance)}</span>
              </Accordion.Control>
              <Accordion.Panel>
                {categorizedLiabilities[liabilityType].map((item, itemIndex) => {
                  return (
                    <AccountAccordionListItem
                      accountUrl={`${CustomerRoutes.ACCOUNTS_INFO_LIABILITY}/${item.id}`}
                      accountName={item.name}
                      accountAmount={item.amount.toString()}
                      key={itemIndex}
                    />
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Link to={CustomerRoutes.ACCOUNTS_CREATE_LIABILITY} style={{ textDecoration: 'none' }}>
        <Button mt={32} size="lg" fullWidth variant="outline">
          New Liability
        </Button>
      </Link>
    </>
  );
};
