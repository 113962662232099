import { Box } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { HeaderBackAndCta } from '../../components/header-back-and-cta';
import { NotificationListItem } from '../../components/notifications';
import { CustomerRoutes } from '../../routes';

export const CustomerNotificationsPage = () => {
  const navigate = useNavigate();
  const onBackClick = () => navigate(CustomerRoutes.DASHBOARD);
  return (
    <>
      <HeaderBackAndCta
        handleBackClick={onBackClick}
        backText="Dashboard"
        ctaText="Done"
        handleCtaClick={onBackClick}
      />
      <Box pt={32}>
        <NotificationListItem
          id="1"
          title="Sennheiser Headphones"
          text="Sport Headphones with 3.5mm jack. Superior Sound Quality"
          active={true}
        />
        <NotificationListItem
          id="2"
          title="Sennheiser Headphones"
          text="Sport Headphones with 3.5mm jack. Superior Sound Quality"
          active={false}
        />
      </Box>
    </>
  );
};
