import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';

type QuestionnaireDataAnswer = {
  letter: string;
  text: string;
  points: number;
};

export type QuestionnaireData = {
  id: string;
  title: string;
  question: string;
  order: number;
  answers: {
    A: QuestionnaireDataAnswer;
    B: QuestionnaireDataAnswer;
    C: QuestionnaireDataAnswer;
    D: QuestionnaireDataAnswer;
  };
}[];

type QuestionnaireResponse = {
  data: QuestionnaireData;
};

export type QuestionnairePayload = {
  [key: string]: string;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchQuestionnaire() {
  const { data } = await httpService.get<QuestionnaireResponse>('/questionnaire');
  return data.data;
}

export async function mutateQuestionnaire(questionnairePayload: QuestionnairePayload) {
  const { data } = await httpService.post<QuestionnairePayload>(
    '/questionnaire',
    questionnairePayload,
  );
  return data.data;
}

export function useGetQuestionnaire(
  options?: UseQueryOptions<
    QuestionnaireData,
    Error,
    QuestionnaireData,
    [REACT_QUERY_CACHE_KEYS_PROPS['questionnaire']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    QuestionnaireData,
    Error,
    QuestionnaireData,
    [REACT_QUERY_CACHE_KEYS_PROPS['questionnaire']]
  >([REACT_QUERY_CACHE_KEYS.questionnaire], fetchQuestionnaire, {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}
