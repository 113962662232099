import { currencySymbolMap } from './currency-symbol-map';

export const formatBirthDate = (birthDate: Date) => {
  const year = birthDate.getFullYear();
  const month =
    birthDate.getMonth() < 9 ? `0${birthDate.getMonth() + 1}` : birthDate.getMonth() + 1;
  const day = birthDate.getDate() <= 9 ? `0${birthDate.getDate()}` : birthDate.getDate();

  return `${year}-${month}-${day}`;
};

export const getSymbolFromCurrency = (currencyCode: string) => {
  const code = currencyCode.toUpperCase();

  if (!Object.prototype.hasOwnProperty.call(currencySymbolMap, code)) {
    return undefined;
  }

  return currencySymbolMap[code];
};
