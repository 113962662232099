import {
  AccordionStylesParams,
  ButtonStylesParams,
  CheckboxStylesNames,
  ContainerStylesParams,
  DividerStylesNames,
  InputStylesNames,
  MantineTheme,
  MantineThemeOverride,
  NotificationStylesNames,
  PinInputStylesNames,
  PopoverStylesNames,
  TextInputStylesNames,
} from '@mantine/core';
import { TabStylesNames } from '@mantine/core/lib/Tabs/Tab/Tab';
import { DatePickerInputStylesNames } from '@mantine/dates';

type ComponentSize = string | number | undefined;

const commonInputStyles = (
  theme: MantineTheme,
  size: ComponentSize,
  customSize: ComponentSize,
) => ({
  background: theme.colors.dark[8],
  borderColor: theme.colors.dark[7],
  fontSize: size === customSize ? '0.875rem' : undefined,
  letterSpacing: size === customSize ? '0.2px' : undefined,
  fontWeight: size === customSize ? 500 : undefined,
  borderRadius: size === customSize ? '12px' : undefined,
  '&:focus': {
    background: theme.colors.dark[7],
    borderColor: theme.colors.blue[4],
    fontSize: '1rem',
  },
});

const themeSettings: MantineThemeOverride = {
  colorScheme: 'dark',
  fontFamily: 'SFProDisplay, sans-serif',
  primaryColor: 'blue',
  primaryShade: { dark: 3 },
  globalStyles: (theme: MantineTheme) => ({
    body: {
      backgroundColor: theme.colors.dark[9],
    },
    h4: {
      letterSpacing: '0.3px',
    },
  }),
  colors: {
    dark: [
      '#E9E9FE',
      '#8F8FAF',
      '#72729B',
      '#5C5C81',
      '#484865',
      '#191E31',
      '#343449',
      '#2C2C3E',
      '#1C1C27',
      '#14141C', // Body bg
    ],
    red: [
      '#FFF5F5',
      '#FFE3E3',
      '#FFC9C9',
      '#FFA8A8',
      '#F75555', // Error
      '#FA5C57', // Our red
      '#FA5252',
      '#F03E3E',
      '#E03131',
      '#C92A2A',
    ],
    green: [
      '#EBFBEE',
      '#D3F9D8',
      '#B2F2BB',
      '#8CE99A', // Our green
      '#69DB7C',
      '#51CF66',
      '#22C55E', // Success
      '#37B24D',
      '#2F9E44',
      '#2B8A3E',
    ],
    blue: [
      '#B7C7FF',
      '#94ABFF',
      '#839EFF',
      '#7594FF',
      '#698AFF', // Our primary color
      '#339AF0',
      '#228BE6',
      '#1C7ED6',
      '#1971C2',
      '#1864AB',
    ],
    yellow: [
      '#FFF4E6',
      '#FFE8CC',
      '#FFD8A8',
      '#FFC078',
      '#FFA94D',
      '#FACC15', // Warning
      '#FD7E14',
      '#F76707',
      '#E8590C',
      '#D9480F',
    ],
    orange: [
      '#FFD4B4',
      '#FFC091',
      '#FFB47D',
      '#FC9244',
      '#FFA562', // Our secondary color
      '#FFA562',
      '#FD7E14',
      '#F76707',
      '#E8590C',
      '#D9480F',
    ],
  },
  headings: {
    sizes: {
      h1: { fontSize: '3rem', fontWeight: 700, lineHeight: '3.3rem' },
      h2: { fontSize: '2.5rem', fontWeight: 700, lineHeight: '2.8rem' },
      h3: { fontSize: '2rem', fontWeight: 700, lineHeight: '2.2rem' },
      h4: { fontSize: '1.5rem', fontWeight: 700, lineHeight: '1.8rem' },
      h5: { fontSize: '1.3rem', fontWeight: 700, lineHeight: '1.5rem' },
      h6: { fontSize: '1.125rem', fontWeight: 700, lineHeight: '1.38rem' },
    },
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
  },
  components: {
    Title: {
      styles: (theme) => ({
        root: {
          color: theme.white,
        },
      }),
    },
    Text: {
      styles: (theme) => ({
        root: {
          letterSpacing: '0.2px',
        },
      }),
    },
    Button: {
      styles: (theme, params: ButtonStylesParams, { variant, size }) => ({
        root:
          variant === 'social'
            ? {
                padding: '14px 20px',
                height: '52px',
                borderRadius: '130px',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '140%',
                letterSpacing: '0.2px',
                color: '#FFFFFF',
                backgroundColor: '#1C1C27',
                '& svg': {
                  height: '24px',
                  marginTop: '-2px',
                },
              }
            : variant === 'subtle'
            ? {
                padding: '0',
                height: 'auto',
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: '1.5rem',
                color: theme.colors.blue[4],
                '&:hover': {
                  background: 'transparent',
                },
              }
            : {
                color: variant === 'outline' ? theme.colors.blue[4] : theme.white,
                backgroundColor:
                  variant === 'filled'
                    ? theme.colors.blue[4]
                    : variant === 'outline'
                    ? theme.colors.dark[5]
                    : undefined,
                borderColor: variant === 'outline' ? theme.colors.blue[4] : undefined,
                '&:disabled': {
                  backgroundColor: variant === 'filled' ? theme.colors.dark[4] : 'transparent',
                  borderColor: theme.colors.dark[4],
                  color: theme.colors.dark[1],
                  '& svg': {
                    fill: theme.colors.dark[1],
                    '& path': {
                      fill: theme.colors.dark[1],
                    },
                  },
                },
                '& svg': {
                  width: size === 'lg' ? '18px' : '12px',
                  fill: variant === 'filled' ? theme.white : undefined,
                  '& path': {
                    fill: theme.white,
                  },
                },
                borderRadius: '8px',
                padding:
                  size === 'sm'
                    ? '0 12px'
                    : size === 'md'
                    ? '12px 16px'
                    : size === 'lg'
                    ? '16px'
                    : undefined,
                height:
                  size === 'sm'
                    ? '36px'
                    : size === 'md'
                    ? '44px'
                    : size === 'lg'
                    ? '56px'
                    : undefined,
                fontSize:
                  size === 'sm'
                    ? '0.75rem'
                    : size === 'md'
                    ? '0.875rem'
                    : size === 'lg'
                    ? '1rem'
                    : undefined,
                lineHeight:
                  size === 'sm'
                    ? '1.2rem'
                    : size === 'md'
                    ? '1.5rem'
                    : size === 'lg'
                    ? '1.5rem'
                    : undefined,
              },
      }),
    },
    TextInput: {
      styles: (theme, params: TextInputStylesNames, { size }) => ({
        input: { ...commonInputStyles(theme, size, 'lg'), marginBottom: '16px' },
        label: {
          fontSize: size === 'lg' ? '0.875rem' : undefined,
          fontWeight: size === 'lg' ? 400 : undefined,
          letterSpacing: '0.2px',
          color: theme.colors.dark[1],
          marginBottom: '4px',
        },
      }),
    },
    Input: {
      styles: (theme, params: InputStylesNames, { size }) => ({
        input: { ...commonInputStyles(theme, size, 'lg'), marginBottom: '16px' },
      }),
    },
    InputWrapper: {
      styles: (theme, params: InputStylesNames, { size }) => ({
        label: {
          fontSize: '0.875rem',
          fontWeight: 400,
          letterSpacing: '0.2px',
          color: theme.colors.dark[1],
          marginBottom: '4px',
        },
        error: {
          fontSize: '0.875rem',
        },
      }),
    },
    PinInput: {
      styles: (theme, params: PinInputStylesNames, { size }) => ({
        input: { ...commonInputStyles(theme, size, 'xl'), height: '56px' },
        root: {
          gap: size === 'xl' ? '0.375rem' : undefined,
        },
      }),
    },
    DatePickerInput: {
      styles: (theme, params: DatePickerInputStylesNames, { size }) => ({
        input: commonInputStyles(theme, size, 'lg'),
        label: {
          fontSize: size === 'lg' ? '0.875rem' : undefined,
          fontWeight: size === 'lg' ? 400 : undefined,
          letterSpacing: '0.2px',
          color: theme.white,
          marginBottom: '4px',
        },
        icon: {
          '& svg': {
            width: size === 'lg' ? '1rem' : undefined,
            marginTop: size === 'lg' ? '-2px' : undefined,
          },
        },
        calendar: {
          background: theme.colors.dark[8],
          padding: '0 11px 13px',
          border: '0',
        },
      }),
    },
    Popover: {
      styles: (theme, params: PopoverStylesNames, { size }) => ({
        dropdown: {
          background: theme.colors.dark[8],
          border: '0',
          borderRadius: '8px',
        },
      }),
    },
    Notification: {
      styles: (theme, params: NotificationStylesNames, { size }) => ({
        title: { color: theme.white, fontSize: '1.2rem' },
        description: { color: theme.colors.gray[4], fontSize: '0.8rem' },
      }),
    },
    Container: {
      styles: (theme, params: ContainerStylesParams, { size }) => ({
        root: {
          padding: size === 'xs' ? '1.5rem' : undefined,
          paddingTop: 0,
          maxWidth: size === 'xs' ? '100%' : undefined,

          [theme.fn.largerThan('md')]: {
            width: '375px',
          },
        },
      }),
    },
    Divider: {
      styles: (theme, params: DividerStylesNames, { size }) => ({
        label: {
          color: theme.colors.dark[1],
          fontSize: '0.875rem',
        },
      }),
    },
    Progress: {
      styles: (theme, params: DividerStylesNames, { size }) => ({
        root:
          size === 'sm'
            ? {
                height: '4px',
              }
            : {},
      }),
    },
    Menu: {
      styles: (theme, params: DividerStylesNames, { size }) => ({
        dropdown: {
          padding: '8px!important',
          width: '182px!important',
        },
        item: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        itemLabel: {
          fontSize: '16px',
          color: '#fff',
          fontWeight: 500,

          a: {
            fontSize: '16px',
            color: '#fff',
            fontWeight: 500,
            textDecoration: 'none',

            '&:hover': {
              color: '#fff',
            },
          },
        },
      }),
    },
    Checkbox: {
      styles: (theme, params: CheckboxStylesNames, { size }) => ({
        inner: size === 'xl' ? { height: '42px', width: '42px' } : {},
        input:
          size === 'xl'
            ? {
                height: '42px',
                width: '42px',
                borderRadius: '4px',
                background: theme.colors.dark[8],
                borderColor: theme.colors.dark[7],
                '&:checked': {
                  background: theme.colors.blue[4],
                  borderColor: theme.colors.blue[2],
                },
              }
            : {},
        label:
          size === 'xl'
            ? {
                paddingLeft: '16px',
                lineHeight: '1.4rem',
                fontWeight: 600,
                fontSize: '1rem',
              }
            : {
                color: theme.white,
              },
        labelWrapper:
          size === 'xl'
            ? {
                justifyContent: 'center',
              }
            : {},
      }),
    },
    Tabs: {
      styles: (theme, params: TabStylesNames, { size }) => ({
        tabsList: {
          backgroundColor: theme.colors.dark[8],
          padding: '4px',
          borderRadius: '12px',
        },
        tab: {
          color: theme.colors.dark[2],
          '&[data-active]': {
            backgroundColor: theme.colors.dark[4],
            color: theme.white,
          },
        },
        tabLabel: {
          fontSize: '0.875rem',
          fontWeight: 600,
        },
      }),
    },
    Accordion: {
      styles: (theme, params: AccordionStylesParams, { size }) => ({
        item: {
          backgroundColor: theme.colors.dark[9],
          borderColor: theme.colors.dark[7],
          borderRadius: params.radius === 'md' ? '10px' : undefined,
          marginTop: '8px !important',
          '&:first-of-type': {
            marginTop: '0 !important',
          },
          '&[data-active]': {
            backgroundColor: theme.colors.dark[8],
            borderColor: theme.colors.dark[4],
            '& .mantine-Accordion-label': {
              color: theme.white,
              '& > span': {
                color: theme.white,
                backgroundColor: theme.colors.dark[7],
              },
            },
          },
        },
        control: {
          padding: '0 16px',
        },
        content: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        label: {
          fontSize: '1.125rem',
          color: theme.colors.dark[0],
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '25px 0',
          '& > span': {
            display: 'flex',
            fontSize: '0.75rem',
            fontWeight: 600,
            color: theme.colors.dark[1],
            padding: '9px 8px 7px',
            borderRadius: '54px',
            backgroundColor: theme.colors.dark[8],
          },
        },
        chevron: {
          marginRight: '2px',
          '&[data-rotate] svg > g > path': {
            stroke: theme.white,
          },
        },
      }),
    },
    NavLink: {
      styles: (theme, params: TabStylesNames, { size }) => ({
        root: {
          padding: '8px 0',
          marginBottom: '8px',
        },
        body: {
          display: 'inline-flex',
          alignItems: 'center',
        },
        label: {
          color: theme.white,
          fontWeight: 600,
          fontSize: '1rem',
        },
      }),
    },
  },
};

export default themeSettings;
