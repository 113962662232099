import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

export interface Exception {
  message: string;
}

export interface NotAuthorizedException extends Exception {
  code: 'NotAuthorizedException';
}

export interface InvalidPasswordException extends Exception {
  code: 'InvalidPasswordException';
}

export interface CodeMismatchException extends Exception {
  code: 'CodeMismatchException';
}

interface CognitoUser {
  username: string;
  attributes: {
    sub: string;
    email: string;
    given_name?: string;
    family_name?: string;
    birthdate?: string;
    'custom:city'?: string;
    'custom:state'?: string;
    'custom:zipCode'?: string;
    'custom:phoneNumber'?: string;
    'custom:primaryAddress'?: string;
    'custom:secondaryAddress'?: string;
    'custom:accountDone'?: string;
    email_verified: boolean;
  };
  // only Google & FB have this JSON field
  identities?: string;
}

export interface User {
  username: string;
  attributes: {
    sub: string;
    email: string;
    given_name?: string;
    family_name?: string;
    birthdate?: string;
    picture?: string;
    'custom:city'?: string;
    'custom:state'?: string;
    'custom:zipCode'?: string;
    'custom:phoneNumber'?: string;
    'custom:primaryAddress'?: string;
    'custom:secondaryAddress'?: string;
    'custom:accountDone'?: string;
    email_verified: boolean;
    // todo :: resto
  };
  // only Google & FB have this JSON field
  identities?: string;
}

export interface AuthServiceMethods {
  signUp: (username: string, password: string) => Promise<void>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  resendVerificationCode: (username: string) => Promise<void>;
  initiateForgotPassword: (username: string) => Promise<void>;
  resetPassword: (username: string, code: string, password: string) => Promise<void>;
  signIn: (username: string, password: string) => Promise<void>;
  federatedSignIn: (provider: CognitoHostedUIIdentityProvider) => Promise<void>;
  updateUserAttributes: (userAttributes: Partial<User['attributes']>) => Promise<void>;
  signOut: () => Promise<void>;
}

export const signUp = async (username: string, password: string) => {
  try {
    await Auth.signUp({
      username,
      password,
    });
  } catch (error) {
    console.log('Error signing up:', error);
    throw error;
  }
};

export const updateUserAttributes = async (attributes: Partial<User['attributes']>) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
  } catch (error) {
    console.log('Error updating attributes:', error);
    throw error;
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.log('Error confirmation:', error);
    throw error;
  }
};

export const resendVerificationCode = async (username: string) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    console.log('Error resending email:', error);
    throw error;
  }
};

export const initiateForgotPassword = async (username: string) => {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.log('Error sending forgot-password email:', error);
    throw error;
  }
};

export const resetPassword = async (username: string, code: string, password: string) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
  } catch (error) {
    console.log('Error sending forgot-password email:', error);
    throw error;
  }
};

export const signIn = async (username: string, password: string) => {
  try {
    await Auth.signIn(username, password);
  } catch (error) {
    console.log('Error signing in:', error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('Error signing out:', error);
    throw error;
  }
};

export const federatedSignIn = async (provider: CognitoHostedUIIdentityProvider) => {
  try {
    await Auth.federatedSignIn({ provider });
  } catch (error) {
    console.log('Error federated sign in:', error);
    throw error;
  }
};

export const authenticatedUserToUserInfo = (user: CognitoUser): User => {
  return {
    username: user.username,
    attributes: user.attributes,
  };
};

export async function getAuthenticatedUserInfo(): Promise<User | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return authenticatedUserToUserInfo(user);
  } catch (error) {
    console.log('Error retrieving user info:', error);

    return null;
  }
}

export const getIsAccountCreationFinished = async () => {
  const userInfo = await getAuthenticatedUserInfo();
  console.log('INFO', userInfo);
  return Number(userInfo?.attributes['custom:accountDone'] ?? 0);
};
