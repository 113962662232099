import * as yup from 'yup';
export const liabilitySchema = yup.object().shape({
  accountName: yup
    .string()
    .required('Required field.')
    .matches(/^[A-Za-z].*/, 'Account name must start with a letter'),
  amount: yup
    .number()
    .typeError('The value must be a number')
    .required('Required field.')
    .positive(),
  monthlyDueDate: yup
    .number()
    .typeError('The value must be a number')
    .integer('Day must be an integer')
    .required('Day is required')
    .min(1, 'Day must be at least 1')
    .max(31, 'Day must be at most 31'),
  interestRate: yup
    .number()
    .typeError('The value must be a number')
    .required('Required field.')
    .positive(),
});
