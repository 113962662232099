import { Box, Flex, Text } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { AccountsIcon } from '../../../assets/icons/accounts';
import { AccountsFilledIcon } from '../../../assets/icons/accounts-filled';
import { EducationIcon } from '../../../assets/icons/education';
import { EducationFilledIcon } from '../../../assets/icons/education-filled';
import { HomeIcon } from '../../../assets/icons/home';
import { HomeFilledIcon } from '../../../assets/icons/home-filled';
import { RewardsIcon } from '../../../assets/icons/rewards';
import { RewardsFilledIcon } from '../../../assets/icons/rewards-filled';
import { TasksIcon } from '../../../assets/icons/tasks';
import { TasksFilledIcon } from '../../../assets/icons/tasks-filled';
import { CustomerRoutes } from '../../routes';
import { useBottomNavigationStyles } from './hooks/use-styles';

export const BottomNavigation = () => {
  const { classes } = useBottomNavigationStyles();
  const { pathname } = useLocation();
  const isDashboardActive = pathname === CustomerRoutes.DASHBOARD;
  const isTasksActive = pathname === CustomerRoutes.TASKS;
  const isAccountsActive = pathname === CustomerRoutes.ACCOUNTS;
  const isEducationActive = pathname === CustomerRoutes.EDUCATION;
  const isRewardsActive = pathname === CustomerRoutes.REWARDS;
  const isTasksNotificationActive = false;

  return (
    <Flex
      align="center"
      p="8px 24px 12px"
      justify="space-between"
      sx={(theme) => ({
        height: '56px',
        backgroundColor: theme.colors.dark[8],
      })}
    >
      <Link to={CustomerRoutes.DASHBOARD} style={{ textDecoration: 'none' }}>
        <Box
          ta="center"
          className={
            isDashboardActive
              ? classes.bottomNavigationIconWrapperActive
              : classes.bottomNavigationIconWrapper
          }
        >
          {isDashboardActive ? <HomeFilledIcon /> : <HomeIcon />}
          <Text className={classes.bottomNavigationText}>Home</Text>
        </Box>
      </Link>
      <Link to={CustomerRoutes.TASKS} style={{ textDecoration: 'none' }}>
        <Box
          ta="center"
          className={
            isTasksActive
              ? classes.bottomNavigationIconWrapperActive
              : classes.bottomNavigationIconWrapper
          }
        >
          {isTasksNotificationActive ? (
            <div className={classes.bottomNavigationNotification}>2</div>
          ) : null}
          {isTasksActive ? <TasksFilledIcon /> : <TasksIcon />}
          <Text className={classes.bottomNavigationText}>Tasks</Text>
        </Box>
      </Link>
      <Link to={CustomerRoutes.ACCOUNTS} style={{ textDecoration: 'none' }}>
        <Box
          ta="center"
          className={
            isAccountsActive
              ? classes.bottomNavigationIconWrapperActive
              : classes.bottomNavigationIconWrapper
          }
        >
          {isAccountsActive ? <AccountsFilledIcon /> : <AccountsIcon />}
          <Text className={classes.bottomNavigationText}>Accounts</Text>
        </Box>
      </Link>
      <Link to={CustomerRoutes.EDUCATION} style={{ textDecoration: 'none' }}>
        <Box
          ta="center"
          className={
            isEducationActive
              ? classes.bottomNavigationIconWrapperActive
              : classes.bottomNavigationIconWrapper
          }
        >
          {isEducationActive ? <EducationFilledIcon /> : <EducationIcon />}
          <Text className={classes.bottomNavigationText}>Education</Text>
        </Box>
      </Link>
      <Link to={CustomerRoutes.REWARDS} style={{ textDecoration: 'none' }}>
        <Box
          ta="center"
          className={
            isRewardsActive
              ? classes.bottomNavigationIconWrapperActive
              : classes.bottomNavigationIconWrapper
          }
        >
          {isRewardsActive ? <RewardsFilledIcon /> : <RewardsIcon />}
          <Text className={classes.bottomNavigationText}>Rewards</Text>
        </Box>
      </Link>
    </Flex>
  );
};
