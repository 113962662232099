import { PinInput, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useAuthContext } from '../../../providers';
import { CodeMismatchException } from '../../../providers/authentication/service';
import { useGetEmailFromQueryParams } from '../../hooks';
import { useStyles } from '../../hooks/use-styles';

interface ConfirmEmailFields {
  code: string;
  email: string;
}

export const ConfirmEmailPage = () => {
  const { confirmSignUp, resendVerificationCode } = useAuthContext();
  const [code, setCode] = useState('');
  const email = useGetEmailFromQueryParams();
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const { mutate: mutateConfirmEmail, isLoading: isConfirmLoading } = useMutation<
    void,
    CodeMismatchException,
    ConfirmEmailFields
  >({
    mutationFn: ({ email, code }) => confirmSignUp(email, code),
    onError: (error) => {
      if (error.code === 'CodeMismatchException') {
        notifications.show({
          message: error.message,
        });
      } else {
        notifications.show({
          message: 'Something went wrong. Please, try again.',
        });
      }
    },
  });

  const { mutate: mutateResendCode } = useMutation<void, void, { email: string }>({
    mutationFn: ({ email }) => resendVerificationCode(email),
    onSuccess: () => {
      notifications.show({
        message: 'Verification code sent. Please, check your email',
      });
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  useEffect(() => {
    const isReadyForSubmit = code.length === 6 && Boolean(email);
    if (isReadyForSubmit) {
      mutateConfirmEmail({ email: email as string, code });
    }
  }, [code, email, mutateConfirmEmail]);

  return (
    <div className={classes.verticalSpaceContainer}>
      <div>
        <Title order={4} mb="xs">
          Please check your email
        </Title>
        <Text fz="md" mb="xl" c={theme.colors.dark[1]}>
          We have sent a code to{' '}
          <Text span c={theme.white} fw={700}>
            {email}
          </Text>
        </Text>
      </div>
      <div
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <PinInput
          value={code}
          size="xl"
          length={6}
          placeholder=""
          onChange={(e) => {
            setCode(e);
          }}
          type="number"
          mb="md"
        />

        {isConfirmLoading && 'Confirming...'}

        <Text fz="md" ta="center" style={{ marginTop: '40px' }}>
          Didn’t get the code?{' '}
          <span
            className={classes.linkLookAlike}
            onClick={() => {
              if (email) {
                mutateResendCode({ email });
              }
            }}
          >
            Click here to resend.
          </span>
        </Text>
      </div>
    </div>
  );
};
