export const AppleIcon = () => {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.937 7.16282C17.8388 7.22131 15.5005 8.45576 15.5005 11.1928C15.6107 14.3142 18.4513 15.4089 18.5 15.4089C18.4513 15.4674 18.0712 16.9001 16.9452 18.402C16.0515 19.6957 15.0597 21 13.5536 21C12.1209 21 11.6066 20.1378 9.95357 20.1378C8.17832 20.1378 7.67601 21 6.31683 21C4.8107 21 3.74539 19.6258 2.80307 18.3442C1.57884 16.6669 0.538286 14.0347 0.501551 11.5073C0.476795 10.168 0.746716 8.85154 1.4319 7.73332C2.39898 6.17223 4.12552 5.1125 6.01097 5.07756C7.45561 5.03122 8.74132 6.02105 9.62296 6.02105C10.4679 6.02105 12.0475 5.07756 13.8347 5.07756C14.6061 5.07832 16.6633 5.29938 17.937 7.16282ZM9.50078 4.81016C9.24363 3.58711 9.95357 2.36406 10.6148 1.58389C11.4597 0.640392 12.7941 0 13.9449 0C14.0184 1.22305 13.5528 2.42255 12.7207 3.29616C11.974 4.23965 10.6883 4.94993 9.50078 4.81016Z"
        fill="white"
      />
    </svg>
  );
};
