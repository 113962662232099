import { useQuery, UseQueryOptions } from 'react-query';
import {
  REACT_QUERY_CACHE_KEYS,
  REACT_QUERY_CACHE_KEYS_PROPS,
} from '../../../constants/react-query-cache-keys';
import { useAuthContext } from '../../../providers';
import * as httpService from '../../../service/http';

export type CategoriesData = {
  id: string;
  title: string;
  description: string;
}[];

type CategoriesResponse = {
  data: CategoriesData;
};

export type CategoriesPayload = {
  [key: string]: string;
};

// Replace 'unknown' with the type of your error
type Error = unknown;

export async function fetchCategories() {
  const { data } = await httpService.get<CategoriesResponse>('/categories');
  return data.data;
}

export function useGetCategories(
  options?: UseQueryOptions<
    CategoriesData,
    Error,
    CategoriesData,
    [REACT_QUERY_CACHE_KEYS_PROPS['categories']]
  >,
) {
  const { isAuthenticated } = useAuthContext();

  return useQuery<
    CategoriesData,
    Error,
    CategoriesData,
    [REACT_QUERY_CACHE_KEYS_PROPS['categories']]
  >([REACT_QUERY_CACHE_KEYS.categories], fetchCategories, {
    enabled: isAuthenticated,
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    ...options,
  });
}
