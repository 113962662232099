export const RewardsFilledIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1 19.7404L17.9198 23.2604C18.9856 23.9055 20.2898 22.9519 20.0093 21.7458L18.4667 15.1267L23.6134 10.6671C24.553 9.85374 24.0482 8.31114 22.8141 8.21297L16.0406 7.638L13.3902 1.38343C12.9134 0.247514 11.2866 0.247514 10.8098 1.38343L8.15933 7.62398L1.38589 8.19895C0.151804 8.29711 -0.353049 9.83972 0.586538 10.6531L5.73323 15.1126L4.19063 21.7318C3.91015 22.9378 5.21436 23.8915 6.28016 23.2464L12.1 19.7404Z"
        fill="#FC9244"
      />
    </svg>
  );
};
