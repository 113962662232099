export const GoogleIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0015 12.2332C23.0015 11.3699 22.93 10.7399 22.7753 10.0865H12.7158V13.9832H18.6205C18.5015 14.9515 17.8587 16.4099 16.4301 17.3898L16.41 17.5203L19.5907 19.935L19.811 19.9565C21.8348 18.1249 23.0015 15.4298 23.0015 12.2332Z"
        fill="#4285F4"
      />
      <path
        d="M12.715 22.5C15.6078 22.5 18.0363 21.5666 19.8102 19.9566L16.4292 17.3898C15.5245 18.0082 14.3102 18.4399 12.715 18.4399C9.88167 18.4399 7.47693 16.6082 6.61971 14.0765L6.49406 14.087L3.18681 16.5954L3.14355 16.7132C4.90543 20.1432 8.52448 22.5 12.715 22.5Z"
        fill="#34A853"
      />
      <path
        d="M6.61997 14.0766C6.39379 13.4233 6.26289 12.7232 6.26289 11.9999C6.26289 11.2765 6.39379 10.5766 6.60807 9.92325L6.60208 9.78411L3.25337 7.23547L3.14381 7.28655C2.41765 8.7099 2.00098 10.3083 2.00098 11.9999C2.00098 13.6916 2.41765 15.2899 3.14381 16.7132L6.61997 14.0766Z"
        fill="#FBBC05"
      />
      <path
        d="M12.715 5.55997C14.7269 5.55997 16.084 6.41163 16.8579 7.12335L19.8817 4.23C18.0246 2.53834 15.6078 1.5 12.715 1.5C8.52451 1.5 4.90544 3.85665 3.14355 7.28662L6.60783 9.92332C7.47696 7.39166 9.88171 5.55997 12.715 5.55997Z"
        fill="#EB4335"
      />
    </svg>
  );
};
