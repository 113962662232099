import { ActionIcon, Flex, Menu, Text } from '@mantine/core';
import { PropsWithChildren, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { BellIcon } from '../../../assets/icons/bell';
import { BellNotifierIcon } from '../../../assets/icons/bell-notifier';
import { LogoutIcon } from '../../../assets/icons/logout';
import { SettingsIcon } from '../../../assets/icons/settings';
import { UserIcon } from '../../../assets/icons/user';
import { useAuthContext } from '../../../providers';
import { CustomerRoutes } from '../../routes';
import { useUserProfileContext } from '../../service/user/context';
import DummyAvatarImage from '../../../assets/avatar.png';

const UserAvatar = forwardRef<HTMLButtonElement, { src: string }>(({ src }, ref) => {
  return (
    <ActionIcon size="xl" ref={ref}>
      <img src={src} width="100%" height="100%" alt="User icon" style={{ borderRadius: '50%' }} />
    </ActionIcon>
  );
});

UserAvatar.displayName = 'UserAvatar';

const Title = ({ children }: PropsWithChildren) => (
  <Text size="xl" fw={500}>
    {children}
  </Text>
);

const Level = ({ children }: PropsWithChildren) => (
  <Text
    fw={500}
    size="md"
    sx={(theme) => ({
      color: theme.colors.dark[2],
    })}
  >
    {children}
  </Text>
);

interface Props {
  title: string;
}

export const Header = ({ title }: Props) => {
  const { user, signOut } = useAuthContext();
  const { userProfile } = useUserProfileContext();
  const isUnreadNotification = false;
  const ActiveBellIcon = isUnreadNotification ? BellNotifierIcon : BellIcon;

  const avatarImage = () => {
    if (userProfile?.profilePhotoUrl) {
      return userProfile?.profilePhotoUrl;
    }
    if (user?.attributes?.picture) {
      return user?.attributes?.picture;
    }
    return DummyAvatarImage;
  };

  return (
    <Flex
      align="center"
      p="16px 24px 14px 24px"
      justify="space-between"
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[8],
      })}
    >
      <Title>{title}</Title>
      <Flex align="center" gap={16}>
        <Level>Level {userProfile?.points?.level}</Level>
        <Menu id="user-menu" position="bottom-end" radius={8}>
          <Menu.Target>
            <ActionIcon
              size="xl"
              sx={() => ({
                width: '40px',
                height: '40px',
                minHeight: '40px',
                minWidth: '40px',
              })}
            >
              <img
                width="100%"
                height="100%"
                alt="User icon"
                src={avatarImage()}
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                referrerPolicy="no-referrer"
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<UserIcon />}>
              <Link to={CustomerRoutes.PROFILE}>Profile Page</Link>
            </Menu.Item>
            <Menu.Item icon={<ActiveBellIcon />}>
              <Link to={CustomerRoutes.NOTIFICATIONS}>Notifications</Link>
            </Menu.Item>
            <Menu.Item icon={<SettingsIcon />}>
              <Link to={CustomerRoutes.SETTINGS}>Settings</Link>
            </Menu.Item>
            <Menu.Item icon={<LogoutIcon />} onClick={signOut}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
};
