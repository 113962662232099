import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { REACT_QUERY_CACHE_KEYS } from '../../../../../constants/react-query-cache-keys';
import { liabilitySchema } from '../../../../../validation-schemas/liabilities';
import { LiabilityFormFields } from '../../../../pages/accounts/create-account-form-page-liability';
import { CustomerRoutes } from '../../../../routes';
import {
  LiabilityData,
  LiabilityPayload,
  removeLiability,
  updateLiability,
} from '../../../../service/liabilities';

interface EditLiabilityFormProps {
  liabilityData: LiabilityData | undefined;
}

export const EditLiabilityForm = ({ liabilityData }: EditLiabilityFormProps) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const [opened, { toggle, close }] = useDisclosure(false);

  const { mutate, isLoading: isEditLoading } = useMutation({
    mutationFn: (liabilityPayload: Omit<LiabilityPayload, 'liabilityType'>) =>
      // eslint-disable-next-line
      //@ts-ignore:next-line
      updateLiability(liabilityData.id, liabilityPayload),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.liabilities);
      notifications.show({
        message: 'Successfully modified liability.',
      });
      setTimeout(() => {
        navigate(CustomerRoutes.ACCOUNTS);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { mutate: deleteLiability, isLoading: isRemoveLoading } = useMutation({
    mutationFn: (liabilityId: string | undefined) => removeLiability(liabilityId),
    onSuccess: () => {
      queryClient.invalidateQueries(REACT_QUERY_CACHE_KEYS.liabilities);
      notifications.show({
        message: 'Successfully removed liability.',
      });
      setTimeout(() => {
        navigate(CustomerRoutes.ACCOUNTS);
      }, 200);
    },
    onError: () => {
      notifications.show({
        message: 'Something went wrong. Please, try again.',
      });
    },
  });

  const { formState, watch, register, handleSubmit } = useForm<LiabilityFormFields>({
    mode: 'onChange',
    defaultValues: {
      interestRate: liabilityData?.interestRate,
      amount: liabilityData?.amount,
      monthlyDueDate: liabilityData?.monthlyDueDate,
      accountName: liabilityData?.name,
    },
    resolver: yupResolver(liabilitySchema),
  });

  const { accountName, amount, monthlyDueDate, interestRate } = watch();

  return (
    <div>
      <form
        onSubmit={handleSubmit(({ accountName, amount, monthlyDueDate, interestRate }) => {
          mutate({
            amount: amount,
            monthlyDueDate: monthlyDueDate,
            interestRate: interestRate,
            name: accountName,
          });
        })}
      >
        <Flex gap="md">
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              mb="sm"
              size="lg"
              style={{ width: '100%' }}
              label={'Liability'}
              value={accountName}
              {...register('accountName')}
              error={formState.errors?.accountName?.message}
            />
          </Flex>
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              size="lg"
              style={{ width: '100%' }}
              label="Amount"
              value={amount}
              {...register('amount')}
              error={formState.errors?.amount?.message}
            />
          </Flex>
        </Flex>
        <Flex gap="md">
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              size="lg"
              style={{ width: '100%' }}
              label="Due Date"
              value={monthlyDueDate}
              {...register('monthlyDueDate')}
              error={formState.errors?.monthlyDueDate?.message}
            />
          </Flex>
          <Flex sx={{ flexShrink: 0, flex: '1 1' }}>
            <TextInput
              size="lg"
              style={{ width: '100%' }}
              label="Interest Rate"
              value={interestRate}
              min={0}
              {...register('interestRate')}
              error={formState.errors?.interestRate?.message}
            />
          </Flex>
        </Flex>
        <Button
          mt={40}
          size="lg"
          fullWidth
          type="submit"
          variant="filled"
          disabled={!formState.isValid || isEditLoading}
        >
          Save changes
        </Button>
      </form>
      <Button
        variant="outline"
        sx={{
          color: theme.colors.red[5],
          borderColor: theme.colors.red[5],
          backgroundColor: '#260908',
        }}
        size="lg"
        fullWidth
        mt={40}
        onClick={toggle}
        loading={isRemoveLoading}
      >
        Delete Liability
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        size="327px"
        padding="25px"
      >
        <LoadingOverlay visible={isRemoveLoading} overlayBlur={2} />
        <Stack>
          <Text fz="18px" fw={700} align="center">
            Are you sure you’d like to delete this liability?
          </Text>
          <Button onClick={() => deleteLiability(liabilityData?.id)}>Delete</Button>
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
        </Stack>
      </Modal>
    </div>
  );
};
