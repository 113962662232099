import * as httpService from '../../../service/http';
import { Response } from '../../../shared-types';

interface Task {
  taskAmount: number;
  bonusPoints: number;
  bankAccountId: string;
  bankAccountName: string;
  objectiveId: string;
}

interface TaskData {
  [taskId: string]: Task[];
}

interface TasksData {
  tasks: TaskData;
}

interface ResponseTaskData {
  data: TasksData;
}
export async function fetchUserTasks(): Promise<TasksData> {
  const { data } = await httpService.get<Response<ResponseTaskData['data']>>(
    '/customer-upcoming-tasks',
  );
  return data.data;
}
